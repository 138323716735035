import React, { Component, useEffect, useRef } from 'react';
import { View } from 'react-native';
import ReactDOM from "react-dom";

// const modalRoot = document.getElementById('modal-root');

const root = document.getElementById('root');
const modalRoot = document.createElement('div');
modalRoot.id = 'modal-root';
// modalRoot.style = `display: flex; position: absolute; top: 0; left: 0; right: 0; bottom: 0;`;
root.insertAdjacentElement('afterend', modalRoot);


const shadow = {
  shadowColor: '#333',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.3,
  shadowRadius: 6,
};

export default function Modal({ visible, children, close }) {

  // listen for escape key -- todo don't close >1 modal at a time
  useEffect(() => {
    if (!visible) return;
    const keyPress = e => e.key === "Escape" && close();
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [visible]);

  if (!visible) return null;

  return (
    <ModalPortal>
      <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div onClick={close} style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.4)' }}/>
        {/*{children}*/}

        {/*<View style={[{ justifyContent: 'center', alignItems: 'center', marginVertical: 20 }]}>*/}
        {/*  {children}*/}
        {/*</View>*/}

        <View style={[{ ...shadow, maxHeight: '90vh', maxWidth: '90vw', backgroundColor: '#fff', overflow: 'scroll', borderRadius: 12 }]}>

          {children}

          {/*<View style={{ height: 20, backgroundColor: '#f00' }}/>*/}
          {/*<View style={{ width: 100, height: 800, backgroundColor: '#f0f' }}/>*/}
          {/*<View style={{ height: 20, backgroundColor: '#0f0' }}/>*/}
        </View>
      </div>
    </ModalPortal>
  );
}

function ModalPortal({ children }) {
  const elt = useRef(document.createElement('div'));

  useEffect(() => {
    modalRoot.appendChild(elt.current);
    return () => modalRoot.removeChild(elt.current);
  }, []);

  return ReactDOM.createPortal(children, elt.current);
}

// export default function Modal({ children, visible }) {
//   const elt = useRef(document.createElement('div'));
//
//   useEffect(() => {
//     if (visible) {
//       modalRoot.appendChild(elt.current);
//       return () => modalRoot.removeChild(elt.current);
//     }
//   }, [visible]);
//
//   if (!visible) return null;
//
//   return ReactDOM.createPortal((
//     <View style={StyleSheet.absoluteFill}>
//       {children}
//     </View>
//   ), elt.current);
// }

// // https://reactjs.org/docs/portals.html
//
// export default class Modal extends React.Component {
//   constructor(props) {
//     super(props);
//     this.el = document.createElement('div');
//   }
//
//   componentDidMount() {
//     // The portal element is inserted in the DOM tree after
//     // the Modal's children are mounted, meaning that children
//     // will be mounted on a detached DOM node. If a child
//     // component requires to be attached to the DOM tree
//     // immediately when mounted, for example to measure a
//     // DOM node, or uses 'autoFocus' in a descendant, add
//     // state to Modal and only render the children when Modal
//     // is inserted in the DOM tree.
//     modalRoot.appendChild(this.el);
//   }
//
//   componentWillUnmount() {
//     modalRoot.removeChild(this.el);
//   }
//
//   render() {
//     return ReactDOM.createPortal(
//       this.props.children,
//       this.el,
//     );
//   }
// }
