import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { apiUpdateDealSubmission, apiUpdateDealDetails } from "../../api/api";
import { updateDealsInfo } from "../../redux/asyncActions";
import { ActivityIndicator, FlatList, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import Touchable from "../../components/Touchable";
import { maybeRounded } from "../../assets/assets";
import CreatorSubmission from "./CreatorSubmission";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";

export default function SubmissionList({ deal, dealSubmissions, selectedTab, setSelectedTab, setSelectedSubmission }) {

  const [selectedSubmissions, setSelectedSubmissions] = useState([]); // arrayOf(shape({ id, type: 'accepted' | 'rejected' }))
  const [selectAllValue, setSelectAllValue] = useState('');
  const [accepted, rejected] = useMemo(() => {
    let acc = new Set(), rej = new Set();
    selectedSubmissions.forEach(ds => {
      if (ds.type === 'accepted') acc.add(ds.id);
      if (ds.type === 'rejected') rej.add(ds.id);
    });
    return [acc, rej];
  }, [selectedSubmissions]);

  const [showAcceptanceConfirm, setShowAcceptanceConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const updateUserAPI = async () => {
    setLoading(true);
    const res = await apiUpdateDealSubmission(selectedSubmissions, deal.id);
    if (res.status === 'success') {
      console.log('SubmissionList - updateUserAPI - apiUpdateDealSubmission res', res.data);
      await updateDealsInfo();
      setLoading(false);
      setSelectedSubmissions([]);
      setShowAcceptanceConfirm(false);
    } else {
      setLoading(false);
      console.log('SubmissionList - updateUserAPI - error', res.message);
      alert(`Error submitting: ${res.message}`);
    }
  };

  const selectAll = (selectionType) => {
    // selectionType = 'accepted' | 'rejected' |  'clear'

    // if double press, clear
    if (selectAllValue === selectionType) {
      setSelectedSubmissions([]);
      setSelectAllValue('');
      return
    }
    const allApplied = dealSubmissions.filter(d => d.status === 'applied');
    setSelectedSubmissions(allApplied.map( x => ({"id": x.id, "type": selectionType})));
    setSelectAllValue(selectionType);
  };


  const headerInfoBox = () => {
    if (selectedTab === 'received') {
      return (
        <View style={{ width: '100%' }}>
          <Text style={{ color: '#0F1D40', ...maybeRounded("400") }}>
            {/*These are applications for your deal that you haven't taken action on yet. Promptly either accept or reject*/}
            {/*these creators by selecting the boxes then clicking "submit."*/}
            {/*Press details to see more information about them. From there you can message them if you have any questions.*/}

            These are creators you haven't taken action on yet.{'\n\n'}
            Promptly accept or reject them by selecting the boxes then clicking "submit."{'\n\n'}
            Press details to see more information. From there you can also message them any questions.
            {/*{deal.campaign_type === 'install' && `\n\nFor install campaigns, you'll want to create individual tracking links for each creator you accept, and let them know how you'll be counting.`}*/}
            {/*{deal.campaign_type === 'install' && `\n\nAt the moment, you'll need to provide your own tracking for how many installs a creator has driven. Be sure to let them know how it'll work (e.g. a link, a referral code, etc.)`}*/}

            {/*Press details to see more information about them. Feel free to message if you have any questions for them.*/}
            {/*Ideally, you should have no pending creators in this column.*/}
          </Text>
        </View>
      );
    }
    if (selectedTab === 'in_progress') {

      if (deal.campaign_type === 'install') {
        return (
          <View style={{ width: '100%' }}>
            <Text style={{ color: '#0F1D40', ...maybeRounded("400") }}>
              These are creators that you've approved.{'\n\n'}
              Send them their link or referral code so they can begin publicizing your product.{'\n\n'}
              You'll want to update their install numbers regularly so they can get feedback on how they're doing.{'\n\n'}
              Creators will get paid out automatically based on the install numbers you enter.{'\n\n'}
              Contact us if you'd like to automate this process. We're considering AppsFlyer and Branch integration, API/webhook integration, and CSV upload support.
            </Text>
          </View>
        );
      }

      return (
        <View style={{ width: '100%' }}>
          <Text style={{ color: '#0F1D40', ...maybeRounded("400") }}>
            {/*These are applications for your deal that you have approved.*/}
            {/*When they upload a video, you will see it in the chat.*/}
            {/*Feel free to provide them feedback/ask them for changes within reason.*/}
            {/*At the end of this process, the creator will give you a rating as a brand.*/}
            {/*Once you hit "Mark Complete" we'll charge your card and they will move to the "Complete" column. We'll then handle paying them directly.*/}
            {/*If you do not see their video within a reasonable time frame, feel free to ping them.*/}
            These are creators that you've approved.{'\n\n'}
            {/*You may want to send them a message after approval to discuss video ideas and to make sure they're committed to the deal.{'\n\n'}*/}
            If you are sending them product, you may want to send them a message first to make sure they're committed to the deal.{'\n\n'}
            When they upload a video, you'll see it in the chat/details view.{'\n\n'}
            If you don't see their video within a reasonable time frame, feel free to ping them.{'\n\n'}
            You can provide feedback or ask for changes within reason. At the end of this process, the creator will also give you a rating as a brand.{'\n\n'}
            Once you hit "Pay Creator" we'll move them to the "Complete" column and charge your card. We'll then handle paying them directly.
          </Text>
        </View>
      );
    }
    if (selectedTab === 'complete') {
      return (
        <View style={{ width: '100%'}}>
          <Text style={{ color: '#0F1D40', ...maybeRounded("400") }}>
            These are completed submissions from the campaign.{'\n\n'}
            Start another campaign to get more submissions.{'\n\n'}
            Want to pay some creators in particular for more/continued work? <a style={{ color: '#333' }} target={"_blank"} href={"mailto:team@swipehouse.co?subject=[Swipehouse] Looking to do more work with some creators"}>Send us an email</a> and we'll get you set up!

            {/*This is where your completed submissions from this campaign will go. Start a new campaign to get started again.*/}
            {/*These are where your past completions are.*/}
            {/*Start a new campaign to get started again.*/}
          </Text>
        </View>
      );
    }
  };

  const updateUserDealStatus = (ds, action) => {
    if (action === 'cleared')  setSelectedSubmissions(curr => [...curr.filter(item => item.id !== ds.id)]);
    if (action === 'accepted') setSelectedSubmissions(curr => [...curr.filter(item => item.id !== ds.id), { type: 'accepted', id: ds.id }]);
    if (action === 'rejected') setSelectedSubmissions(curr => [...curr.filter(item => item.id !== ds.id), { type: 'rejected', id: ds.id }]);
  };

  const canSubmit = !!selectedSubmissions.length;

  const data = useMemo(() => {
    if (!dealSubmissions) return [];
    if (selectedTab === 'received') return dealSubmissions.filter(d => d.status === 'applied');
    if (selectedTab === 'complete') return dealSubmissions.filter(d => d.status === 'paid');
    if (selectedTab === 'in_progress') return dealSubmissions.filter(d => d.status !== 'applied' && d.status !== 'rejected' && d.status !== 'paid');
  }, [dealSubmissions, selectedTab]);

  const dealChannelsList = useSelector(state => state.dealChannelsList);
  const lastMessageTimes = useMemo(() => {
    let times = {};
    dealChannelsList && dealChannelsList.filter(dc => dc.deal_id === deal.id).forEach(dc => {
      times[dc.user_id] = new Date(dc.last_message_sent_on);
    });
    return times;
  }, [dealChannelsList, deal]);

  const sortedData = useMemo(() => {
    let d2 = data.map(ds => {
      let time = new Date(ds.created_on);
      if (lastMessageTimes[ds.user_id]) time = lastMessageTimes[ds.user_id] > time ? lastMessageTimes[ds.user_id] : time;
      // if (ds.video_submitted_on) time = new Date(ds.video_submitted_on) > time ? new Date(ds.video_submitted_on) : time;
      return { ...ds, time };
    });

    d2.sort((a, b) => {
      if (a.time > b.time) return -1;
      if (b.time > a.time) return 1;
    });

    return d2;
  }, [data, lastMessageTimes]);

  const listRef = useRef();
  let oldScrollTop = useRef(0);
  if (listRef && listRef.current && listRef.current.getScrollableNode && listRef.current.getScrollableNode()) {
    oldScrollTop.current = listRef.current.getScrollableNode().scrollTop;
  }
  useEffect(() => {
    let newScrollTop = listRef.current.getScrollableNode().scrollTop;
    if (oldScrollTop.current && !newScrollTop) listRef.current.getScrollableNode().scrollTop = oldScrollTop.current;
  }, [sortedData]);

  return (
    <View style={{ ...styles.boxShadow, ...styles.submissiobBoxStyle }}>
      <View style={{ paddingBottom: 15, paddingTop: 10, paddingLeft: 3, flexDirection: 'row' }}>
        {/* <Touchable onPress={() => setSelectedTab('received')}>
          <Text style={[{ fontSize: 16, paddingRight: 20, color: selectedTab === 'received' ? 'white' : 'black' }, selectedTab === 'received' ? maybeRounded('600') : maybeRounded('400')]}>Received</Text>
       </Touchable> */}
       
       <TabButton onPress={() => setSelectedTab('received')} selected={selectedTab === "received"} text={"Received"} />

       <TabButton onPress={() => setSelectedTab('in_progress')} selected={selectedTab === "in_progress"} text={"In-Progress"} />

       {deal.campaign_type !== 'install' &&   
        <TabButton onPress={() => setSelectedTab('complete')} selected={selectedTab === "complete"} text={"Complete"} />
       }

        {/* 
        <Touchable onPress={() => setSelectedTab('in_progress')}>
          <Text style={[{ fontSize: 16, paddingRight: 20 }, selectedTab === 'in_progress' ? maybeRounded('600') : maybeRounded('400')]}>In-Progress</Text>
        </Touchable> */}
        {/* {deal.campaign_type !== 'install' &&
          <Touchable onPress={() => setSelectedTab('complete')}>
            <Text style={[{ fontSize: 16, paddingRight: 20 }, selectedTab === 'complete' ? maybeRounded('600') : maybeRounded('400')]}>Complete</Text>
          </Touchable>
        } */}
      </View>

      <FlatList
        ref={listRef}
        ListHeaderComponent={(
          <Fragment>
            {!deal.is_approved &&
              <View style={{ paddingVertical: 20, flexDirection: 'column', gap: 16 }}>
                <Text style={{ fontSize: 14, fontWeight: '500' }}>Congrats! We're currently reviewing your campaign ⭐️️️</Text>
                <Text style={{ fontSize: 14, fontWeight: '400' }}>As we approve only one or two brand deals per day to prominently feature them, it may take a week and a half to get your campaign approved. We do this to make sure your deal gets great featuring.</Text>
                <Text style={{ fontSize: 14, fontWeight: '400' }}>Once approved you'll start receiving submissions.</Text>
                <Text style={{ fontSize: 14, fontWeight: '400' }}>
                  <a style={{ color: '#555', textDecorationLine: 'none' }} target={"_blank"} href={"mailto:team@swipehouse.com?subject=Swipehouse for Brands"}>Questions or need this out sooner? <span style={{ textDecorationLine: 'underline' }}>Send us an email</span></a>
                </Text>
              </View>
            }
            {!!deal.is_approved && dealSubmissions.length === 0 &&
              <View style={{ paddingVertical: 20, flexDirection: 'column', gap: 16 }}>
                <Text style={{ fontSize: 14, fontWeight: '500' }}>Your campaign is live!</Text>
                <Text style={{ fontSize: 14, fontWeight: '400' }}>You'll start receiving submissions shortly.</Text>
                <Text style={{ fontSize: 14, fontWeight: '400' }}>
                  <a style={{ color: '#555', textDecorationLine: 'none' }} target={"_blank"} href={"mailto:team@swipehouse.co?subject=Swipehouse for Brands"}>Questions? <span style={{ textDecorationLine: 'underline' }}>Send us an email</span></a>
                </Text>
              </View>
            }
            {!!deal.is_approved && dealSubmissions.length > 0 &&
              <Fragment>{headerInfoBox()}</Fragment>
            }
            { selectedTab === 'received' && dealSubmissions.length > 0 &&
              <View style={{ gap: 12, flexDirection: 'row', justifyContent: 'flex-start'}}>
                <Touchable onPress={() => selectAll('accepted')} style={styles.buttonStyle}>
                  <Text style={{ fontSize: 12, lineHeight: 24, ...maybeRounded('600'), color: '#fff'}}>Accept all</Text>
                </Touchable>
                <Touchable onPress={() => selectAll('rejected')} style={styles.rejectButtonStyle}>
                  <Text style={{ fontSize: 12, lineHeight: 24, ...maybeRounded('600'), color: '#FE2D55'}}>Reject all</Text>
                </Touchable>
              </View>
            }
          </Fragment>
        )}
        ListHeaderComponentStyle={{ paddingHorizontal: 20, paddingBottom: 20, gap: 16 }}
        style={{ marginLeft: -20, marginRight: -20 }}
        contentContainerStyle={{ paddingBottom: 150 }}
        data={sortedData}
        renderItem={({ item }) =>
          <CreatorSubmission
            submission={item}
            selectedTab={selectedTab}
            deal={deal}
            setSelectedSubmission={setSelectedSubmission}
            updateUserDealStatus={updateUserDealStatus}
            accepted={accepted.has(item.id)}
            rejected={rejected.has(item.id)}
          />
        }
        keyExtractor={item => item.user_id}
      />

      {canSubmit &&
        <View style={{ position: 'absolute', bottom: 0, width: '100%', alignItems: 'center', alignSelf: 'center', textAlign: 'center', paddingBottom: 40 }}>
          {selectedTab === 'received' &&
            <View style={{ backgroundColor: '#ffffff', padding: 20, flexDirection: 'row', justifyContent: 'space-between', width: '456px', boxShadow: '0px -4px 28px 0px #00000024' }}>
              <View style={{flexDirection: 'row', gap: 8}}>
                  { (accepted.size > 0) && 
                    <Text style={{ ...maybeRounded('500'), color: '#0F1D40', fontSize: 14, lineHeight: 20, alignSelf: 'center' }}>
                      {accepted.size} Accepts{(rejected.size > 0) && ","}
                    </Text>
                  }
                  { (rejected.size > 0) && <Text style={{ ...maybeRounded('500'), color: '#0F1D40', fontSize: 14, lineHeight: 20, alignSelf: 'center' }}>{rejected.size} Rejects</Text>}
              </View>
              <Touchable 
                onPress={() => setShowAcceptanceConfirm(true)} 
                style={{
                  backgroundColor: "#3B51F5", 
                  borderRadius: 40,
                  height: 44,
                  width: 90,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  paddingVertical: 20,
                  paddingHorizontal: 12,
                }}
              >
                {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ color: '#fff', ...maybeRounded('600'), fontSize: 14  }}>Submit</Text>}
              </Touchable>
            </View>
          }
          <Modal visible={showAcceptanceConfirm}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, borderRightWidth: 1, borderRightColor: '#ebebeb' }}>

                {/*<View style={{ flexDirection: 'row', paddingLeft: 25, paddingRight: 25, paddingTop: 10, alignItems: 'center' }}>*/}
                {/*  <Text style={[styles.headerOne, { marginBottom: 10 }]}>New Campaign 🚀</Text>*/}
                {/*  <View style={{ flex: 1 }}/>*/}
                {/*  <Touchable onPress={close}>*/}
                {/*    <Text style={[styles.descriptionText, { marginBottom: 10 }]}>Close</Text>*/}
                {/*  </Touchable>*/}
                {/*</View>*/}
                <ScrollView style={{ backgroundColor: '#fcfdff', flex: 1, width: 325, borderRadius: 5, paddingHorizontal: 25, paddingVertical: 10, borderTopWidth: 1, borderBottomWidth: 1, borderColor: '#EBEBEB', maxHeight: 900 }}>
                  <View style={{ height: 24 }} />
                  <Touchable onPress={() => setShowAcceptanceConfirm(!showAcceptanceConfirm)} style={{ position: 'absolute', top: 10, right: 10 }}>
                    <Text style={{  fontSize: 14, marginBottom: 13, color: '#656B73', ...maybeRounded('400'), paddingLeft: 2 }}>Cancel</Text>
                  </Touchable>
                  <Text style={{...maybeRounded('500'), fontSize: 22, marginTop: 5, marginBottom: 15, color: '#16191C' }}>Confirm selection</Text>
                  <Text style={{  fontSize: 14, ...maybeRounded('500') }}>After confirming this selection:</Text>
                  <Text style={{  fontSize: 14, marginTop: 8, ...maybeRounded('400') }}>Creators will be notified of their status</Text>
                  <Text style={{  fontSize: 14, marginTop: 8, ...maybeRounded('400') }}>For creators that you are accepting, all price negotiation has to happen before accepting</Text>
                  <View style={{ alignItems: 'center', width: '100%', marginTop: 20, paddingBottom: 15 }}>
                    <Touchable onPress={updateUserAPI} style={{ backgroundColor: '#3E8BFF', borderRadius: 40, height: 43, width: 170, alignItems: 'center', justifyContent: 'center'}}>
                      {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ color: '#fff', ...maybeRounded('700'), fontSize: 16  }}>Submit</Text>}
                    </Touchable>
                  </View>
                </ScrollView>
              </View>
            </View>
          </Modal>
        </View>
      }
    </View>
  );
}

const TabButton = ({ text, onPress, selected }) => {
  return (
    <Touchable onPress={onPress}>
      <View style={[
        {
          paddingHorizontal: 20,
          paddingVertical: 6,
          borderRadius: 25,
          borderWidth: 1,
          borderColor: selected ? '#0D1E52' : '#E2E4E8',
          backgroundColor: selected ? '#0F1D40' : 'transparent',
          marginRight: 10, // To add spacing between buttons,
          boxShadow: '0px 1px 1px 0px #0000001A'
        }
      ]}>
        <Text style={{ fontWeight: '600', fontSize: "14px", lineHeight: 24, color: selected ? '#FFFFFF' : '#525C76' }}>
          {text}
        </Text>
      </View>
    </Touchable>
  );
};

const styles = StyleSheet.create({
  input: {
    height: 50,
    borderRadius: 4,
    backgroundColor: "#fff",
    marginBottom: 10,
    padding: 10,
    ...maybeRounded("500"),
    fontSize: 15,
    color: "#000000",

    borderWidth: 1,
    borderColor: "#dddfe1",
  },
  inputMultiline: {
    height: 170,
    borderRadius: 4,
    backgroundColor: "#fff",
    marginBottom: 10,
    padding: 10,
    paddingTop: 12,
    ...maybeRounded("500"),
    fontSize: 15,
    color: "#000000",
    textAlign: "flex-start",

    borderWidth: 1,
    borderColor: "#dddfe1",
  },
  inputText: {
    ...maybeRounded("500"),
    fontSize: 16,
    color: "#8B9399",
  },
  checkbox: {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#dddfe1",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxRow: {
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 5,
  },
  checkboxText: {
    fontSize: 14,
    marginLeft: 7,
    paddingTop: 5,
    color: "#16191C",
    ...maybeRounded("600"),
  },
  descriptionText: {
    fontSize: 14,
    marginBottom: 20,
    color: "#656B73",
    ...maybeRounded("400"),
    paddingLeft: 2,
  },
  headerOne: {
    ...maybeRounded("700"),
    fontSize: 25,
    marginBottom: 20,
    color: "#16191C",
  },
  headerTwo: {
    ...maybeRounded("600"),
    fontSize: 14,
    marginBottom: 8,
    color: "#16191C",
    marginTop: 8,
  },
  jobHeaderText: {
    ...maybeRounded("700"),
    fontSize: 18,
    marginBottom: 10,
    color: "#16191C",
  },
  postPreviewContainer: {
    height: 100,
    backgroundColor: "#e7e7e7",
    borderRadius: 10,
  },
  checkBoxIcon: {
    height: 18,
    width: 18,
  },

  card: {
    flex: 1,
    height: 190,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#888",
    paddingBottom: 12,
    paddingHorizontal: 8,
    shadowColor: "#333",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  selectedCard: {
    backgroundColor: "rgba(255,255,255,0.8)",
    borderColor: "#284bff",
    shadowColor: "#284bff",
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  cardTitle: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: 14,
    paddingBottom: 5,
  },
  cardSubtitle: {
    textAlign: "center",
    fontWeight: "400",
    fontSize: 14,
    color: "#444",
  },
  boxShadow: {
    shadowColor: "#000", // Use the color from the box-shadow, ignoring the alpha for shadowColor
    shadowOffset: { width: 0, height: 6 }, // Offset for x (0px) and y (6px)
    shadowOpacity: 0.08, // Convert the alpha value from #00000008 to a decimal (8% opacity)
    shadowRadius: 20, // This is the blur radius in CSS
    elevation: 4,
  },
  submissiobBoxStyle: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    width: "100%",
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingTop: 10,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  buttonStyle: {
    backgroundColor: "#3B51F5",
    borderRadius: 62,
    height: 36,
    // width: 131,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 12,
    paddingHorizontal: 20,
    boxShadow: '0px 1px 1px 0px #0000001A'
  },
  rejectButtonStyle: {
    backgroundColor: "#fff",
    borderColor: "#FE2D55",
    borderRadius: 62,
    height: 36,
    // width: 131,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderWidth: 1
  },
});




