import React, { Fragment, useState, useEffect, useRef } from 'react';
import Touchable from "../../components/Touchable"
import { maybeRounded } from "../../assets/assets";
import Navbar from "../../components/Navbar";
import { ActivityIndicator, Alert, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { apiPhoneSubmit, apiConfirmCode, apiEmailSubmit, apiConfirmEmailCode } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { addCompanyInfo, login } from "../../redux/reducers";

// import PhoneInput from "react-native-phone-number-input";
// import * as Localization from "expo-localization";
// import Modal from "../../components/Modal"

// 2023-12-14
// - this is the current sign in form, asks for email and then code
// - it was first committed 2020-12-14, last updated 2021-09-10
// - our current sign up flow is
//   - SignIn.js - email-based auth - handle getting email / email code
//   - SetCompanyInfo.js - set company info (if not already set / new user)

export function SignInForm() {
  const dispatch = useDispatch();

  const [signInStep, setSignInStep] = useState('email');

  const [loading, setLoading] = useState(false);
  const [submissionAttempts, setSubmissionAttempts] = useState(0);
  const [successfulAttempts, setSuccessfulAttempts] = useState(0);
  const [failedAttempts, setFailedAttempts] = useState(0);

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const isSubmitting = useRef(false);

  const emailSubmit = async () => {
    setLoading(true);
    setSubmissionAttempts(x => x+1);
    const res = await apiEmailSubmit(email);
    if (res.status === 'success') {
      setSuccessfulAttempts(x => x+1);
      console.log('SignIn - emailSubmit - apiEmailSubmit - res.data.email', res.data.email);
      dispatch(addCompanyInfo({ email: res.data.email }));
      setSignInStep('code')
    }
    else {
      setFailedAttempts(x => x+1);
      Alert.alert("Error submitting", res.message);
    }
    setLoading(false);
    // setShouldShowHavingIssues(true);
  };

  const codeSubmit = async () => {
    if (isSubmitting.current) return; // if it auto-submits but you press "done" while it submits it alerts "incorrect code"
    isSubmitting.current = true;

    setLoading(true);
    const res = await apiConfirmEmailCode(code, email);
    console.log('SignIn - codeSubmit - apiConfirmEmailCode res', res);

    if (res.status !== 'success') {
      isSubmitting.current = false;
      return Alert.alert("Error", res.message);
    }

    dispatch(login(res.data.token, res.data.userId));
    setLoading(false);
  };

  useEffect(() => {
    if (code.length >= 4) codeSubmit();
  }, [code]);

  const renderContents = () => {
    if (signInStep === 'email') {
      return (
        <Fragment>
          <Text style={styles.headerTwo}>Your Email</Text>
          <TextInput
            style={styles.input}
            onChangeText={setEmail}
            value={email}
            placeholder={'myname@company.org'}
            autoFocus
            // keyboardType={'email-address'} // deprecated in RNW 0.19
            inputMode={'email'}
            onSubmitEditing={() => email.trim().length && emailSubmit()}
            placeholderTextColor={'#888'}
          />

          <View style={{ height: 90 }}/>
          <View style={{ position: 'absolute', bottom: -3, width: '100%', alignItems: 'center' }}>
            <Touchable
              onPress={emailSubmit}
              style={{
                backgroundColor: '#3E8BFF',
                borderRadius: 40,
                height: 43,
                // width: 220,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ color: '#fff', ...maybeRounded('700'), fontSize: 16 }}>Continue</Text>}
            </Touchable>

            <View style={{ marginTop: 16 }}>
              <Text style={[styles.descriptionText, { marginBottom: 2 }]}>
                Login issues? <a style={{ color: '#555' }} target={"_blank"} href={"mailto:team@swipehouse.co?subject=Swipehouse for Brands Login Issue"}>Send us an email</a>
              </Text>
            </View>
          </View>
        </Fragment>
      );
    }
    if (signInStep === 'code') {
      return (
        <Fragment>
          <Text style={styles.headerTwo}>Enter Code</Text>
          <TextInput
            style={styles.input}
            onChangeText={setCode}
            value={code}
            placeholder={'6570'}
            autoFocus
            placeholderTextColor={'#888'}
          />

          <View style={{ height: 90 }}/>
          <View style={{ position: 'absolute', bottom: -3, width: '100%', alignItems: 'center' }}>
            <Touchable
              onPress={codeSubmit}
              style={{
                backgroundColor: '#3E8BFF',
                borderRadius: 40,
                height: 43,
                width: 220,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ color: '#fff', ...maybeRounded('700'), fontSize: 16 }}>Confirm Code</Text>}
            </Touchable>
            <Touchable style={{ marginTop: 16 }} onPress={() => setSignInStep('email')}>
              <Text style={[styles.descriptionText, { marginBottom: 2 }]}>Didn't get the code?</Text>
            </Touchable>
          </View>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <ScrollView style={{ backgroundColor: '#FAFBFC', flex: 1,  borderRadius: 5, paddingHorizontal: 25, paddingVertical: 10, borderWidth: 1, borderColor: '#EBEBEB', maxHeight: 320, maxWidth: 400,}}>
        <View style={{ height: 40 }} />
        <Text style={styles.headerOne}>{signInStep === 'code' ? 'Enter Confirm Code 🚀' : 'Welcome to Swipehouse! 🚀'}</Text>
        {renderContents()}
      </ScrollView>
    </Fragment>
  );
}

export default function SignIn() {
  return (
    <View style={{ backgroundColor: '#236EFF', flex: 1 }}>
      <Navbar dark/>
      <View style={{ flexDirection:'row', paddingHorizontal: '20%', flex: 1, paddingTop: 60, justifyContent: 'center'}}>
        <SignInForm />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    borderRadius: 4,
    backgroundColor: '#EEEEEE',
    marginBottom: 10,
    padding: 10,
    ...maybeRounded('500'),
    fontSize: 16,
    color: '#000000',
    maxWidth: '97%',
  },
  inputMultiline: {
    height: 170,
    borderRadius: 4,
    backgroundColor: '#EEEEEE',
    marginBottom: 10,
    padding: 10,
    paddingTop: 12,
    ...maybeRounded('500'),
    fontSize: 16,
    color: '#000000',
    textAlign: 'flex-start'
  },
  inputText: {
    ...maybeRounded('500'),
    fontSize: 16,
    color: '#8B9399',
  },
  checkbox: {
    height: 28,
    width: 28,
    backgroundColor: '#EEEEEE',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxRow: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
  },
  checkboxText: {
    fontSize: 14,
    marginLeft: 5,
    paddingTop: 5,
    color: '#16191C',
    ...maybeRounded('600')
  },
  descriptionText: {
    fontSize: 14,
    marginBottom: 20,
    color: '#656B73',
    ...maybeRounded('400'),

  },
  headerOne: {
    ...maybeRounded('700'),
    fontSize: 25,
    marginBottom: 20,
    color: '#16191C'
  },
  headerTwo: {
    ...maybeRounded('600'),
    fontSize: 14,
    marginBottom: 8,
    color: '#16191C',
    marginTop: 8,
  },
  jobHeaderText: {
    ...maybeRounded('700'),
    fontSize: 18,
    marginBottom: 10,
    color: '#16191C'
  },
  postPreviewContainer: {
    height: 100,
    backgroundColor: '#e7e7e7',
    borderRadius: 10,
  },
  checkBoxIcon:{
    height: 18,
    width: 18
  },
});
