import React, { Fragment, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Touchable from "../../components/Touchable"
import { maybeRounded } from "../../assets/assets";
import Navbar from "../../components/Navbar";
import CreateCampaignModel from "./CreateCampaignModel";
import { ActivityIndicator, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { updateDealChannelsList, updateDealsInfo, updateMe, updatePaymentMethod } from "../../redux/asyncActions";
import ConvoScreen from "../Messages/ConvoScreen";
import SubmissionList from "./SubmissionList";
import {
  apiAddPreApprovedEmail,
  apiGetCardSetupIntent, apiGetCurrentAndPendingMembers,
  apiRemovePreApprovedEmail, apiSendPendingMemberReminderEmail,
  apiUpdateEmailSettings
} from "../../api/api";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { divViewStyle } from "../../util/constants";

const checkMark = require("../../assets/images/icons8-checkmark.png");

function PaymentSettings() {
  const me = useSelector(state => state.me);
  const paymentMethod = useSelector(state => state.paymentMethod);
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const getPaymentMethod = async () => {
    const res = await updatePaymentMethod();
    if (res.status !== 'success') {
      return alert(`Error fetching payment method: ${res.message}. Try reloading the page.`)
    }
    console.log("Settings - PaymentSettings - getPaymentMethod res", res);
  };
  useEffect(() => {
    getPaymentMethod();
  }, []);

  const submit = async () => {
    if (loading) return;

    setLoading(true);

    // Stripe.js hasn't loaded yet
    if (!stripe || !elements) return;

    const setupIntentRes = await apiGetCardSetupIntent();
    if (setupIntentRes.status !== 'success') {
      setLoading(false);
      return alert(`Error setting up payment: ${setupIntentRes.message}`);
    }

    const result = await stripe.confirmCardSetup(setupIntentRes.data.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: me.company_name,
          // address,
          // email,
          // phone,
        },
        metadata: {
          userId: me.id,
          userName: me.name,
          companyName: me.company_name,
        },
      }
    });

    console.log("Settings - PaymentSettings - submit - stripe card res", result);
    // {
    //   setupIntent: {
    //     cancellation_reason: null,
    //     client_secret: "seti_1I1g...wH",
    //     created: 1608764124,
    //     description: null,
    //     id: "seti_1I...p",
    //     last_setup_error: null,
    //     livemode: false,
    //     next_action: null,
    //     object: "setup_intent",
    //     payment_method: "pm_1I1g...I",
    //     payment_method_types: ["card"],
    //     status: "succeeded",
    //     usage: "off_session",
    //   },
    // }

    if (result.error) {
      // Display result.error.message in your UI
      alert(`Error saving card: ${result.error.message}`);
    } else {
      // Show success
      // send result.setupIntent.payment_method to server to save the card to the Customer
      await getPaymentMethod();
      await updateMe();
    }

    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // don't refresh page
    await submit();
  };

  return (
    <View style={styles.card}>
      <Text style={styles.heading}>Payment settings</Text>

      <Text style={{ paddingHorizontal: 4, fontSize: 14, ...maybeRounded('500'), color: '#0F1D40' }}>We partner with Stripe for payments — your card details are never sent to our servers.</Text>

      {!!paymentMethod && !!paymentMethod.card &&
        <Fragment>
          <Text style={{ fontSize: 14, color: '#0F1D40' }}>
            <Text style={{ ...maybeRounded('500') }}>Currently on file: </Text>
            {paymentMethod.card.brand} ending in {paymentMethod.card.last4}
          </Text>
        </Fragment>
      }

      <form onSubmit={handleSubmit} style={divViewStyle}>

        {/*<Text>{JSON.stringify(paymentMethod)}</Text>*/}

        <CardElement
          options={{
            style: {
              base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
              },
            },
          }}
        />

        <Touchable disabled={!stripe || loading} onPress={submit} style={styles.button}>
          {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={styles.buttonText}>Save</Text>}
        </Touchable>
      </form>
    </View>
  );
}

function EmailSettings() {
  const me = useSelector(state => state.me);

  const [loading, setLoading] = useState(false);
  const [notifOnUserApplied, setNotifOnUserApplied] = useState(me.company_notif_on_user_applied === false ? false : true);
  const [notifOnUserDealUpdate, setNotifOnUserDealUpdate] = useState(me.company_notif_on_user_deal_update === false ? false : true);
  const [notifOnUserDealMessage, setNotifOnUserDealMessage] = useState(me.company_notif_on_user_deal_message === false ? false : true);

  const submit = async () => {
    setLoading(true);
    const res = await apiUpdateEmailSettings(notifOnUserApplied, notifOnUserDealUpdate, notifOnUserDealMessage);
    if (res.status !== 'success') {
      setLoading(false);
      alert(`Error updating email settings: ${res.message}`);
    }
    setLoading(false);
  };

  return (
    <View style={styles.card}>
      <Text style={styles.heading}>Email settings</Text>
      <Touchable onPress={() => setNotifOnUserApplied(x => !x)} style={styles.checkboxRow}>
        <View style={[styles.checkbox, notifOnUserApplied && { backgroundColor: '#0F1D40' }]}>
          {notifOnUserApplied && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} /> }
        </View>
        <Text style={styles.checkboxText}>Notify me when a creator applies to a campaign</Text>
      </Touchable>
      {/*<Touchable onPress={() => setNotifOnUserDealUpdate(x => !x)} style={styles.checkboxRow}>*/}
      {/*  <View style={[styles.checkbox, notifOnUserDealUpdate && { backgroundColor: '#3A3E41' }]}>*/}
      {/*    {notifOnUserDealUpdate && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} /> }*/}
      {/*  </View>*/}
      {/*  <Text style={styles.checkboxText}>Notify me when a creator upload or posts their video</Text>*/}
      {/*</Touchable>*/}
      {/*<Touchable onPress={() => setNotifOnUserDealMessage(x => !x)} style={styles.checkboxRow}>*/}
      {/*  <View style={[styles.checkbox, notifOnUserDealMessage && { backgroundColor: '#3A3E41' }]}>*/}
      {/*    {notifOnUserDealMessage && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} /> }*/}
      {/*  </View>*/}
      {/*  <Text style={styles.checkboxText}>Notify me when a creator messages me</Text>*/}
      {/*</Touchable>*/}

     <Touchable onPress={submit} style={styles.button}>
        {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={styles.buttonText}>Save</Text>}
      </Touchable>
    </View>
  );
}

function OrganizationMember({ item, refresh }) {
  const me = useSelector(state => state.me);

  const { id, email, name, type } = item;

  const [loading, setLoading] = useState(false);
  const sendReminder = async () => {
    setLoading(true);
    const res = await apiSendPendingMemberReminderEmail(id);
    if (res.status !== 'success') {
      setLoading(false);
      alert(`Error sending reminder: ${res.message}`);
    }
    await refresh();
    setLoading(false);
  };
  const removePendingUser = async () => {
    setLoading(true);
    const res = await apiRemovePreApprovedEmail(id);
    if (res.status !== 'success') {
      setLoading(false);
      alert(`Error removing: ${res.message}`);
    }
    await refresh();
    setLoading(false);
  };

  return (
    <View style={{ borderRadius: 16, padding: 20, borderColor: '#E9EAED', borderWidth: 1 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
        <Text style={[{ color: id === me.id ? '#3B51F5' : '#0F1D40' }, { ...maybeRounded('500'), fontSize: 16 }]}>{name ? `${name}` : ''}</Text>
        <Text style={[{ color: id === me.id ? '#3B51F5' : '#0F1D40' }, { ...maybeRounded('500'), fontSize: 16 }]}>{email ? `(${email})` : ''}{id === me.id ? ' (you!)' : ''}</Text>
      </View>
      {type === 'pending' &&
        <View style={{ flexDirection: 'row', marginTop: 12, justifyContent: 'flex-end' }}>
          {loading
           ? <ActivityIndicator/>
           : <Fragment>
               <Text onPress={sendReminder} style={{ textDecorationLine: 'underline', color: '#32357a' }}>Send reminder</Text>
               <View style={{ width: 8 }}/>
               <Text onPress={removePendingUser} style={{ textDecorationLine: 'underline', color: '#32357a' }}>Remove</Text>
             </Fragment>
          }
        </View>
      }
    </View>
  );
}

function OrganizationMembers() {

  const [membersData, setMembersData] = useState(null);
  const getMembers = async () => {
    const res = await apiGetCurrentAndPendingMembers();
    if (res.status === 'success') {
      console.log("Settings - OrganizationMembers - getMembers res", res.data);
      setMembersData(res.data);
    } else {
      alert(`Error fetching organization members: ${res.message}`);
    }
  };
  useEffect(() => {
    getMembers();
  }, []);

  const data = useMemo(() => {
    if (!membersData) {
      return {
        members: [],
        pending: [],
      };
    }

    let { preApprovedEmails, members } = membersData;

    let existingEmails = new Set();
    members.forEach(m => m.email && existingEmails.add(m.email.trim().toLowerCase()));

    return {
      members: members.map(m => ({ ...m, type: 'member' })),
      pending: preApprovedEmails.filter(e => !existingEmails.has(e.email.trim().toLowerCase())).map(e => ({ ...e, type: 'pending' })),
    };
  }, [membersData]);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const submit = async () => {
    if (!email.trim()) return alert(`Invalid email`);
    if (!name.trim()) return alert(`Invalid name`);
    setLoading(true);
    const res = await apiAddPreApprovedEmail(email, name);
    if (res.status !== 'success') {
      setLoading(false);
      alert(`Error sending invite: ${res.message}`);
    } else {
      setName('');
      setEmail('');
    }
    await getMembers();
    setLoading(false);
  };

  return (
    <View style={styles.card}>
      <Text style={styles.heading}>Organization members</Text>
      {data.members.map(d => <OrganizationMember item={d} refresh={getMembers} key={d.type + " " + d.id}/>)}
      
      {!!(data.pending && data.pending.length) &&
        <Fragment>
          <Text style={styles.subHeading}>Pending Invites</Text>
          {data.pending.map(d => <OrganizationMember item={d} refresh={getMembers} key={d.type + " " + d.id}/>)}
        </Fragment>
      }

      <Text style={styles.subHeading}>Add another member</Text>
      <TextInput
        style={styles.input}
        value={name}
        onChangeText={setName}
        placeholder={"Name"}
        placeholderTextColor={'#777E93'}
      />
      <TextInput
        style={styles.input}
        value={email}
        onChangeText={setEmail}
        placeholder={"Email"}
        placeholderTextColor={'#777E93'}
      />
      <Touchable disabled={loading} onPress={submit} style={styles.button}>
        {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={styles.buttonText}>Send invite</Text>}
      </Touchable>
    </View>
  );
}

export default function Settings() {
  return (
    <View style={{ backgroundColor: '#F8F9FA', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
      <Navbar logoutBar/>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ alignItems: 'center', justifyContent: 'center', padding: 32 }}
      >
        <View style={{ flexDirection: 'column', gap: 16 }}> 
          <EmailSettings/>
          <PaymentSettings/>
          <OrganizationMembers/>
        </View>
      </ScrollView>
    </View>
  );
}


const styles = StyleSheet.create({
  checkbox: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#999999',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  checkboxRow: {
    flexDirection: 'row',
    paddingHorizontal: 4,
    gap: 6
  },
  checkboxText: {
    fontSize: 14,
    color: '#0F1D40',
    ...maybeRounded('500')
  },
  checkBoxIcon: {
    height: 10,
    width: 10
  },
  input: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#E9EAED',
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 20,
    paddingVertical: 16,
    ...maybeRounded('500'),
    fontSize: 14,
    lineHeight: 20,
    color: '#000000',
  },
  button: { 
    alignSelf: 'flex-start', 
    justifyContent: 'center', 
    alignItems: 'center', 
    borderRadius: 62,
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: '#3B51F5',
    boxShadow: '0px 6px 20px 0px #00000008'
  },
  buttonText: {
    fontSize: 12, 
    ...maybeRounded('600'), 
    color: '#fff'
  },
  card: {
    width: 590, 
    padding: 20, 
    backgroundColor: '#fff', 
    borderRadius: 12, 
    flexDirection: 'column', 
    gap: 16
  },
  heading: { fontWeight: '600', fontSize: 16, color: '#0F1D40' },
  subHeading: { fontWeight: '600', fontSize: 14, color: '#0F1D40' },

});
