import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CameraIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M22.25 5.167h-6.352l-1.283-2.155a.696.696 0 00-.598-.34H9.983a.696.696 0 00-.598.34L8.102 5.167H6.017v-.475a.696.696 0 00-.695-.696H2.748a.696.696 0 00-.696.696v.475H1.75A1.75 1.75 0 000 6.916V19.58c0 .966.783 1.749 1.75 1.749h20.5A1.75 1.75 0 0024 19.58V6.917a1.75 1.75 0 00-1.75-1.75zM12 18.227a4.979 4.979 0 110-9.958 4.979 4.979 0 010 9.958zm9.16-9.454h-2.18a.696.696 0 110-1.391h2.18a.696.696 0 110 1.391z"
      />
      <Path
        d="M12 16.726a3.478 3.478 0 100-6.956 3.478 3.478 0 000 6.956z"
      />
    </Svg>
  );
};

export default CameraIcon;