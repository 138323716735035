
// initial state
const initialState = {
  token: '', // string | ''
  userId: '', // number | ''
  adminToken: '', // for going back to luke/raph dashboard after logging in as a company
  adminUserId: '', // number | ''
  me: null,
  dealsInfo: null,
  companyInfo: null,
  paymentMethod: null,
  dealChannelsList: null,
  dealChannelsAndMessages: {}, // [channelId]: response from apiGetDealChannelAndMessages
};

// action types
const LOGIN = "LOGIN";
const ADD_ME = "ADD_ME";
const ADD_DEALS_INFO = "ADD_DEALS_INFO";
const ADD_COMPANY_INFO = "ADD_COMPANY_INFO";
const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
const ADD_DEAL_CHANNELS_LIST = "ADD_DEAL_CHANNELS_LIST";
const ADD_DEAL_CHANNEL_AND_MESSAGES = "ADD_DEAL_CHANNEL_AND_MESSAGES";
const LOCAL_MARK_DEAL_CHANNEL_READ = "LOCAL_MARK_DEAL_CHANNEL_READ";
const LOGOUT = "LOGOUT";
const ADMIN_LOGIN_AS = "ADMIN_LOGIN_AS";


// simple actions
export const login = (token: string, userId: number) => ({ type: LOGIN, payload: { token, userId } });
export const loginAsUser = (token: string, userId: number) => ({ type: ADMIN_LOGIN_AS, payload: { token, userId } });
export const addMe = (me: object) => ({ type: ADD_ME, payload: me });
export const addDealsInfo = (dealsInfo: object) => ({ type: ADD_DEALS_INFO, payload: dealsInfo });
export const addCompanyInfo = (companyInfo: { email: string }) => ({ type: ADD_COMPANY_INFO, payload: companyInfo });
export const addPaymentMethod = (paymentMethod: { card: object | null }) => ({ type: ADD_PAYMENT_METHOD, payload: paymentMethod });
export const addDealChannelsList = ({ dealChannelsList }) => ({ type: ADD_DEAL_CHANNELS_LIST, payload: { dealChannelsList } });
export const addDealChannelAndMessages = (dealChannelId: number, dealChannelAndMessages: object) => ({ type: ADD_DEAL_CHANNEL_AND_MESSAGES, payload: { dealChannelId, dealChannelAndMessages } });
export const localMarkDealChannelRead = (dealChannelId: number) => ({ type: LOCAL_MARK_DEAL_CHANNEL_READ, payload: dealChannelId });
export const logout = () => ({ type: LOGOUT, payload: null });


// reducer
function rootReducer(state = initialState, action: { type: string, payload: any }) {
  if (action.type === LOGIN) {
    const { token, userId } = action.payload;
    return { ...state, token, userId };
  }
  if (action.type === ADMIN_LOGIN_AS) {
    const { token, userId } = action.payload;
    return { ...initialState, token, userId, adminToken: state.token, adminUserId: state.userId };
  }
  if (action.type === ADD_ME) {
    const me = action.payload;
    return { ...state, me };
  }
  if (action.type === ADD_DEALS_INFO) {
    const dealsInfo = action.payload;
    return { ...state, dealsInfo };
  }
  if (action.type === ADD_COMPANY_INFO) {
    const companyInfo = action.payload;
    return { ...state, companyInfo };
  }
  if (action.type === ADD_PAYMENT_METHOD) {
    const paymentMethod = action.payload;
    return { ...state, paymentMethod };
  }
  if (action.type === ADD_DEAL_CHANNELS_LIST) {
    const { dealChannelsList } = action.payload;
    return { ...state, dealChannelsList };
  }
  if (action.type === ADD_DEAL_CHANNEL_AND_MESSAGES) {
    const { dealChannelId, dealChannelAndMessages } = action.payload;
    return {
      ...state,
      dealChannelsAndMessages: {
        ...state.dealChannelsAndMessages,
        [dealChannelId]: dealChannelAndMessages
      }
    };
  }
  if (action.type === LOCAL_MARK_DEAL_CHANNEL_READ) {
    const dealChannelId = action.payload;
    return {
      ...state,
      dealChannelsList: state.dealChannelsList && state.dealChannelsList.map(dc => {
        if (dc.id !== dealChannelId) return dc;
        return {
          ...dc,
          unread: false,
        };
      }),
    };
  }
  if (action.type === LOGOUT) {
    if (state.adminToken) { // if you did temp login as someone else, go back to being admin
      return {
        ...initialState,
        userId: state.adminUserId,
        token: state.adminToken,
        adminUserId: null,
        adminToken: null,
      };
    }
    return {
      ...initialState,
    };
  }
  return state;
}
export default rootReducer;
