import { store } from '../redux/store';
import { limitLength } from '../util/limitLength';
import { customLog } from '../util/customLog';

const HOST = '';
// const HOST = 'https://duet.social';
export const WS_HOST = (window.location.host.startsWith('localhost') ? 'ws://0.0.0.0' : 'wss://' + window.location.host);
// export const WS_HOST = 'wss://duet.social';
// export const WS_HOST = "https://duet.social";

const authHeader = () => {
  let session = store.getState().token;
  let headers = new Headers({'Content-Type': 'application/json'});
  if (session) headers.append('Authorization', 'Bearer ' + session);
  return { headers, session };
};

const resToJSON = async (res, route, hadSession, startTime) => {
  let text, json, err;

  try {
    text = await res.text();
    json = JSON.parse(text);
  } catch(e) {
    err = e;
    json = { status: 'fail', message: 'Error making request' };
  }

  if (err) console.warn(`JSON error from ${route}.\n${err}\n>>>>> Response:\n${text || res}`);
  if (!err && json && json.status !== 'success' && json.message === 'Not logged in') console.warn("Not logged in");

  const timeTaken = (new Date()) - startTime;
  const kb = Math.trunc(((text && text.length) || 0)/10)/100;
  customLog('gotJson', '🆗', `${route} | ${json.status} | ${timeTaken}ms | ${kb}kb | msg ${limitLength(json.message, 400)}`);

  return json;
};

export const get = async (route) => {
  customLog('get', '↘️ ', route);
  const startTime = new Date();

  let res;
  const { session, headers } = authHeader();

  try {
    res = await fetch(`${HOST}${route}`, {
      method: 'GET',
      credentials: 'include',
      headers,
    });
  } catch (e) {
    console.warn(`Error making GET to ${route}: ${e}`);
  }

  return resToJSON(res, route, !!session, startTime);
};

export const post = async (route, data) => {
  customLog('post', '↗️ ', route, limitLength(JSON.stringify(data), 40));
  const startTime = new Date();

  let body = JSON.stringify(data);
  let res;
  const { session, headers } = authHeader();

  try {
    // clearCookies();
    res = await fetch(`${HOST}${route}`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body,
    });
  } catch (e) {
    console.warn(`Error making POST to ${route} with data ${JSON.stringify(data)}: ${e}`);
  }

  return resToJSON(res, route, !!session, startTime);
};