import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import { minsBetweenTimes, timestampToTimeAndMaybeDate } from "../../util/dateUtil";
import { Image, Text, View } from "react-native";
import Touchable from "../../components/Touchable";
import { maybeRounded } from "../../assets/assets";

export const LEFT_GUTTER_WIDTH = 52;
export const RIGHT_GUTTER_WIDTH = 20;

export default function MockMessage({ text, isMe, isFirst, isLast, propic_url, intro }) {
  const isPressed = false;
  const isHeld = false;

  return (
    <View style={{ width: '100%' }}>

      <View>
        <View style={{ flexDirection: 'row', paddingTop: (isFirst || isPressed) ? 7 : 1, paddingBottom: (isLast || isPressed) ? 7 : 1, alignItems: 'flex-end', }}>
          <View style={{ width: LEFT_GUTTER_WIDTH }}>
            {!isMe && isLast && !!propic_url &&
              <Touchable style={{ width: LEFT_GUTTER_WIDTH, paddingLeft: 12, paddingRight: LEFT_GUTTER_WIDTH-12-29 }}>
                <Image
                  source={{ uri: propic_url || undefined }}
                  style={{ backgroundColor: '#e0e0e0', width: 29, height: 29, borderRadius: 29/2.0 }}
                  resizeMode={'cover'}
                />
              </Touchable>
            }
          </View>

          <View style={{ flex: 1 }}>
            <View>
              <View style={{ flexDirection: 'row', alignSelf: isMe ? 'flex-end' : 'flex-start', maxWidth: '100%' }}>
                <View style={{ width: '100%', maxWidth: 400 }}>
                  <View style={{
                    backgroundColor: isMe
                      ? (isPressed || isHeld ? '#0047b3' : '#0065fd')
                      : (isPressed || isHeld ? '#bababa' : '#efefef'),
                    paddingHorizontal: 12,
                    paddingVertical: isPressed ? 10 : 8,
                    borderTopLeftRadius: !isMe && !isFirst ? 4 : 18,
                    borderTopRightRadius: isMe && !isFirst ? 4 : 18,
                    borderBottomRightRadius: isMe && !isLast ? 4 : 18,
                    borderBottomLeftRadius: !isMe && !isLast ? 4 : 18,
                    maxWidth: '100%',
                  }}>
                    <Text style={{ ...maybeRounded('400'), fontSize: 16, color: isMe ? '#fff' : '#020202', letterSpacing: 0.3 }}>
                      {!!intro && <Text style={{ ...maybeRounded('600') }}>{intro}</Text>}
                      {text}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: RIGHT_GUTTER_WIDTH }}>

          </View>
        </View>
      </View>
    </View>
  );
}