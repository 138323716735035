import React, { Fragment, useState, useEffect } from "react";
import Touchable from "../../components/Touchable";
import { maybeRounded } from "../../assets/assets";
import Navbar from "../../components/Navbar";
import CreateCampaignModel from "./CreateCampaignModel";
import { ActivityIndicator, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { updateDealChannelsList, updateDealsInfo } from "../../redux/asyncActions";
import { apiCloseDeal, apiUpdateDealDetails } from "../../api/api";
import ConvoScreen from "../Messages/ConvoScreen";
import SubmissionList from "./SubmissionList";
import Modal from "../../components/Modal";
import arrowRight from "../../assets/icons/arrow-right-s-line.png";
import PlusIcon from "../../components/svg-icons/PlusIcon";

export default function Dashboard() {
  const dealsInfo = useSelector((state) => state.dealsInfo);
  const deals = dealsInfo && dealsInfo.deals;
  const dealSubmissions = dealsInfo && dealsInfo.submissions;

  const [newCampaignModalVisible, setNewCampaignModalVisible] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [selectedTab, setSelectedTab] = useState("received"); // oneOf(['received', 'in_progress', 'complete'])
  const [selectedSubmission, setSelectedSubmission] = useState(null);

  const getDeals = async () => {
    const res = await updateDealsInfo();
    if (res.status !== "success") {
      console.log("Dashboard - updateDealsInfo error", res.message);
      alert(`Error fetching deals — try refreshing the page. More info: ${res.message}`);
    }
  };
  useEffect(() => {
    getDeals();
    updateDealChannelsList();
  }, []);

  useEffect(() => {
    if (!selectedCampaignId && deals && deals.length) setSelectedCampaignId(deals[0].id);
  }, [selectedCampaignId, deals]);

  const selectedDeal = selectedCampaignId && deals && deals.find((d) => d.id === selectedCampaignId);
  const selectedDealSubmissions = selectedCampaignId && dealSubmissions && dealSubmissions.filter((ds) => ds.deal_id === selectedCampaignId);

  useEffect(() => {
    if (selectedDeal && selectedDeal.campaign_type === "install" && selectedTab === "complete") {
      setSelectedTab("in_progress");
    }
  }, [selectedTab, selectedDeal]);

  return (
    <View style={{ backgroundColor: "#F8F9FA", position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
      <Navbar logoutBar />
      <View style={{ flexDirection: "row", flex: 1 }}>
        <View style={{ paddingLeft: 32, paddingTop: 32, width: 281 }}>
          <CampaignsList campaigns={deals} selectedCampaignId={selectedCampaignId} setSelectedCampaignId={setSelectedCampaignId} setNewCampaignModalVisible={setNewCampaignModalVisible} />
        </View>

        {!dealsInfo && (
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <ActivityIndicator />
            <View style={{ height: 16 }} />
            <Text style={{ ...maybeRounded("500"), fontSize: 16 }}>Loading Deals...</Text>
          </View>
        )}

        {!!dealsInfo && deals.length === 0 && (
          <ScrollView style={{ maxWidth: 500 }} contentContainerStyle={{ paddingHorizontal: 30 }}>
            <Text style={{ ...maybeRounded("500"), fontSize: 20 }}>Welcome to Swipehouse!</Text>
            <View style={{ height: 8 }} />
            <Text style={{ ...maybeRounded("400"), fontSize: 16 }}>Where brands connect with tiktokers</Text>
            <View style={{ height: 20 }} />
            <Text style={{ ...maybeRounded("400"), fontSize: 16 }}>With over 600 million followers across our creators, we make it easy to get UGC content and run influencer campaigns with tiktokers</Text>
            <View style={{ height: 20 }} />

            <Text style={{ ...maybeRounded("400"), fontSize: 16 }}>
              New here?{" "}
              <a style={{ ...maybeRounded("500"), color: "#305dff" }} target={"_blank"} href={"https://calendly.com/owen-guetschow/30min"}>
                Book a time to talk with us!
              </a>
            </Text>
            <View style={{ height: 50 }} />

            <Text style={{ ...maybeRounded("500"), fontSize: 20 }}>How it works:</Text>

            <View style={{ height: 8 }} />
            <View style={styles.card}>
              <Text style={styles.cardTitle}>1.</Text>
              <Text style={styles.cardSubtitle}>Create your campaign. We'll review it & make it visible to creators.</Text>
              <View style={{ paddingLeft: 8, justifyContent: "center", alignItems: "center", paddingRight: 8, marginTop: 8 }}>
                <Text style={{ fontSize: 32 }}>📝</Text>
              </View>
            </View>
            <View style={{ height: 8 }} />
            <View style={styles.card}>
              <Text style={styles.cardTitle}>2.</Text>
              <Text style={styles.cardSubtitle}>View applications as they come in, and accept or reject them.</Text>
              <View style={{ paddingLeft: 8, justifyContent: "center", alignItems: "center", paddingRight: 8, marginTop: 8 }}>
                <Text style={{ fontSize: 30 }}>✅</Text>
              </View>
            </View>
            <View style={{ height: 8 }} />
            <View style={styles.card}>
              <Text style={styles.cardTitle}>3.</Text>
              <Text style={styles.cardSubtitle}>Send creators anything extra they need. They'll then shoot their video.</Text>
              <View style={{ paddingLeft: 8, justifyContent: "center", alignItems: "center", paddingRight: 8, marginTop: 8 }}>
                <Text style={{ fontSize: 32 }}>📦</Text>
              </View>
            </View>
            <View style={{ height: 8 }} />
            <View style={styles.card}>
              <Text style={styles.cardTitle}>4.</Text>
              <Text style={styles.cardSubtitle}>Review videos. When ready, we'll charge you and pay the creator.</Text>
              <View style={{ paddingLeft: 8, justifyContent: "center", alignItems: "center", paddingRight: 8, marginTop: 8 }}>
                <Text style={{ fontSize: 32 }}>🚀</Text>
              </View>
            </View>
            <View style={{ height: 8 }} />
            <Text>
              <a style={{ textDecorationLine: "none" }} target={"_blank"} href={"https://www.notion.so/lukeheine/Swipehouse-Example-Videos-f7843d8040cf4154bc17cf09cdf0bb5b"}>
                Here are some example vids 💫
              </a>
            </Text>
            <View style={{ height: 20 }} />

            <Text style={{ fontSize: 16 }}>
              <a style={{ color: "#555", textDecorationLine: "none" }} target={"_blank"} href={"mailto:team@swipehouse.com?subject=Swipehouse for Brands"}>
                Questions? <span style={{ textDecorationLine: "underline" }}>Send us an email</span>
              </a>
            </Text>
            <View style={{ height: 20 }} />
          </ScrollView>
        )}

        {!!dealsInfo && !!deals.length && !!selectedDeal && (
          <View style={{ width: "600px", alignItems: "center", paddingHorizontal: 32, paddingTop: 32 }}>
            <DealInfo deal={selectedDeal} dealSubmissions={selectedDealSubmissions} />
            <SubmissionList deal={selectedDeal} dealSubmissions={selectedDealSubmissions} selectedTab={selectedTab} setSelectedTab={setSelectedTab} setSelectedSubmission={setSelectedSubmission} />
          </View>
        )}

        {!!selectedSubmission && <ConvoScreen name={selectedSubmission.name} userId={selectedSubmission.user_id} dealId={selectedSubmission.deal_id} />}

        {/*</View>*/}
        {/*<View style={{ flex: 1, backgroundColor: '#fff', borderRadius: 8, borderWidth: 1, borderColor: '#EBEBEB', minWidth: 400, maxHeight: 700 }}>*/}
        {/*  <Text>Order Details</Text>*/}
        {/*  <Text>Total Price</Text>*/}
        {/*/!*  /!*<Video*!/*!/*/}
        {/*/!*  /!*  source={{ uri: selectedSubmission. }}*!/*!/*/}
        {/*/!*  /!*  resizeMode={Video.RESIZE_MODE_CONTAIN}*!/*!/*/}
        {/*/!*  /!*  style={[StyleSheet.absoluteFill, currentlyViewing !== 'provid' && { opacity: 0 }]}*!/*!/*/}
        {/*/!*  /!*  // style={[StyleSheet.absoluteFill, { backgroundColor: '#000' }]}*!/*!/*/}
        {/*/!*  /!*  // shouldPlay={isFocused && currentlyViewing === 'provid'}*!/*!/*/}
        {/*/!*  /!*  // isLooping*!/*!/*/}
        {/*/!*  /!*  // isMuted={!isFocused || muted}*!/*!/*/}
        {/*/!*  /!*  // onPlaybackStatusUpdate={playbackStatus => {*!/*!/*/}
        {/*/!*  /!*  //   // toggle between showing video reply and provid for pending match item*!/*!/*/}
        {/*/!*  /!*  //   if (playbackStatus.didJustFinish && currentlyViewing === 'provid' && pendingMatchItem && pendingMatchItem.vid_url) {*!/*!/*/}
        {/*/!*  /!*  //     console.log("Just finished2");*!/*!/*/}
        {/*/!*  /!*  //     toggleViewing();*!/*!/*/}
        {/*/!*  /!*  //   }*!/*!/*/}
        {/*/!*  /!**!/*/}
        {/*</View>*/}

        <CreateCampaignModel visible={newCampaignModalVisible} toggleCampaignModal={setNewCampaignModalVisible} />
      </View>
    </View>
  );
}

function CampaignStatusIndicator({ deal }) {
  const [cancelCampaignModalVisible, setCancelCampaignModalVisible] = useState(false);
  const [loadingEndApps, setLoadingEndApps] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postSpecs, setPostSpecs] = useState(deal.post_specs);
  const [videoSpecs, setVideoSpecs] = useState(deal.video_specs);
  const [brandDescription, setBrandDescription] = useState(deal.brand_description);
  const [installReqs, setInstallReqs] = useState(deal.video_specs);
  const [showEditCampaignModal, setShowEditCampaignModal] = useState(false);

  useEffect(() => {
    setPostSpecs(deal.post_specs);
    setVideoSpecs(deal.video_specs);
    setBrandDescription(deal.brand_description);
    setInstallReqs(deal.install_reqs);
  }, [deal.post_specs, deal.video_specs, deal.brand_description, deal.install_reqs]);
  const updateCampaign = async () => {
    setLoading(true);
    const res = await apiUpdateDealDetails(deal.id, postSpecs, videoSpecs, brandDescription, installReqs);
    if (res.status === "success") {
      console.log("Dashboard - CampaignStatusIndicator - updateCampaign - apiUpdateDealDetails success - res", res.data);
      await updateDealsInfo(); // maybe don't need this
      setLoading(false);
      setShowEditCampaignModal(false);
    } else {
      setLoading(false);
      console.log("Dashboard - CampaignStatusIndicator - updateCampaign - apiUpdateDealDetails error - res", res.message);
      alert(`Error updating deal info: ${res.message}`);
    }
  };

  const endReceivingApps = async () => {
    setLoadingEndApps(true);
    const res = await apiCloseDeal(deal.id);
    if (res.status !== "success") {
      setLoadingEndApps(false);
      console.log("Dashboard - CampaignStatusIndicator - endReceivingApps - apiCloseDeal error - res", res.message);
      alert(`Error closing submissions — try again or contact the team. More info: ${res.message}`);
    } else {
      await updateDealsInfo();
      setLoadingEndApps(false);
      setCancelCampaignModalVisible(false);
    }
  };

  const adjustedLinkPrice = (deal.base_price_per_install_usd + deal.our_price_on_top_per_install_usd).toFixed(2);

  return (
    <Fragment>
      {deal.status === "open" && (
        <View style={{ justifyContent: "space-between", width: "100%", flexDirection: "row", alignItems: "center", marginTop: 4 }}>
          <Touchable onPress={() => setCancelCampaignModalVisible(true)}>
            <Text style={{ fontSize: 14 }}>
              Status: Accepting Applications.{" "}
              <Text onPress={() => setCancelCampaignModalVisible(true)} style={{ textDecorationLine: "underline", color: "#333" }}>
                Stop
              </Text>
            </Text>
          </Touchable>
          {/*<Text style={{ fontSize: 16, marginTop: 10, color: '#4A4A4A', ...maybeRounded('700'), textDecorationLine: 'none' }}>End Applications</Text>*/}
          <Touchable onPress={() => setShowEditCampaignModal(true)}>
            <Text style={{ textDecorationLine: "underline", color: "#333" }}>Edit Campaign</Text>
          </Touchable>
        </View>
      )}
      <Modal visible={cancelCampaignModalVisible} close={() => setCancelCampaignModalVisible(false)} style={{ width: "100%", height: "100%", borderWidth: 0, borderColor: "none", backgroundColor: "rgba(0,0,0,.3)", alignItems: "center", justifyContent: "center" }}>
        <View style={{ width: 300, height: 150, backgroundColor: "white", borderRadius: 10, alignItems: "center", justifyContent: "center", padding: 5 }}>
          <View>
            <Text style={{ ...maybeRounded("600"), fontSize: 16, color: "#16191C", marginBottom: 36 }}>Stop Receiving Submissions?</Text>
          </View>
          {loadingEndApps && <ActivityIndicator />}
          {!loadingEndApps && (
            <View style={{ flexDirection: "row" }}>
              <Touchable onPress={endReceivingApps} style={{ paddingHorizontal: 15, height: 35, backgroundColor: "#3E8BFF", alignItems: "center", justifyContent: "center", borderRadius: 3, marginRight: 5 }}>
                <Text style={{ color: "white", fontWeight: "700", fontSize: 16, ...maybeRounded("600") }}>Yes</Text>
              </Touchable>
              <Touchable onPress={() => setCancelCampaignModalVisible(false)} style={{ paddingHorizontal: 15, height: 35, backgroundColor: "#3E8BFF", alignItems: "center", justifyContent: "center", borderRadius: 3, marginLeft: 5 }}>
                <Text style={{ color: "white", fontWeight: "700", fontSize: 16, ...maybeRounded("600") }}>No</Text>
              </Touchable>
            </View>
          )}
        </View>
      </Modal>
      <Modal visible={showEditCampaignModal} close={() => setShowEditCampaignModal(false)}>
        <ScrollView style={{ backgroundColor: "#fcfdff", flex: 1, width: 500, borderRadius: 5, paddingHorizontal: 25, paddingVertical: 10, borderTopWidth: 1, borderBottomWidth: 1, borderColor: "#EBEBEB", maxHeight: 900 }}>
          <View style={{ height: 55 }} />
          <Touchable onPress={() => setShowEditCampaignModal(false)} style={{ position: "absolute", top: 30, right: 10 }}>
            <Text style={styles.descriptionText}>Close</Text>
          </Touchable>
          <Text style={styles.headerOne}>Edit campaign ✏️️️️</Text>

          {deal.campaign_type !== "install" && (
            <Fragment>
              <Text style={styles.headerTwo}>{deal.campaignType === "Post" && "Base "}Price per Video (USD)</Text>
              <TextInput
                style={[styles.input, { color: "grey" }]}
                value={deal.base_price_usd + deal.our_price_on_top_usd}
                // editable={false} // deprecated in RNW 0.19
                readOnly={true}
                // placeholder={'$80'}
                // placeholder={'80.00'}
                placeholder={"200"}
              />
            </Fragment>
          )}
          {deal.campaign_type === "install" && (
            <Fragment>
              <Text style={styles.headerTwo}>{deal.campaignType === "Post" && "Base "}Price per Install (USD)</Text>
              <TextInput
                style={[styles.input, { color: "grey" }]}
                value={adjustedLinkPrice}
                // editable={false} // deprecated in RNW 0.19
                readOnly={true}
                placeholder={"200"}
              />
            </Fragment>
          )}

          <Text style={styles.headerTwo}>Your brand description</Text>
          <TextInput style={styles.inputMultiline} onChangeText={setBrandDescription} value={brandDescription} placeholder={"Finding a watch that’s stylish, bold, and built to last can cost a pretty penny, but we are here to change that. We believe you deserve to look good no matter your budget. In 2014 we started Vincero with a very simple mission: to make products without taking shortcuts, for the people who refuse to take them."} multiline={true} />
          <Text style={styles.descriptionText}>Good brand descriptions get creators excited about filming you a vid. Explain why your brand is special or unique and why a creator would want to publicly vouch for your product.</Text>
          <View style={{ height: 5 }} />
          <Text style={styles.descriptionText}>Here you can ask for a creator to post with certain hashtags or to mention your brand in their description or comments</Text>
          <View style={{ height: 5 }} />

          {deal.campaign_type === "post" && (
            <Fragment>
              <Text style={styles.headerTwo}>Your post specifications</Text>
              <TextInput style={styles.inputMultiline} onChangeText={setPostSpecs} value={postSpecs} placeholder={"Include the hashtags #savory, #gourmet in the video description. Pin this link in the comments: https://omsom.com/tiktok"} multiline={true} />
              <Text style={styles.descriptionText}>Here you can ask for a creator to post with certain hashtags or to mention your brand in their description or comments</Text>
            </Fragment>
          )}

          {deal.campaign_type !== "install" && (
            <Fragment>
              <Text style={styles.headerTwo}>Your video specifications</Text>
              <TextInput style={styles.inputMultiline} onChangeText={setVideoSpecs} value={videoSpecs} placeholder={`We want the content to feel very organic as if it is a TikTok video, not an ad.  Use a high-quality camera that is at least 1080p (newer phones totally fine). Film in a bright setting, preferably during the day. Try to show FitnessAI off in the video if you have a second phone (or show the website w/ screenshots if you want) Also okay to leave this out. We're looking for...`} multiline={true} />
              <Text style={styles.descriptionText}>
                This sets the direction for your video. Our creators work well with both concrete and vague directions. You can request for edits and remakes from creators as long as they don't meet the specs here. Some things you may want to mention:
                {"\n\n"}• How strongly you want the video to feature your product. Are you looking for videos centered around your product, product sitting on the side of a creator's normal content, do you want a shoutout of the company name / website, any of the above, etc.
                {"\n\n"}• How you want the video exported. Do you want captions, do you want it saved without the watermark, and is there a specific video length you need?
                {"\n\n"}• "Things to note" with any bullet points you want to get across in the video.
                {/*This is where you can set the direction for your video. Our creators work well with both concrete and vague directions. You don't need to have a full script and can keep it open ended. Our creators can uniquely frame your product but some structure is good. You can request for edits and remakes from creators as long as they don't meet the specs here.*/}
                {/*{'\n\n'}If you have any specific export options you want, like exporting with or without the tiktok watermark, a specific video length, or captions, be sure to include that here. If you want your product featured a certain way (just show it in the background, give the website a shoutout, etc) you should include it here as well.*/}
                {/*By default, we will ask creators to use an HD camera, export their video without a tiktok watermark, and submit a video over 4 seconds in length.*/}
              </Text>
            </Fragment>
          )}

          {deal.campaign_type === "install" && (
            <Fragment>
              <Text style={styles.headerTwo}>What counts as an install?</Text>
              <TextInput style={[styles.inputMultiline, { height: 100 }]} onChangeText={setInstallReqs} value={installReqs} placeholder={"Anyone who joins using the link we send you or with your referral code will count as an install."} multiline={true} />
            </Fragment>
          )}

          <View style={{ width: "100%", alignItems: "center", paddingTop: 20 }}>
            <Touchable onPress={updateCampaign} style={{ backgroundColor: "#513EFF", borderRadius: 40, height: 43, width: 170, alignItems: "center", justifyContent: "center" }}>
              {loading ? <ActivityIndicator /> : <Text style={{ color: "#fff", ...maybeRounded("700"), fontSize: 16 }}>Update Deal</Text>}
            </Touchable>
          </View>
          <View style={{ height: 30 }} />
        </ScrollView>
      </Modal>
    </Fragment>
  );
}

export function DealInfo({ deal, dealSubmissions, setCancelCampaignModalVisible }) {
  const { id, campaign_type, campaign_name, is_approved, post_required, post_immediately, requires_mailing, base_price_usd, our_price_on_top_usd, base_price_per_install_usd, our_price_on_top_per_install_usd } = deal;

  const adjustedDealPrice = (base_price_usd + our_price_on_top_usd).toFixed(0);
  const adjustedLinkPrice = (base_price_per_install_usd + our_price_on_top_per_install_usd).toFixed(2);

  return (
    <View
      style={{
        ...styles.dealboxStyles,
        ...styles.boxShadow,
      }}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "55%" }}>
          <Text rows={1} ellipsizeMode="tail" style={{ color: "#0F1D40", marginBottom: 5, ...maybeRounded("600"), fontSize: "16px" }}>
            {campaign_name}
          </Text>
          <Text style={{ color: "#525C76", ...maybeRounded("500"), fontSize: "12px" }}>
            {campaign_type === "ugc" && "Video Campaign"}
            {campaign_type === "post" && "Video & Post Campaign"}
            {campaign_type === "install" && "Install Campaign"}
          </Text>
        </View>
        <View style={{ width: "45%", flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "50%" }}>
            <Text style={{ color: "#0F1D40", fontSize: "14px", marginBottom: 5, ...maybeRounded("500") }}>Submissions</Text>
            <Text style={{ fontSize: "12px", ...maybeRounded("500"), color:"#525C76" }}>{dealSubmissions.length}</Text>
          </View>
          <View style={{ width: "50%" }}>
            <Text style={{ color: "#0F1D40", fontSize: "14px", marginBottom: 5, ...maybeRounded("500") }}>{deal.campaign_type === "install" ? "Price Per Install" : deal.campaign_type === "post" && !deal.is_flat_rate ? "Price Per Video" : "Price Per Video"}</Text>
            <Text style={{ fontSize: "12px", ...maybeRounded("500"), color:"#525C76" }}>${campaign_type === "install" ? adjustedLinkPrice : adjustedDealPrice}</Text>
          </View>
        </View>
      </View>
      {/* <CampaignStatusIndicator deal={deal}/> */}
    </View>
  );
}

export function CampaignsList({ campaigns, selectedCampaignId, setSelectedCampaignId, setNewCampaignModalVisible }) {
  const renderItem = ({ item }) => (
    <Touchable style={{ paddingHorizontal: 12, paddingVertical: 8, gap: 8, flexDirection: "row" }} onPress={() => setSelectedCampaignId(item.id)}>
      <Text style={{ color: selectedCampaignId === item.id ? "#0F1D40" : "#525C76", ...maybeRounded(selectedCampaignId === item.id ? "600" : "500"), fontSize: "14px", lineHeight: 20 }}>{item.campaign_name}</Text>
      {selectedCampaignId === item.id && <Image source={arrowRight} style={{ height: "20px", width: "20px" }} />}
    </Touchable>
  );

  return (
    <Fragment>
      <View style={{ flexDirection: 'column', gap: 16 }}>
        <Touchable onPress={() => setNewCampaignModalVisible(true)} style={styles.buttonStyle}>
          <PlusIcon style={{ alignSelf: 'center' }} width={20} height={20} />  
          <Text style={{ color: "#FFFFFF", ...maybeRounded("600"), fontSize: 16, lineHeight: 20 }}>New Campaign</Text>
        </Touchable>
        <FlatList 
          data={campaigns} 
          extraData={selectedCampaignId} 
          keyExtractor={(item) => item.id} 
          renderItem={renderItem}
          contentContainerStyle={{ gap: 8 }}
        />
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    borderRadius: 4,
    backgroundColor: "#fff",
    marginBottom: 10,
    padding: 10,
    ...maybeRounded("500"),
    fontSize: 15,
    color: "#000000",

    borderWidth: 1,
    borderColor: "#dddfe1",
  },
  inputMultiline: {
    height: 170,
    borderRadius: 4,
    backgroundColor: "#fff",
    marginBottom: 10,
    padding: 10,
    paddingTop: 12,
    ...maybeRounded("500"),
    fontSize: 15,
    color: "#000000",
    textAlign: "flex-start",

    borderWidth: 1,
    borderColor: "#dddfe1",
  },
  inputText: {
    ...maybeRounded("500"),
    fontSize: 16,
    color: "#8B9399",
  },
  checkbox: {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#dddfe1",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxRow: {
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 5,
  },
  checkboxText: {
    fontSize: 14,
    marginLeft: 7,
    paddingTop: 5,
    color: "#16191C",
    ...maybeRounded("600"),
  },
  descriptionText: {
    fontSize: 14,
    marginBottom: 20,
    color: "#656B73",
    ...maybeRounded("400"),
    paddingLeft: 2,
  },
  headerOne: {
    ...maybeRounded("700"),
    fontSize: 25,
    marginBottom: 20,
    color: "#16191C",
  },
  headerTwo: {
    ...maybeRounded("600"),
    fontSize: 14,
    marginBottom: 8,
    color: "#16191C",
    marginTop: 8,
  },
  jobHeaderText: {
    ...maybeRounded("700"),
    fontSize: 18,
    marginBottom: 10,
    color: "#16191C",
  },
  postPreviewContainer: {
    height: 100,
    backgroundColor: "#e7e7e7",
    borderRadius: 10,
  },
  checkBoxIcon: {
    height: 18,
    width: 18,
  },

  card: {
    flexDirection: "row",
    width: 400,
    maxWidth: "100%",
    backgroundColor: "#fff",
    borderRadius: 4,
    paddingBottom: 10,
    paddingHorizontal: 10,
    shadowColor: "#262733",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  cardTitle: {
    paddingLeft: 4,
    paddingTop: 15,
    fontSize: 14,
    fontWeight: "600",
  },
  cardSubtitle: {
    paddingTop: 14,
    flex: 1,
    fontSize: 14,
    fontWeight: "400",
    paddingLeft: 10,
    paddingRight: 5,
    color: "#444",
  },
  buttonStyle: {
    backgroundColor: "#3B51F5",
    borderRadius: 99,
    width: 208,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: 8,
    paddingVertical: 18,
    paddingHorizontal: 32,
    boxShadow: '0px 4px 8px 0px #00000029'
  },
  boxShadow: {
    shadowColor: "#000", // Use the color from the box-shadow, ignoring the alpha for shadowColor
    shadowOffset: { width: 0, height: 6 }, // Offset for x (0px) and y (6px)
    shadowOpacity: 0.08, // Convert the alpha value from #00000008 to a decimal (8% opacity)
    shadowRadius: 20, // This is the blur radius in CSS
    elevation: 4,
  },
  dealboxStyles: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderRadius: "12px",
    marginBottom: 20,
    borderWidth: 1,
    borderColor: 'transparent',
  },
});
