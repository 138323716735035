import { Dimensions, Platform } from "react-native";
const { width } = Dimensions.get('window');

// export const isEmulator = !Device.isDevice;
// export const isAndroidDev = Platform.OS === 'android' && __DEV__;
export const threePaneMode = Platform.OS === 'web' || width > 1024 || Platform.isPad;

export const cursorPointer = Platform.OS === 'web' ? { cursor: 'pointer' } : null;
export const cursorDefault = Platform.OS === 'web' ? { cursor: 'default' } : null;
export const outlineNone = Platform.OS === 'web' ? { outline: 'none' } : null;

// style a div like a View. copied from RNW View/StyleSheet/constants
export const divViewStyle = {
  alignItems: 'stretch',
  border: '0 solid black',
  boxSizing: 'border-box',
  display: 'flex',
  flexBasis: 'auto',
  flexDirection: 'column',
  flexShrink: 0,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  minHeight: 0,
  minWidth: 0,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  position: 'relative',
  zIndex: 0,
  gap: 16
};

// style an input/textarea like a TextInput. copied from RNW TextInput + StyleSheet constants
export const inputTextInputStyle = {
  MozAppearance: 'textfield',
  WebkitAppearance: 'none',
  backgroundColor: 'transparent',
  border: '0 solid black',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  boxSizing: 'border-box',
  font: '14px system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif',
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  resize: 'none',
};
