import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function EyeIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#clip0_246_6566)">
        <Path d="M12 8.139A3.865 3.865 0 008.138 12 3.865 3.865 0 0012 15.863a3.865 3.865 0 003.862-3.862A3.865 3.865 0 0012 8.139zm-.293 2.717c-.48 0-.879.4-.879.879H9.549c.027-1.199.986-2.158 2.158-2.158v1.279z" />
        <Path d="M23.72 11.201C22.415 9.576 17.754 4.275 12 4.275c-5.754 0-10.415 5.301-11.72 6.926-.373.453-.373 1.119 0 1.598 1.305 1.625 5.966 6.926 11.72 6.926 5.754 0 10.415-5.3 11.72-6.926.373-.453.373-1.118 0-1.598zM12 17.594A5.593 5.593 0 016.406 12 5.593 5.593 0 0112 6.406 5.593 5.593 0 0117.594 12 5.593 5.593 0 0112 17.594z" />
      </G>
      <Defs>
        <ClipPath id="clip0_246_6566">
          <Path fill="#fff" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
};

export default EyeIcon;
