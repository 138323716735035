import React, { useState, useEffect } from 'react';
import { Redirect, BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";
import { ActivityIndicator, View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./screens/Dashboard/Dashboard";
import Settings from "./screens/Dashboard/Settings";
import SignIn from "./screens/Signup/SignIn";
import { useEffectWithoutReturn } from "./util/useEffectWithoutReturn";
import { logout } from "./redux/reducers";
import { updateMe } from "./redux/asyncActions";
import { sleep } from "./util/sleep";
import SetCompanyInfo from "./screens/Signup/SetCompanyInfo";
import { uploadView } from "./util/view";
import useHomeSocket from "./api/homeSocket";
import AdminScreen from "./screens/Admin/AdminScreen";
import ApprovalsScreen from "./screens/Admin/ApprovalsScreen";

// import SignInPhone from "./screens/Signup/SignInPhone"; // moved to archive/2023-12-14-login-signup-screens/SignInPhone.js
// import Signup2 from "./screens/Signup/Signup2"; // moved to archive/2023-12-14-login-signup-screens/Signup2.js

function Logout() {
  const dispatch = useDispatch();
  useEffectWithoutReturn(() => dispatch(logout()), []);
  return (
    <View style={{ flex: 1, backgroundColor: '#236EFF', alignItems: "center", justifyContent: "center" }}>
      <Text style={{ fontWeight: '500', fontSize: 16, color: '#fff' }}>Logging out...</Text>
    </View>
  );
}

export default function Root() {
  const token = useSelector(state => state.token);

  useEffectWithoutReturn(() => uploadView(), [token]);

  return (
    <Router>
      <Switch>
        {!!token && <Route exact path={"/logout"} component={Logout}/>}
        {!!token && <Route component={LoggedIn}/>}
        {!token && <Route component={LoggedOut}/>}
      </Switch>
    </Router>
  );
}

const LoggedIn = ({ location }) => {

  const me = useSelector(state => state.me);

  useEffect(() => {
    if (!!me) return;
    let mounted = true;
    const f = async () => {
      console.log("LoggedIn, has me:", !!me);
      const res = await updateMe();
      if (res.status !== 'success') {
        await sleep(1000);
        mounted && f();
      }
    };
    f();
    return () => {
      mounted = false;
    }
  }, [!!me]);

  useHomeSocket();

  // if !me, return loading screen
  if (!me) {
    return (
      <View style={{ backgroundColor: '#236EFF', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator color={"#fff"}/>
      </View>
    );
  }

  console.log("LoggedIn render, me:", me);
  // still need to fill out the business signup form
  const businessSignupComplete = !!me.is_business_user;

  return (
    <Switch>
      {!businessSignupComplete && <Route exact path={'/set_company_info'} component={SetCompanyInfo}/>}
      {!businessSignupComplete && <Redirect to={'/set_company_info'}/>}

      <Route exact path={"/settings"} component={Settings}/>
      <Route exact path={"/dashboard"} component={Dashboard}/>
      <Route exact path={"/adminpanel"} component={AdminScreen}/>
      <Route exact path={"/campaign_approval"} component={ApprovalsScreen}/>
      <Redirect to={"/dashboard"}/>
    </Switch>
  );
};

const LoggedOut = ({ location }) => {
  return (
    <Switch>
      {/*<Route path={"/signup"} component={Signup2}/>*/}
      <Route path={"/login"} component={SignIn}/>
      <Redirect to={"/login"}/>
    </Switch>
  );
};
