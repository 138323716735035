import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { friendlyTimestamp, minsBetweenTimes, timestampToTimeAndMaybeDate } from "../../util/dateUtil";
import { Image, StyleSheet, Text, View } from "react-native";
import { maybeRounded } from "../../assets/assets";
import Touchable from "../../components/Touchable";
import { Video, ResizeMode } from "expo-av";
import useToggleOptions from "../../util/useToggleOptions";

// import { useFonts, ComicNeue_700Bold_Italic } from '@expo-google-fonts/comic-neue';
// import { useIsFocused, useNavigation } from "@react-navigation/native";

const audioMutedIcon = require('../../assets/images/audio.png');
const audioIcon = require('../../assets/images/audio-muted.png');
const playIcon = require('../../assets/images/icons8-play.png');
const noBgDuetIcon = require('../../assets/images/icon-no-bg.png');

const shadow = {
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.3,
  shadowRadius: 3,
};

export const LEFT_GUTTER_WIDTH = 52;
export const RIGHT_GUTTER_WIDTH = 20;

export default function Message({ item, otherUser, prevMessage, nextMessage, isDeal }) {
  const me = useSelector(state => state.me);
  const myId = useSelector(state => state.userId);
  // const navigation = useNavigation();

  const {
    id,
    uuid,
    sent_on,
    user_id,
    text,
    daily_match_id,
    from_company,

    vid_url, vid_thumb_100_url, vid_thumb_320_url, vid_thumb_800_url, vid_info, is_video_reply, prompt_text, prompt_id,
    pic_100_url, pic_320_url, pic_800_url
  } = item;
  // const { id, name, propic_100_url, propic_320_url, tiktok_handle } = otherUser;

  // let [fontsLoaded] = useFonts({ ComicNeue_700Bold_Italic });

  const isMe = !!from_company; // user_id === myId;
  const isPressed = false;
  const isHeld = false;

  const isFocused = true; // useIsFocused();
  const [videoVisible, setVideoVisible] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(true);
  // const vidRef = useRef();
  useEffect(() => {
    // if (!isFocused && vidRef.current) vidRef.current.setStatusAsync({ shouldPlay: false });
    if (!isFocused && videoVisible) setShouldPlay(false);
    if (isFocused && videoVisible) setShouldPlay(true);
  }, [isFocused, videoVisible]);

  const [imageAspectRatio, setImageAspectRatio] = useState(1);
  useEffect(() => {
    if (pic_800_url) {
      Image.getSize(pic_800_url, (width, height) => setImageAspectRatio(width/height));
    }
  }, [pic_800_url]);

  const { isFirst, isLast, showTime } = useMemo(() => {
    const sentOn = new Date(sent_on).getTime();
    const prevSentOn = prevMessage && new Date(prevMessage.sent_on).getTime();
    const nextSentOn = nextMessage && new Date(nextMessage.sent_on).getTime();
    const sinceLast = prevMessage && minsBetweenTimes(sentOn, prevSentOn);
    const untilNext = nextMessage && minsBetweenTimes(sentOn, nextSentOn);

    const prevTextFromSame = prevMessage && prevMessage.user_id === user_id;
    const nextTextFromSame = nextMessage && nextMessage.user_id === user_id;

    const hasReaction = false; // message && (message.reactions || []).length > 0;
    const prevReaction = false; // prev && (prev.reactions || []).length > 0;

    const hasReply = false; // message && (message.children || []).length > 0;
    const prevReply = false; // prev && ((prev.children || []).length > 0 || (prev.parent_message_id && !parentMessageId));

    const isStoryReply = false; // message && !!message.story_id;
    const nextIsStoryReply = false; // next && !!next.story_id;

    // if first message in array or first message from person or 10 mins from next message or there's a reaction/reply in the prev message or it's a reply to a story
    const isFirst = !prevTextFromSame || (sinceLast && sinceLast >= 10) || prevReaction || prevReply || isStoryReply;

    // if last message in array or last message from person or 10 mins since prev message or there's a reaction/reply in the message or the next message is a reply to a story
    const isLast = !nextTextFromSame || (untilNext && untilNext >= 10) || hasReaction || hasReply || nextIsStoryReply;

    // if pressed or first message in arr or 13 mins since prev message
    const showTime = !prevMessage || (sinceLast && sinceLast >= 13) || isPressed;

    return { isFirst, isLast, showTime };
  }, [item, prevMessage, nextMessage]);

  let name = isMe ? me.name : otherUser.name;
  let propic_url = isMe ? me.propic_100_url : otherUser.propic_100_url;
  let tiktokHandle = isMe ? me.tiktok_handle : otherUser.tiktok_handle;
  const time = timestampToTimeAndMaybeDate(sent_on);

  const goToProfile = () => null; // navigation.push("ProfileScreen", { id: user_id });

  const returnMessageBody = () => {
    if (!vid_url && !pic_800_url) {
      return (
        <View style={{
          backgroundColor: isMe
            ? (isPressed || isHeld ? '#0047b3' : '#3B51F5')
            : (isPressed || isHeld ? '#bababa' : '#F0F0F0'),
          paddingHorizontal: 12,
          paddingVertical: isPressed ? 20 : 8,
          borderTopLeftRadius: !isMe && !isFirst ? 4 : 16,
          borderTopRightRadius: isMe && !isFirst ? 4 : 16,
          borderBottomRightRadius: isMe && !isLast ? 4 : 16,
          borderBottomLeftRadius: !isMe && !isLast ? 4 : 16,
          maxWidth: '100%',
        }}>
          <Text style={{ ...maybeRounded('500'), fontSize: 14, lineHeight: 20, color: isMe ? '#fff' : '#0F1D40' }}>{text}</Text>
        </View>
      );
    } else if (vid_url) {
      return (
        <View style={{
          backgroundColor: isMe
            ? (isPressed || isHeld ? '#0047b3' : '#3B51F5')
            : (isPressed || isHeld ? '#bababa' : '#F0F0F0'),
          borderTopLeftRadius: !isMe && !isFirst ? 4 : 16,
          borderTopRightRadius: isMe && !isFirst ? 4 : 16,
          borderBottomRightRadius: isMe && !isLast ? 4 : 16,
          borderBottomLeftRadius: !isMe && !isLast ? 4 : 16,
          // maxWidth: '100%',
          // maxWidth: 200, // needed if text shown underneath. resulted in border being off -- can probably be fixed though
          width: 208,
          height: 208 * 16/9,
          borderWidth: 1,
          borderColor: '#000',
          overflow: 'hidden',
        }}>
          <View style={{ backgroundColor: '#000', width: '100%', height: '100%', aspectRatio: 9/16, justifyContent: 'center', alignItems: 'center' }}>
            <Image
              source={{ uri: vid_thumb_800_url }}
              resizeMode={'contain'}
              style={StyleSheet.absoluteFill}
            />

            {!!videoVisible &&
              <Video
                // ref={vidRef}
                source={{ uri: vid_url }}
                resizeMode={ResizeMode.CONTAIN}
                // style={[StyleSheet.absoluteFill, { width: '100%', height: '100%', alignSelf: 'center', justifySelf: 'center' }]}
                // note: version 13.0.0/13.0.1 added videoStyle and PosterComponent props we may want to use
                style={{ width: '100%', height: '100%', justifyContent: 'center' }}
                isLooping
                // shouldPlay={isFocused ? undefined : false}
                shouldPlay={shouldPlay}
                isMuted={!isFocused}
                // onFullscreenUpdate={({ fullscreenUpdate, status }) => vidRef.current.setStatusAsync({ shouldPlay: true })}
                useNativeControls={shouldPlay}
              />
            }

            {/* prompt */}
            {/*{!!prompt_text &&*/}
            {/*  <View style={{ pointerEvents: 'none', position: 'absolute', top: 40, left: 10, maxWidth: 120 }}>*/}
            {/*    {fontsLoaded && <Text style={{ color: '#fff', fontSize: 15, fontFamily: 'ComicNeue_700Bold_Italic', fontStyle: 'italic', ...shadow }}>“{prompt_text}”</Text>}*/}
            {/*  </View>*/}
            {/*}*/}

            {/* watermark */}
            {/*{!!prompt_text &&*/}
            {/*  <View style={{ pointerEvents: 'none', position: 'absolute', bottom: 10, right: 10, maxWidth: 100, justifyContent: 'center', alignItems: 'center' }}>*/}
            {/*    <Image source={noBgDuetIcon} resizeMode={'contain'} style={{ width: 21, height: 21 }}/>*/}
            {/*    <Text style={{ color: '#fff', fontWeight: '600', fontSize: 10, fontStyle: 'italic', ...shadow }}>@{tiktokHandle.trim().toLowerCase()}</Text>*/}
            {/*  </View>*/}
            {/*}*/}

            {(!videoVisible || !shouldPlay) &&
              <Fragment>
                <View style={[StyleSheet.absoluteFill, { backgroundColor: 'rgba(0,0,0,0.05)' }]}/>
                <View style={{ position: 'absolute', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.28)', width: 58, height: 58, borderRadius: 60 }}>
                  <Image source={playIcon} tintColor={'#fff'} resizeMode={'contain'} style={{ width: 30, height: 30 }}/>
                </View>
                <Touchable onPress={() => setVideoVisible(true)} style={StyleSheet.absoluteFill}/>
              </Fragment>
            }
          </View>
          {/*{!!text && <Text style={{ ...maybeRounded('400'), fontSize: 16, color: isMe ? '#fff' : '#020202', letterSpacing: 0.3, paddingHorizontal: 12, paddingVertical: isPressed ? 10 : 8 }}>{text}</Text>}*/}
          {/*{!!prompt_text && <Text style={{ ...maybeRounded('400'), fontSize: 16, color: isMe ? '#fff' : '#020202', letterSpacing: 0.3, paddingHorizontal: 12, paddingVertical: isPressed ? 10 : 8 }}>Response to video prompt: “{prompt_text}”</Text>}*/}
        </View>
      )
    } else if (pic_800_url) {

      console.log('Message - pic_800_url', pic_800_url);
      return (
        <View style={{
          // backgroundColor: isMe
          //   ? (isPressed || isHeld ? '#0047b3' : '#0065fd')
          //   : (isPressed || isHeld ? '#bababa' : '#efefef'),
          borderTopLeftRadius: !isMe && !isFirst ? 4 : 16,
          borderTopRightRadius: isMe && !isFirst ? 4 : 16,
          borderBottomRightRadius: isMe && !isLast ? 4 : 16,
          borderBottomLeftRadius: !isMe && !isLast ? 4 : 16,
          // maxWidth: '100%',
          // maxWidth: 200, // needed if text shown underneath. resulted in border being off -- can probably be fixed though
          width: 208,
          height: 208 / imageAspectRatio,
          // height: 300,
          borderWidth: 1,
          borderColor: '#000',
          overflow: 'hidden',
        }}>
          <Touchable onPress={() => window.open(pic_800_url, "_blank")} style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Image
              source={{ uri: pic_800_url || undefined }}
              style={{ backgroundColor: '#ccc', width: '100%', height: '100%' }}
              resizeMode={'cover'}
            />
          </Touchable>
        </View>
      );
    }
  };


  return (
    <View style={{ width: '100%', paddingHorizontal: 20 }}>

      {showTime &&
        <View style={{ width: '100%', alignItems: 'center', paddingTop: 20, paddingBottom: 8 }}>
          <Text style={{ ...maybeRounded('500'), fontSize: 12, lineHeight: 16, letterSpacing: -0.2, color: '#999999', textAlign: 'center' }}>{time}</Text>
        </View>
      }

      <View>
        <View style={{
          flexDirection: 'row', 
          gap: 10, 
          // paddingTop: (isFirst || isPressed) ? 8 : 1, 
          paddingBottom: (isLast || isPressed) ? 8 : 1, 
          alignItems: 'flex-end'
        }}>
          <View style={{ 
            // width: LEFT_GUTTER_WIDTH
          }}>
            {!isMe && isLast &&
              <Touchable disabled={isDeal} onPress={goToProfile} style={{ 
                // width: LEFT_GUTTER_WIDTH, 
                // paddingLeft: 12, 
                // paddingRight: 
                // LEFT_GUTTER_WIDTH-12-29
              }}>
                <Image
                  source={{ uri: propic_url || undefined }}
                  style={{ backgroundColor: '#e0e0e0', width: 26, height: 26, borderRadius: 12 }}
                  resizeMode={'cover'}
                />
              </Touchable>
            }
            {!isMe && !isLast &&
              <View style={{width: 26, height: 26}}></View>
            }
          </View>

          <View style={{ flex: 1}}>
            <View>
              <View style={{ flexDirection: 'row', alignSelf: isMe ? 'flex-end' : 'flex-start', maxWidth: '100%' }}>
                <View style={{ maxWidth: 400, width: '100%' }}>
                  {returnMessageBody()}
                </View>
              </View>
            </View>
          </View>

          {/* <View style={{ width: RIGHT_GUTTER_WIDTH }}>

          </View> */}
        </View>
      </View>
    </View>
  );

  // const timestamp = useMemo(() => friendlyTimestamp(sent_on, true), [sent_on]);
  // return (
  //   <View style={{ flexDirection: 'row', alignItems: 'center' }}>
  //     <Image
  //       source={{ uri: propic_url || undefined }}
  //       style={{ alignSelf: 'flex-start', backgroundColor: '#ccc', width: 36, height: 36, borderRadius: 10, marginRight: 16, marginLeft: 20 }}
  //       resizeMode={'cover'}
  //     />
  //     <View style={{ flex: 1 }}>
  //       <Text style={{ ...maybeRounded('500'), fontSize: 16 }}>{name}</Text>
  //       <Text style={{ ...maybeRounded('400'), fontSize: 16, color: '#333' }}>{text}</Text>
  //     </View>
  //     <View style={{ marginRight: 20, marginLeft: 8 }}>
  //       <Text style={{ ...maybeRounded('400'), fontSize: 16, color: '#666', opacity: 0 }}>{timestamp}</Text>
  //       <Text style={{ ...maybeRounded('400'), fontSize: 16, color: '#666' }}>{timestamp}</Text>
  //     </View>
  //   </View>
  // );
}
