import React, { StrictMode, Fragment, useEffect, useState } from 'react';
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from "react-redux";
import { ActivityIndicator, View } from 'react-native';
import Root from "./Root";
import { store, persistor } from './redux/store';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_51HCrcpEBOWtY9Bt1Coe47zhfgicdlMyB8CqQNXrPDwmc79kc5calf6smUXSFT1TiLJFieebcqMxa40iVSIRfGUcw00gAgCzesP");
const stripePromise = loadStripe("pk_live_51HCrcpEBOWtY9Bt1Mah406z8fEZu3EIq2fLK9YmoZXuD5na2iH7srnr87kMPO5kndPZZ4NbOVdLUwt0POXBmElKK00RekN2yK7");


// todo: Sentry

const splashJSX = (
  <View style={{ backgroundColor: '#5A017A', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <ActivityIndicator color={"#fff"}/>
  </View>
);

export default function App() {
  return (
    <StrictMode>
      <View style={{ flex: 1 }}>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <PersistGate loading={splashJSX} persistor={persistor}>
              <Root/>
            </PersistGate>
          </Provider>
        </Elements>
      </View>
    </StrictMode>
  );
}
