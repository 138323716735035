// effects aren't supposed to return anything except a function for cleanup
// sometimes you want to use one liners but they return something
// this returns nothing
import { useEffect } from "react";

export const useEffectWithoutReturn = (arg0, ...otherArgs) => {
  useEffect(() => {
    arg0();
  }, ...otherArgs);
};
