import { useState } from "react";

export default function useToggleOptions(def, options) {
  if (!options) options = [true, false];
  const [opt, setOpt] = useState(def);
  const onPress = () => {
    const currIdx = options.indexOf(opt);
    const newIdx = (currIdx + 1) % options.length;
    setOpt(options[newIdx]);
  };
  return [opt, onPress];
}
