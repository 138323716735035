import React, { Fragment, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Touchable from "../../components/Touchable"
import { maybeRounded } from "../../assets/assets";
import Navbar from "../../components/Navbar";
import { ActivityIndicator, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { updateDealChannelsList, updateDealsInfo, updateMe, updatePaymentMethod } from "../../redux/asyncActions";
import ConvoScreen from "../Messages/ConvoScreen";
import {
  apiAddPreApprovedEmail, apiAdminGetUserList, apiAdminLoginAsUser,
  apiGetCardSetupIntent, apiGetCurrentAndPendingMembers,
  apiRemovePreApprovedEmail, apiSendPendingMemberReminderEmail,
  apiUpdateEmailSettings
} from "../../api/api";
import { divViewStyle } from "../../util/constants";
import { loginAsUser, logout } from "../../redux/reducers";
import { VIEW_ONLY_ADMINS, FULL_ADMINS } from "../../util/adminIds";

const checkMark = require("../../assets/images/icons8-checkmark.png");


function NotAdmin() {
  return (
    <View>
      <Text>Not admin</Text>
    </View>
  );
}

function BrandUser({ user, idx, refresh }) {
  const me = useSelector(state => state.me);
  const dispatch = useDispatch();

  const { email, id, name, user_email_id, company_id, company_name, count } = user;

  const [loading, setLoading] = useState(false);

  const doLoginAsUser = async () => {
    setLoading(true);
    const res = await apiAdminLoginAsUser(user.id);
    if (res.status !== 'success') {
      setLoading(false);
      Alert.alert("Error logging in as user", res.message);
    } else {
      console.log("Admin - doLoginAsUser - success", res.data);
      dispatch(loginAsUser(res.data.token, res.data.userId));
      window.location = '/dashboard';
      // await updateMe();
      // await updateChannelsList();
      // await updateMatch();
      // await updateBonusMatches();
      setLoading(false);
    }
  };

  return (
    <View style={{ backgroundColor: id === me.id ? '#ffe6bd' : '#f9f9f9', borderRadius: 4, paddingHorizontal: 10, paddingVertical: 4, marginBottom: 2 }}>

      <View style={{ flexDirection: 'row' }}>
        {/*<Text style={{ flex: 1, color: id === me.id ? '#231a9a' : '#000' }}>{name ? `${name} (${email})` : email}{id === me.id ? ' (you!)' : ''}</Text>*/}
        <Text style={{ width: '7%', color: '#000' }}>{company_id}</Text>
        <Text style={{ width: '20%', color: '#000' }}>{company_name}</Text>
        <Text style={{ width: '6%', color: '#000' }}>{id}</Text>
        <Text style={{ width: '31%', color: '#000' }}>{email}</Text>
        <Text style={{ width: '20%', color: '#000' }}>{name}</Text>
        <Text style={{ width: '7%', color: '#000' }}>{count}</Text>
        {!!id && <Text onPress={doLoginAsUser} style={{ width: '6%', textDecorationLine: 'underline', color: '#32357a' }}>Login</Text>}
      </View>
    </View>
  );
}

function UserList() {
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState(null);
  const getUserList = async () => {
    setLoading(true);
    const res = await apiAdminGetUserList();
    setLoading(false);
    if (res.status === 'error') {
      return alert(`Error getting user list: ${res.message}`);
    }

    let list = [...res.data];
    list.sort((a, b) => {
      if (a.company_id && !b.company_id) return -1;
      if (b.company_id && !a.company_id) return 1;
      if (a.company_id && b.company_id) return a.company_id > b.company_id ? -1 : 1;

      if (a.id && !b.id) return -1;
      if (b.id && !a.id) return 1;
      if (a.id && b.id) return a.id > b.id ? -1 : 1;

      return a.email < b.email ? -1 : 1;
    });

    setUserList(list);
  };
  useEffect(() => {
    getUserList();
  }, []);

  return (
    <View style={{ width: 900, paddingTop: 20, paddingBottom: 20, paddingHorizontal: 20, backgroundColor: '#fff', borderRadius: 7 }}>
      <Text style={{ fontWeight: '500', fontSize: 20 }}>Brand Users</Text>
      <View style={{ height: 14 }}/>
      {loading && <ActivityIndicator/>}
      {!!userList &&
        <View style={{ backgroundColor: '#f9f9f9', borderRadius: 4, paddingHorizontal: 10, paddingVertical: 4, marginBottom: 2 }}>
          <View style={{ flexDirection: 'row' }}>
            {/*<Text style={{ flex: 1, color: id === me.id ? '#231a9a' : '#000' }}>{name ? `${name} (${email})` : email}{id === me.id ? ' (you!)' : ''}</Text>*/}
            <Text style={{ width: '7%', color: '#000' }}>Co</Text>
            <Text style={{ width: '20%', color: '#000' }}>Co Name</Text>
            <Text style={{ width: '6%', color: '#000' }}>uid</Text>
            <Text style={{ width: '31%', color: '#000' }}>email</Text>
            <Text style={{ width: '20%', color: '#000' }}>uname</Text>
            <Text style={{ width: '7%', color: '#000' }}>deal #</Text>
            <Text style={{ width: '6%', color: '#000' }}> </Text>
          </View>
        </View>
      }
      {userList && userList.map((u, idx) => <BrandUser index={idx} key={"" + u.user_email_id} user={u}/>)}
      <View style={{ height: 20 }}/>
    </View>
  );
}

function CampaignReviewLink() {
  return (
    <View style={{ width: 900, paddingTop: 10, paddingBottom: 10, paddingHorizontal: 20, backgroundColor: '#fff', borderRadius: 7, marginBottom: 20 }}>
      <View style={{ height: 14 }}/>
      <Text style={{ fontWeight: '500', fontSize: 20 }}>
        <a style={{ color: '#00a', textDecorationLine: 'none' }} href={"/campaign_approval"}>Go to Campaign Review</a>
      </Text>
      <View style={{ height: 14 }}/>
    </View>
  )
}

export default function AdminScreen() {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.userId);
  const adminUserId = useSelector(state => state.adminUserId);
  let isAdmin = FULL_ADMINS.includes(userId) || VIEW_ONLY_ADMINS.includes(userId);

  useEffect(() => {
    if (!isAdmin && adminUserId) {
      dispatch(logout());
    }
  }, []);

  return (
    <View style={{ backgroundColor: '#F8F9FA', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
      <Navbar logoutBar/>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ alignItems: 'center', justifyContent: 'center', paddingTop: 60, paddingBottom: 40, paddingHorizontal: 30 }}
      >
        {!isAdmin && <NotAdmin/>}
        {isAdmin && <CampaignReviewLink/>}
        {isAdmin && <UserList/>}
      </ScrollView>
    </View>
  );
}

