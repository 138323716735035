import React, { useEffect, useMemo, useState } from "react";
import Touchable from "../../components/Touchable";
import { ActivityIndicator, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { maybeRounded } from "../../assets/assets";
import { formatFollowers } from "../../util/formatFollowers";
import { friendlyTimestamp } from "../../util/dateUtil";
import { useSelector } from "react-redux";
import { calculatePrice } from "../../util/calculateSubmissionPrice";
import { apiPayCreatorForSubmission, apiSetInstallCounts } from "../../api/api";
import { updateDealsInfo } from "../../redux/asyncActions";
import Modal from "../../components/Modal";
import ChatIcon from "../../components/svg-icons/ChatIcon";
const checkMark = require("../../assets/images/icons8-checkmark.png");

const statusToStr = {
  applied: "Applied",
  rejected: "Rejected",
  accepted: "In progress",
  // accepted: "Accepted - in progress, may require attention",
  // accepted: "Accepted - in progress",
  // accepted: "Accepted - waiting for their video. Send over anything they need if applicable",
  reviewing_video_or_post: "Review video",
  waiting_for_post: "Waiting for post",
  submission_done_invoice_not_sent: "Waiting for payment",
  submission_done_invoice_sent: "Waiting for payment",
  paid: "Completed",
};

export default function CreatorSubmission({ submission, selectedTab, deal, setSelectedSubmission, updateUserDealStatus, accepted, rejected }) {

  const dealId = submission.deal_id;
  const userId = submission.user_id;
  const [payConfirmModalVisible, setPayConfirmModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { adjustedPrice } = calculatePrice(deal, submission);
  const creatorProposedPrice = submission.user_proposed_base_price ? (submission.user_proposed_base_price + submission.user_proposed_price_our_add_usd) : null;

  const me = useSelector(state => state.me);
  const dealChannel = useSelector(state => {
    return state.dealChannelsList && state.dealChannelsList.find(dc => dc.deal_id === dealId && dc.user_id === userId);
  }); // (a, b) => a.dealChannelsList !== b.dealChannelsList <-- broke updates

  const {
    last_message_sent_on,
    last_message_text,
    unread,
    last_message_pic_320_url,
    last_message_vid_thumb_320_url,
    last_message_from_company,
  } = dealChannel || {};
  const timestamp = useMemo(() => last_message_sent_on && friendlyTimestamp(last_message_sent_on, true), [last_message_sent_on]);

  const select = () => setSelectedSubmission(submission);
  const acceptancePressed = () => {
    // if link campaign and no payment method - say they have to add card first
    if (!me.company_has_added_card) {
      return alert("Add your card information in settings before approving users");
    }
    updateUserDealStatus(submission, accepted ? 'cleared' : 'accepted');
  };
  const rejectionPressed = () => updateUserDealStatus(submission, rejected ? 'cleared' : 'rejected');

  const [completeLoading, setCompleteLoading] = useState(false);
  const markDealAsComplete = async () => {
    if (completeLoading) return;

    if (!me.company_has_added_card) return alert(`Add a payment method in settings to complete.`);

    // alert("Send an email to support@swipehouse.co!");
    setCompleteLoading(true);
    const res = await apiPayCreatorForSubmission(dealId, userId);
    if (res.status !== 'success') {
      setCompleteLoading(false);
      return alert(`Error marking complete: ${res.message}`);
    }
    await updateDealsInfo();
    setCompleteLoading(false);
    setPayConfirmModalVisible(false);
  };
  const cancelDeal = () => {
    alert("Send an email to support@swipehouse.co!");
  };

  // update installs count
  const [installsLoading, setInstallsLoading] = useState(false);
  const [installsCountText, setInstallsCountText] = useState('' + (submission.installs_count || ''));
  const [updateInstallsCountModalVisible, setUpdateInstallsCountModalVisible] = useState(false);
  const showUpdateInstalls = () => setUpdateInstallsCountModalVisible(true);
  const hideUpdateInstalls = () => setUpdateInstallsCountModalVisible(false);
  const updateInstallsCount = async () => {
    setInstallsLoading(true);
    const res = await apiSetInstallCounts(dealId, submission.id, installsCountText);
    if (res.status !== 'success') {
      setInstallsLoading(false);
      return alert(`Error setting installs: ${res.message}`);
    }
    await updateDealsInfo();
    setInstallsLoading(false);
    hideUpdateInstalls();
  };
  useEffect(() => {
    setInstallsCountText('' + (submission.installs_count || ''));
  }, [submission.installs_count]);

  const stripeComingAlert = () => {
    // if (submission.has_setup_payouts) {
    //   alert("Payments set up");
    // } else {
    //   alert(`${submission.name} hasn't set up payments yet. `);
    // }
    // alert("Hey there 👋 We are currently integrating payments. You will be able to mark complete and pay within a couple days.")
  };

  let textPreview = last_message_text;
  if (last_message_vid_thumb_320_url) {
    let prefix = last_message_from_company ? 'You sent a video' : 'Sent you a video';
    if (last_message_text) {
      textPreview = `${prefix}: ${last_message_text}`;
    } else {
      textPreview = `${prefix}`;
    }
  }
  if (last_message_pic_320_url) {
    let prefix = last_message_from_company ? 'You sent a photo' : 'Sent you a photo';
    if (last_message_text) {
      textPreview = `${prefix}: ${last_message_text}`;
    } else {
      textPreview = `${prefix}`;
    }
  }

  return (
    <View style={{ width: '100%', backgroundColor: '#fff', padding: 20, borderRadius: 0, marginVertical: 0, borderColor: '#EBEBEB', borderTopWidth: 1 }}>
      <View style={{ width: '100%', flexDirection: 'row', gap: 12 }}>
        {/* left column */}
        <View style={{ width: 88 }}>
          <Image source={submission.propic_800_url} style={{ width: 88, height: 88, borderRadius: 12 }} />
        </View>

        {/* middle column */}
        <View style={{ flex: 1, gap: 2 }}>
          <View style={{ flexDirection: 'row', gap: 2, alignItems: 'baseline' }}>
            <Text style={{ ...maybeRounded('600'), fontSize: 16, color: '#0F1D40', lineHeight: 21 }}>{submission.name}</Text>
            {!!dealChannel && <ChatIcon style={{ alignSelf: 'center' }} width={16} height={16} fill={'#1dbf73'}/>
            // <View style={{
            //   backgroundColor: '#1dbf73', 
            //   borderRadius: 99, 
            //   paddingHorizontal: 6, 
            //   paddingVertical: 3,
            //   alignSelf: 'center',
            // }}>
            //   <Text style={{ color: '#fff', fontSize: 10, ...maybeRounded('400') }}>new message</Text>
            // </View>
            }
          </View>
          { !!submission.tiktok_handle && <Text style={{ ...maybeRounded('500'), fontSize: 16, color: '#3B51F5', lineHeight: 21 }}>
            <a style={{ color: '#3B51F5', textDecorationLine: 'none' }} target={"_blank"} href={`https://tiktok.com/@${submission.tiktok_handle.toLowerCase()}`}>@{submission.tiktok_handle}</a>
          </Text>}
          <View style={{flexDirection: 'row', gap: 32}}>
            <Text style={{ ...maybeRounded('500'), fontSize: 12, lineHeight: 18, color: '#0F1D40' }}>Followers {formatFollowers(submission.follower_count)}</Text>
            {deal.campaign_type !== 'install' && <Text style={{ ...maybeRounded('500'), fontSize: 12, lineHeight: 18, color: '#0F1D40'  }}>Price: ${adjustedPrice.toFixed(0)}{submission.requested_price_usd && '**'}</Text>}
          </View>
          {!!creatorProposedPrice && <Text style={{ ...maybeRounded('400'), fontSize: 14, color: '#16191C', paddingBottom: 1, fontWeight: '600' }}>Price update pending</Text>}
          <Text style={{ ...maybeRounded('500'), fontSize: 12, lineHeight: 18, color: '#525C76' }}>Applied {(new Date(submission.created_on)).toLocaleDateString()}</Text>
          {/* {submission.status !== 'applied' && deal.campaign_type !== 'install' && <Text style={{ ...maybeRounded('600'), fontSize: 14, color: '#16191C', paddingBottom: 1 }}>{statusToStr[submission.status]}</Text>} */}
          {selectedTab === 'in_progress' && deal.campaign_type === 'install' &&
            <Text style={{ ...maybeRounded('600'), marginTop: 2, fontSize: 15, color: '#16191C', paddingBottom: 1 }}>
              {submission.installs_count || 0} installs
              {/*{' • '}*/}
              {/*<Text onPress={showUpdateInstalls} style={{ fontWeight: '600', color: '#062bff', textDecorationLine: 'underline' }}>Update</Text>*/}
            </Text>
          }

          {selectedTab === 'in_progress' && deal.campaign_type !== 'install' &&
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 6 }}>
              <Touchable
                disabled={completeLoading}
                onPress={() => setPayConfirmModalVisible(true)}
                // onPress={stripeComingAlert}
                style={styles.buttonStyle}
              >
                {completeLoading
                  ? <ActivityIndicator color={'#fff'}/>
                  : <Text style={{ ...maybeRounded('600'), color: '#fff', fontSize: 12 }}>Pay Creator</Text>
                }
              </Touchable>
              {/*<Touchable onPress={cancelDeal} style={{ alignItems: 'center', justifyContent: 'center', padding: 4, borderRadius: 5 }}>*/}
              {/*  <Text style={{ ...maybeRounded('500'), color: '#333' }}>Cancel Deal</Text>*/}
              {/*</Touchable>*/}
            </View>
          }
          <Modal visible={payConfirmModalVisible}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, borderRightWidth: 1, borderRightColor: '#ebebeb' }}>
                {/*<View style={{ flexDirection: 'row', paddingLeft: 25, paddingRight: 25, paddingTop: 10, alignItems: 'center' }}>*/}
                {/*  <Text style={[styles.headerOne, { marginBottom: 10 }]}>New Campaign 🚀</Text>*/}
                {/*  <View style={{ flex: 1 }}/>*/}
                {/*  <Touchable onPress={close}>*/}
                {/*    <Text style={[styles.descriptionText, { marginBottom: 10 }]}>Close</Text>*/}
                {/*  </Touchable>*/}
                {/*</View>*/}
                <ScrollView style={{ backgroundColor: '#fcfdff', flex: 1, width: 325, borderRadius: 5, paddingHorizontal: 25, paddingVertical: 10, borderTopWidth: 1, borderBottomWidth: 1, borderColor: '#EBEBEB', maxHeight: 900 }}>
                  <View style={{ height: 24 }} />
                  <Touchable onPress={() => setPayConfirmModalVisible(!payConfirmModalVisible)} style={{ position: 'absolute', top: 10, right: 10 }}>
                    <Text style={{  fontSize: 14, marginBottom: 13, color: '#656B73', ...maybeRounded('400'), paddingLeft: 2 }}>Cancel</Text>
                  </Touchable>
                  <Text style={{...maybeRounded('500'), fontSize: 22, marginTop: 5, marginBottom: 15, color: '#16191C' }}>Confirm completion</Text>
                  <Text style={{  fontSize: 14, ...maybeRounded('500') }}>Before paying this creator:</Text>
                  <Text style={{  fontSize: 14, marginTop: 8, ...maybeRounded('400') }}>Make sure the deal is complete</Text>
                  <Text style={{  fontSize: 14, marginTop: 8, ...maybeRounded('400') }}>This will complete your job and pay the creator</Text>
                  <Text style={{  fontSize: 14, marginTop: 8, ...maybeRounded('400') }}>Payment (80% goes to creator): <Text style={{ ...maybeRounded('600') }}>${adjustedPrice.toFixed(0)}</Text>{!!creatorProposedPrice ? ' (*there is also a pending price update. Make sure this is the correct price!)' : ''}</Text>
                  <View style={{ alignItems: 'center', width: '100%', marginTop: 20, paddingBottom: 15 }}>
                    <Touchable onPress={markDealAsComplete} style={styles.buttonStyle}>
                      {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ ...maybeRounded('600'), color: '#fff', fontSize: 12 }}>Pay Creator</Text>}
                    </Touchable>
                  </View>
                </ScrollView>
              </View>
            </View>
          </Modal>
        </View>

        {/* right column */}
        <View style={{ alignItems: 'flex-start', gap: 8 }}>
          <Touchable onPress={select} style={{...styles.detailsButtonStyle, width: 110 }}>
            <Text style={{ ...maybeRounded('600'), color: '#3B51F5', fontSize: 12 }}>Details</Text>
          </Touchable>

          { // SR - commented the update install count button
            // selectedTab === 'in_progress' && deal.campaign_type === 'install' &&
            //   <Touchable 
            //     onPress={showUpdateInstalls} 
            //     style={{ ...styles.buttonStyle, width: 110, backgroundColor: '#2d41ff' }}
            //   >
            //     <Text style={{ ...maybeRounded('600'), color: '#fff', fontSize: 12 }}>Update #</Text>
            //   </Touchable>
          }
          
          {selectedTab === 'received' &&
            <View style={{gap: 6}}>
              <Touchable onPress={acceptancePressed} style={styles.checkboxRow}>
                <View style={[styles.checkbox, accepted && { backgroundColor: '#0F1D40' }]}>
                  {accepted && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} /> }
                </View>
                <Text style={styles.checkboxText}>Accept</Text>
              </Touchable>
              <Touchable onPress={rejectionPressed} style={styles.checkboxRow}>
                <View style={[styles.checkbox, rejected && { backgroundColor: '#0F1D40' }]}>
                  {rejected && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} /> }
                </View>
                <Text style={styles.checkboxText}>Reject</Text>
              </Touchable>
            </View>
          }
          {!!submission.video_thumb_800_url &&
            <Touchable onPress={select} style={{ width: '100%', textAlign: 'center', alignItems: "center", justifyContent: "center" }}>
              <Image source={{ uri: submission.video_thumb_800_url }} resizeMode={'cover'} style={{ width: 80, height: 80*16/9, borderRadius: 10 }}/>
            </Touchable>
          }
          {
            submission.status !== 'applied' && deal.campaign_type !== 'install' && 
            <View style={{ backgroundColor: '#C0EFCE', borderRadius: 99, width: 110, paddingHorizontal: 12, paddingVertical: 2 }}>
              <Text style={{ ...maybeRounded('500'), fontSize: 12, lineHeight: 24, color: '#0F1D40', textAlign: 'center' }}>{statusToStr[submission.status]}</Text>
            </View>
          }
          
        </View>
      </View>

      {/* {!!dealChannel &&
        <Touchable onPress={select} style={{ borderTopColor: '#e0e0e0', borderTopWidth: 1, marginTop: 10, paddingTop: 10 }}>
          <Text>Most recent message:</Text>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingRight: 20 }}>
              <Text rows={1} style={{ flex: 1, ...maybeRounded(unread ? '500' : '400'), fontSize: 16, color: unread ? '#000' : '#666' }}>{textPreview}</Text>
              <View style={{ width: 8 }}/>
              <Text rows={1} style={{ ...maybeRounded(unread ? '500' : '400'), fontSize: 16, color: unread ? '#000' : '#666', opacity: 1, textAlign: 'right' }}>{timestamp}</Text>
            </View>
          </View>
        </Touchable>
      } */}

      <Modal
        visible={updateInstallsCountModalVisible}
        close={hideUpdateInstalls}
        style={{ 
          width: '100%', 
          height: '100%', 
          borderWidth: 0, 
          borderColor: 'none', 
          backgroundColor: 'rgba(0,0,0,.3)', 
          alignItems: 'center', 
          justifyContent: 'center'
        }}
      >
        <View
          style={{
            width: 300,
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 20,
            paddingHorizontal: 20,
            flexDirection: 'column',
            gap: 10
          }}
        >
          <Text style={{ fontWeight: '600', fontSize: 14, color: '#16191C' }}>
            {submission.name} | Installs Count
          </Text>
          <Text style={{ fontWeight: '400', fontSize: 14, color: '#16191C', textAlign: 'center' }}>
            Be sure this number is correct. You won't be able to set it lower once you submit.
          </Text>
          <TextInput
            style={styles.input}
            value={installsCountText}
            onChangeText={setInstallsCountText}
            placeholder={"5000"}
            placeholderTextColor={'#777E93'}
          />
          <Touchable 
            disabled={!installsCountText.trim() || installsLoading} 
            onPress={updateInstallsCount} 
            style={{
              ...styles.buttonStyle, 
              width: '100%', 
              backgroundColor: !installsCountText.trim() ? '#aaa' : '#2d41ff', 
              alignItems: 'center', 
              justifyContent: 'center', 
            }}
          >
            {installsLoading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ ...maybeRounded('700'), color: '#fff'}}>Update</Text>}
          </Touchable>
        </View>
      </Modal>

    </View>
  );
}

const styles = StyleSheet.create({
  checkbox: {
    height: 14,
    width: 14,
    backgroundColor: '#EEEEEE',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxRow: {
    flexDirection: 'row',
    paddingHorizontal: 4,
    gap: 6,
    alignContent: "center",
    alignItems: "center"
  },
  checkboxText: {
    fontSize: 12,
    lineHeight: 18,
    color: '#0F1D40',
    ...maybeRounded('600')
  },
  checkBoxIcon: {
    height: 10,
    width: 10
  },
  input: {
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#E9EAED',
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 20,
    paddingVertical: 16,
    ...maybeRounded('500'),
    fontSize: 14,
    lineHeight: 20,
    color: '#000000',
  },
  detailsButtonStyle: {
    backgroundColor: "#ffffff",
    borderColor: "#3B51F5",
    borderWidth:1,
    borderRadius: 62,
    height: 36,
    width: 81,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingHorizontal: "20px",
    paddingVertical: "10px"
  },
  buttonStyle: {
    backgroundColor: "#1dbf73",
    borderRadius: 62,
    height: 36,
    width: 110,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
    boxShadow: '0px 1px 1px 0px #0000001A'
  }
});
