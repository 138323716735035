import { get, post } from "./util";
import { ApiResponse, CreateCompanyInfo } from "./types/apiTypes";

// login / signup
export const apiPhoneSubmit = (number: string, countryCode: string): ApiResponse => post('/api/v0/phone', { number, countryCode });
export const apiConfirmCode = (code: string, number: string): ApiResponse => post('/api/v0/phonecode', { code, number });
export const apiEmailSubmit = (email: string): ApiResponse => post('/api/v0/email', { email });
export const apiConfirmEmailCode = (code: string, email: string): ApiResponse<{ type: 'login' | 'signup' | 'add', isSignup: boolean, token?: string, userId?: number }> => post('/api/v0/emailcode', { code, email });

// login / signup additional info

export const apiCreateBusinessAccount = (companyInfo: CreateCompanyInfo, token?: string): ApiResponse => post('/api/v0/me/create_business_user', { companyInfo, token });
export const apiGetLogoUploadUrls = (): ApiResponse => post(`/api/v0/company/get_logo_upload_urls`);
export const apiUpdateEmailSettings = (notifOnUserApplied: boolean, notifOnUserDealUpdate: boolean, notifOnUserDealMessage: boolean): ApiResponse => post(`/api/v0/company/update_email_settings`, { notifOnUserApplied, notifOnUserDealUpdate, notifOnUserDealMessage });

// view/edit organization members
export const apiAddPreApprovedEmail = (email: string, name: string): ApiResponse => post(`/api/v0/company/add_pre_approved_email`, { email, name });
export const apiRemovePreApprovedEmail = (preApprovedEmailId: number): ApiResponse => post(`/api/v0/company/remove_pre_approved_email`, { preApprovedEmailId });
export const apiGetCurrentAndPendingMembers = (): ApiResponse => get(`/api/v0/company/current_and_pending_members`);
export const apiSendPendingMemberReminderEmail = (preApprovedEmailId: number): ApiResponse => post(`/api/v0/company/send_pending_member_reminder_email`, { preApprovedEmailId });

// stripe + payments
export const apiGetCardSetupIntent = (): ApiResponse => post('/api/v0/company/card_setup_intent');
export const apiPayCreatorForSubmission = (dealId: number, userId: number): ApiResponse => post('/api/v0/deal_stripe/pay_for_deal_submission', { dealId, userId });
export const apiPayCreatorAgainForSubmission = (dealId: number, userId: number, price): ApiResponse => post('/api/v0/deal_stripe/pay_again_for_deal_submission', {dealId, userId, price});
export const apiGetPaymentMethod = (): ApiResponse<{ card: object | null }> => get('/api/v0/deal_stripe/company_payment_method');

// my info
export const apiGetMe = async (): ApiResponse<any, { type: 'not_found' }> => await get('/api/v0/business/me');

// get deals and campaigns
export const apiGetDealsInfo = (): ApiResponse => get(`/api/v0/deals_for_dashboard`);

// update deals info
export const apiUpdateDealDetails = (dealId: number, postSpecs: string | null, videoSpecs: string | null, brandDescription: string | null, installReqs: string | null): ApiResponse => post(`/api/v0/update_deal_info`, { dealId, postSpecs, videoSpecs, brandDescription, installReqs });

// approve/reject users
export const apiUpdateDealSubmission = (submissions: { type: 'accepted' | 'rejected', id: number }[], dealId: number): ApiResponse => post(`/api/v0/approve_reject_deal_submissions`, { submissions, dealId });

// set install counts
export const apiSetInstallCounts = (dealId: number, dealSubmissionId: number, installCount: number): ApiResponse => post(`/api/v0/deals/set_install_amount`, { dealId, dealSubmissionId, installCount });

// price updates
export const apiProposePrice = (dealId: number, dealSubmissionId: number, totalPrice: number): ApiResponse => post(`/api/v0/deals/company_propose_new_price`, { dealId, dealSubmissionId, totalPrice });
export const apiAcceptPriceProposal = (dealId: number, dealSubmissionId: number): ApiResponse => post(`/api/v0/deals/company_accept_new_price`, { dealId, dealSubmissionId });

// end deal
export const apiCloseDeal = (dealId: number): ApiResponse => post(`/api/v0/deals/close_deal_applications`, { dealId });

// for device info / tracking / [notifs?]
export const apiView = (browserInfo: object, urlInfo: object, referrer: string) => {
  return post(`/api/v0/view`, { browserInfo, urlInfo, referrer });
};

// deal messages
export const apiGetDealChannels = (): ApiResponse => get('/api/v0/company_deal_channels');
export const apiGetDealChannelAndMessages = (id: number): ApiResponse => post('/api/v0/company_deal_channel_and_messages', { id });
export const apiMarkDealChannelRead = (channelId: number): ApiResponse => post('/api/v0/deal_channel/company_read', { channelId });
export const apiSendDealMessage = (channelId: number, text: string): ApiResponse => post('/api/v0/deal_messages/company_send_message', { channelId, text });
export const apiCreateDealConvo = (dealId: number, userId: number, text: string): ApiResponse => post('/api/v0/deal_messages/company_create_channel', { dealId, userId, text });

export const apiCreateNewDeal = ({
  campaignType,
  customCampaignName,
  customCampaignImage,
  customCampaignShortDescription,
  customCampaignLink,
  campaignBudget,
  creatorNotes,
  brandDescription,
  videoSpecs,
  postSpecs,
  linkInBio,
  linkValue,
  reviewVidFirst,
  mailingProduct,
  merchDetails,
  noMerchDetails,
  merchMSRP,
  basePrice,
  pricePerInstall,
  appLink,
  creatorNeg,
  installReqs,
  companyLogo,
  companyShortDescription,
  companyLink,
}): ApiResponse => post(`/api/v0/deals/create_new_deal`, {
  campaignType,
  customCampaignName,
  customCampaignImage,
  customCampaignShortDescription,
  customCampaignLink,
  campaignBudget,
  creatorNotes,
  brandDescription,
  videoSpecs,
  postSpecs,
  linkInBio,
  linkValue,
  reviewVidFirst,
  mailingProduct,
  merchDetails,
  noMerchDetails,
  merchMSRP,
  basePrice,
  pricePerInstall,
  appLink,
  creatorNeg,
  installReqs,
  companyLogo,
  companyShortDescription,
  companyLink,
});

// admin - web only
export const apiAdminGetUserList = (): ApiResponse => get('/api/v0/admin/deals_users_list');


// admin
// export const apiGetUserReview = (): ApiResponse => get('/api/v0/admin/user_review');
// export const apiGetAdminStats = (): ApiResponse => get('/api/v0/admin/stats');
// export const apiGetUsersList = (): ApiResponse => get('/api/v0/admin/users_list');
// export const apiUsersListLite = (sort, forUserId): ApiResponse => post('/api/v0/lite_users_list', { sort, forUserId });
// export const apiGetApprovalsUsersList = (): ApiResponse => get('/api/v0/admin/approvals_users_list');
// export const apiGetApprovalsUsersList2 = (listType): ApiResponse => post('/api/v0/admin/approvals_users_list_2', { listType });
// export const apiGetUserListRogue = (algoFunc, listType, query): ApiResponse => post('/api/v0/admin/user_list_rouge', { algoFunc, listType, query });
// export const apiGetApprovalsUserInfo = (userId, getExtraInfo): ApiResponse => post('/api/v0/admin/approvals_user_info', { userId, getExtraInfo });
// export const apiGetScoredMatches = (userId): ApiResponse => post('/api/v0/get_scored_matches', { userId });
// export const apiGetScoredMatchesRogue = (userId, algoFunc, extraArgs): ApiResponse => post('/api/v0/get_scored_matches_rouge', { userId, algoFunc, extraArgs });
// export const apiGetAdminSwipeStack = (userId, randomize): ApiResponse => post('/api/v0/admin/swipe_stack', { userId, randomize });
// export const apiGetMultiswipeTestStackRogue = (userId, algoFunc, extraArgs): ApiResponse => post('/api/v0/admin/test_algo_rogue', { userId, algoFunc, extraArgs });

// admin - get user match info, update user info
// export const apiGetUserMatchInfo = (userId): ApiResponse => post('/api/v0/admin/user_match_info', { userId });
// export const apiAdminSetNextStackTop = (userId, otherUserId): ApiResponse => post('/api/v0/admin/next_stack_top', { userId, otherUserId });
// export const apiAdminBatchSetNextStackTops = (nextTops): ApiResponse => post('/api/v0/admin/batch_set_next_stack_tops', { nextTops });
// export const apiAdminBatchAddBonuses = (bonuses): ApiResponse => post('/api/v0/admin/batch_add_bonuses', { bonuses });
// export const apiAdminPushOffWaitlistUsers = (title, body, minAge, gender, onlyDealsUsers, minFollowerCount, throttle): ApiResponse => post('/api/v0/admin/push_off_waitlist_users', { title, body, minAge, gender, onlyDealsUsers, minFollowerCount, throttle });
// export const apiAdminApproveUser = (userId): ApiResponse => post('/api/v0/admin/approve', { userId });
// export const apiAdminWaitlistUser = (userId): ApiResponse => post('/api/v0/admin/waitlist', { userId });
// export const apiAdminVerifyUser = (userId): ApiResponse => post('/api/v0/admin/verify', { userId });
// export const apiAdminUnverifyUser = (userId): ApiResponse => post('/api/v0/admin/unverify', { userId });
// export const apiAdminSetHotness = (userId, hotness): ApiResponse => post('/api/v0/admin/hotness', { userId, hotness });
// export const apiAdminSetFollowerCount = (userId, follower_count): ApiResponse => post('/api/v0/admin/follower_count', { userId, follower_count });
// export const apiAdminSetVideo = (userId, videoUrl, videoInfo, videoThumbs): ApiResponse => post('/api/v0/admin/set_video', { userId, videoUrl, videoInfo, videoThumbs });
// export const apiAdminGetProvidUploadUrls = (userId): ApiResponse => post('/api/v0/admin/get_provid_upload_urls', { userId });
// export const apiAdminSetBirthday = (userId, birthday): ApiResponse => post('/api/v0/admin/set_birthday', { userId, birthday });
// export const apiAdminSetTiktok = (userId, handle): ApiResponse => post('/api/v0/admin/set_tiktok_handle', { userId, handle });
// export const apiAdminSetGenderIdentity = (userId, gender_identity): ApiResponse => post('/api/v0/admin/set_gender_identity', { userId, gender_identity });
// export const apiAdminSetGenderPrefs = (userId, genderIdentity, value): ApiResponse => post('/api/v0/admin/set_gender_pref', { userId, genderIdentity, value });
// export const apiAdminSetReviewStatus = (userId, reviewStatus): ApiResponse => post('/api/v0/admin/review_status', { userId, reviewStatus });

// admin - create/delete/login as users, backup db
// export const apiAdminCreateTestUser = (): ApiResponse => post('/api/v0/admin/create_test_user');
// export const apiAdminUpdateFollowerCounts = (): ApiResponse => get('/api/v0/admin/update_follower_counts');
// export const apiAdminBatchApprove = (): ApiResponse => get('/api/v0/admin/batch_approve');
// export const apiAdminGetBatchApproveCount = (): ApiResponse => get('/api/v0/admin/get_batch_approve_count');
// export const apiAdminUpdateInstallCampaignNumbers = (): ApiResponse => get(`/api/v0/deals/auto_set_swipehouse_install_counts?actuallyRun=t`);
// export const apiAdminGetLastBackupDb = (): ApiResponse => get('/api/v0/admin/last_db_backup');
// export const apiAdminBackupDb = (): ApiResponse => post('/api/v0/admin/backup_db');
export const apiAdminLoginAsUser = (userId: number): ApiResponse => post('/api/v0/admin/login_as_user', { userId });
// export const apiAdminDeleteUser = (userId, notify): ApiResponse => post('/api/v0/admin/delete_user', { userId, notify });
// export const apiAdminYeetUserSwipesAndMessages = (userId): ApiResponse => post('/api/v0/admin/yeet_user_swipes_messages', { userId });
// export const apiAdminIgnoreUser = (userId, ignore): ApiResponse => post('/api/v0/admin/set_ignore', { userId, ignore });
// export const apiAdminAddBonusMatch = (userId, matchUserId, referredUserId, isForReferral): ApiResponse => post('/api/v0/admin/add_bonus_match', { userId, matchUserId, referredUserId, isForReferral });
// export const apiAdminSendPush = (userId, title, body): ApiResponse => post('/api/v0/admin/send_push', { userId, title, body });
// export const apiAdminAddTag = (userId, text, userAdded): ApiResponse => post('/api/v0/admin/create_tag', { userId, text, userAdded });
// export const apiAdminDeleteTag = (userId, id): ApiResponse => post('/api/v0/admin/delete_tag', { userId, id });
// export const apiAdminFixTags = (userId): ApiResponse => post('/api/v0/admin/fix_tags', { userId });

// // export const apiAdminBonusMatchDashboard = (): ApiResponse => get('/api/v0/admin/bonus_match_dashboard');
// // export const apiAdminGetPossibleBonuses = (): ApiResponse => get('/api/v0/get_possible_bonuses');
// export const apiAdminGetAllMatchInfo = (): ApiResponse => get('/api/v0/get_all_match_info');

// campaigns - admin
export const apiGetCampaigns = (): ApiResponse => get('/api/v0/admin/deals/get_campaigns');
export const apiApproveCampaign = (id: number, notify: boolean): ApiResponse => post('/api/v0/admin/deals/approve_campaign', { id, notify });
export const apiSetCampaignStatus = (id: number, status: 'open' | 'applicants_only' | 'closed', notify: boolean): ApiResponse => post('/api/v0/admin/deals/set_deal_status', { id, status, notify });
