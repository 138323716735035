import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ActivityIndicator, Alert, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Touchable from "../../components/Touchable"
import { maybeRounded } from "../../assets/assets";
import Navbar from "../../components/Navbar";
import { apiCreateBusinessAccount } from "../../api/api";
import { updateMe } from "../../redux/asyncActions";

const checkMark = require("../../assets/images/icons8-checkmark.png");

// 2023-12-14
// - screen shown on signup after email verification
// - part of the current signup flow
// - first committed 2020-12-14, last real update 2020-12-18
// - our current sign up flow is
//   - SignIn.js - email-based auth - handle getting email / email code
//   - SetCompanyInfo.js - set company info (if not already set / new user)

export default function SetCompanyInfo() {

  const [name, setName] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [referralCode, setReferralCode] = useState('')

  // UGC, Post, Link
  const [anticipatedCampaign, setAnticipatedCampaign] = useState('');
  const [monthlySpend, setMonthlySpend] = useState('');
  const [role, setRole] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    // required fields: name, company name, email
    if (!name.trim()) return alert(`Error: Add your name`);
    if (!name.trim().includes(' ') || name.trim().length <= 3) return alert(`Error: Enter your full name`);
    if (!companyName.trim()) return alert(`Error: Add your company name`);
    // if (!contactEmail.trim()) return alert(`Error: Add your email`);
    // if (!contactNumber.trim()) return alert(`Error: Add your email`);

    setLoading(true);
    const res = await apiCreateBusinessAccount({ name, companyName, websiteLink, anticipatedCampaign, monthlySpend, role, contactEmail, contactNumber, referralCode });
    if (res.status !== 'success') {
      setLoading(false);
      return Alert.alert("Error", res.message);
    }
    await updateMe();
    setLoading(false);
  };

  // #5A017A
  // #1a2f7a

  return (
    <View style={{ backgroundColor: '#236EFF', flex: 1 }}>
      <Navbar dark/>
      <View style={{ flexDirection:'row', paddingHorizontal: '20%', paddingTop: 60, justifyContent: 'center' }}>

        {/*<ScrollView style={{ backgroundColor: '#FAFBFC', flex: 1,  borderRadius: 5, paddingHorizontal: 25, paddingVertical: 10, borderWidth: 1, borderColor: '#EBEBEB', maxHeight: 900, maxWidth: 400,}}>*/}
        <View style={{ backgroundColor: '#FAFBFC', borderRadius: 5, paddingHorizontal: 25, paddingVertical: 10, borderWidth: 1, borderColor: '#EBEBEB', maxWidth: 400, marginBottom: 50 }}>

          <View style={{ height: 50 }} />
          <Text style={styles.headerOne}>Finish Signup 🚀</Text>

          <Text style={styles.headerTwo}>Your name</Text>
          <TextInput
            style={styles.input}
            onChangeText={setName}
            value={name}
            placeholder={'Kim Sampson'}
            placeholderTextColor={'#888'}
          />

          <Text style={styles.headerTwo}>Company name</Text>
          <TextInput
            style={styles.input}
            onChangeText={setCompanyName}
            value={companyName}
            placeholder={'Ridge Wallets'}
            placeholderTextColor={'#888'}
          />

          <Text style={styles.headerTwo}>Your role</Text>
          <TextInput
            style={styles.input}
            onChangeText={setRole}
            value={role}
            placeholder={'Growth Specialist'}
            placeholderTextColor={'#888'}
          />

          {/*<Text style={styles.headerTwo}>Your email</Text>*/}
          {/*<TextInput*/}
          {/*  style={styles.input}*/}
          {/*  onChangeText={setContactEmail}*/}
          {/*  value={contactEmail}*/}
          {/*  placeholder={'kim@yourbrand.com'}*/}
          {/*/>*/}

          <Text style={styles.headerTwo}>Your number</Text>
          <TextInput
            style={styles.input}
            onChangeText={setContactNumber}
            value={contactNumber}
            placeholder={'+1 847 555 4433'}
            placeholderTextColor={'#888'}
          />

          <Text style={styles.headerTwo}>Website or app link</Text>
          <TextInput
            style={styles.input}
            onChangeText={setWebsiteLink}
            value={websiteLink}
            // placeholder={'https://apps.apple.com/us/app/swipehouse/id1516566208'}
            placeholder={'https://yourbrand.com'}
            placeholderTextColor={'#888'}
          />

          <Text style={styles.headerTwo}>What type of campaign seems most interesting?</Text>
          <View style={styles.checkboxRow}>
            <Touchable style={[styles.checkbox, anticipatedCampaign === 'UGC' && { backgroundColor: '#3A3E41' }]} onPress={() => setAnticipatedCampaign('UGC')}>
              {anticipatedCampaign === 'UGC' && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} />}
            </Touchable>
            <Text style={styles.checkboxText}>Video-only (UGC)</Text>
          </View>
          <Text style={styles.descriptionText}>TikTokers will film content for you to use in your own ads</Text>
          <View style={styles.checkboxRow}>
            <Touchable style={[styles.checkbox, anticipatedCampaign === 'Post' && { backgroundColor: '#3A3E41' }]} onPress={() => setAnticipatedCampaign('Post')}>
              {anticipatedCampaign === 'Post' && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} />}
            </Touchable>
            <Text style={styles.checkboxText}>Video & Post</Text>
          </View>
          <Text style={styles.descriptionText}>TikTokers will film content for you and also post it to their profiles</Text>
          <View style={styles.checkboxRow}>
            <Touchable style={[styles.checkbox, anticipatedCampaign === 'Link' && { backgroundColor: '#3A3E41' }]} onPress={() => setAnticipatedCampaign('Link')}>
              {anticipatedCampaign === 'Link' && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} />}
            </Touchable>
            <Text style={styles.checkboxText}>Pay per Install</Text>
            {/*<Text style={styles.checkboxText}>Pay per click/install</Text>*/}
          </View>
          <Text style={styles.descriptionText}>TikTokers will promote a link that leads to your product or service. If you're an app, this can be a good way to go.</Text>

          <Text style={styles.headerTwo}>What is your potential monthly budget?</Text>
          <TextInput
            style={styles.input}
            onChangeText={setMonthlySpend}
            value={monthlySpend}
            placeholder={'$10,000'}
            placeholderTextColor={'#888'}
          />
          <Text style={styles.headerTwo}>Do you have a referral code?</Text>
          <TextInput
            style={styles.input}
            onChangeText={setReferralCode}
            value={referralCode}
            placeholder={'SXSW-Deal'}
            placeholderTextColor={'#888'}
          />

          <View style={{ width: '100%', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
            <Touchable disabled={loading} onPress={submit} style={{ backgroundColor: '#3E8BFF', borderRadius: 40, height: 43, width: 220, alignItems: 'center', justifyContent: 'center'}}>
              {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ color: '#fff', ...maybeRounded('700'), fontSize: 16  }}>Create Account</Text>}
            </Touchable>
          </View>

        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    height: 50,
    borderRadius: 4,
    backgroundColor: '#EEEEEE',
    marginBottom: 10,
    padding: 10,
    ...maybeRounded('500'),
    fontSize: 16,
    color: '#000000',
    maxWidth: '97%',
  },
  checkbox: {
    height: 28,
    width: 28,
    backgroundColor: '#EEEEEE',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxRow: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
  },
  checkboxText: {
    fontSize: 14,
    marginLeft: 5,
    paddingTop: 5,
    color: '#16191C',
    ...maybeRounded('600')
  },
  descriptionText: {
    fontSize: 14,
    marginBottom: 20,
    color: '#656B73',
    ...maybeRounded('400'),
  },
  headerOne: {
    ...maybeRounded('700'),
    fontSize: 25,
    marginBottom: 20,
    color: '#16191C',
  },
  headerTwo: {
    ...maybeRounded('600'),
    fontSize: 14,
    marginBottom: 8,
    color: '#16191C',
    marginTop: 8,
  },
  checkBoxIcon:{
    height: 18,
    width: 18,
  },
});
