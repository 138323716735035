import React, { useEffect, Fragment, useRef, useState, useMemo } from 'react';
import {
  ActivityIndicator,
  Alert,
  FlatList,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View
} from 'react-native';
import Touchable from '../../components/Touchable';
import {
  apiCreateDealConvo,
  apiMarkDealChannelRead, apiPayCreatorAgainForSubmission,
  apiSendDealMessage,
} from "../../api/api";
import { maybeRounded } from "../../assets/assets";
import { useDispatch, useSelector } from "react-redux";
import Message from "./Message";
import { updateDealChannelAndMessages, updateDealChannelsList, updateDealsInfo } from "../../redux/asyncActions";
import { addHomeSocketOpenListener, removeHomeSocketOpenListener } from "../../api/homeSocket";
import { localMarkDealChannelRead } from "../../redux/reducers";
import TextareaAutosize from "react-autosize-textarea";
import { inputTextInputStyle } from "../../util/constants";
import { formatFollowers } from "../../util/formatFollowers";
import DealStatusSummary from "./DealStatusSummary";
import { store } from "../../redux/store";
import Modal from "../../components/Modal";

export default function ConvoScreen({ name, dealId, userId }) {
  const dispatch = useDispatch();
  const dealChannel = useSelector(state => {
    return state.dealChannelsList && state.dealChannelsList.find(dc => dc.deal_id === dealId && dc.user_id === userId)
  }); // , (a, b) => a.dealChannelsList === b.dealChannelsList);
  const dealChannelId = dealChannel && dealChannel.id;
  const dealChannelAndMessages = useSelector(state => state.dealChannelsAndMessages && dealChannel && state.dealChannelsAndMessages[dealChannel.id]);

  const submission = useSelector(state => {
    return state.dealsInfo && state.dealsInfo.submissions.find(ds => ds.deal_id === dealId && ds.user_id === userId);
  }); // , (a, b) => a.dealsInfo === b.dealsInfo);

  const deal = useSelector(state => {
    return state.dealsInfo && state.dealsInfo.deals.find(d => d.id === dealId);
  }); // , (a, b) => a.dealsInfo === b.dealsInfo);

  const otherUser = dealChannelAndMessages && dealChannelAndMessages.otherParticipant;
  const messages = dealChannelAndMessages && dealChannelAndMessages.messages;

  const isUnread = !!dealChannel && !!dealChannel.unread;

  // invert scrolling
  const flatlistRef = useRef();
  useEffect(() => {
    let scrollNode = flatlistRef.current && flatlistRef.current.getScrollableNode();
    if (!scrollNode) return;
    const listener = scrollNode.addEventListener("wheel", e => {
      scrollNode.scrollTop -= e.deltaY;
      e.preventDefault();
    });
    // 2023-12-14
    // - commented this line out because react-native-web no longer supports `setNativeProps` (upgraded from `~0.13.12` to `~0.19.6`)
    // - scrolling still seems to work without it though (on Mac on Chrome 120), maybe RNW fixed inverted scrolling somewhere in there
    // flatlistRef.current.setNativeProps({ style: { transform: "translate3d(0,0,0) scaleY(-1)" } });
    return () => scrollNode.removeEventListener("wheel", listener);
  }); // needs to run any time flatlist mounts

  useEffect(() => {
    let isInTempLogin = !!store.getState().adminUserId;
    if (isInTempLogin) return;

    if (isUnread) {
      dispatch(localMarkDealChannelRead(dealChannelId));
      apiMarkDealChannelRead(dealChannelId);
    }
  }, [isUnread]);

  // const contactUs = async () => {
  //   const isAvailable = await (Device.isDevice && SMS.isAvailableAsync());
  //   if (isAvailable) {
  //     await textDuetTeam(`Hey Duet Team! This is ${me.name} (@${me.tiktok_handle}) and I'd like to join the collab beta`);
  //   } else {
  //     let isAvailable = await MailComposer.isAvailableAsync();
  //     if (isAvailable) {
  //       MailComposer.composeAsync({
  //         recipients: ['team@swipehouse.co'],
  //         subject: 'Duet Feedback',
  //       });
  //     } else {
  //       Alert.alert('Email us at team@swipehouse.co!');
  //     }
  //   }
  // };

  const getDealChannelAndMessages = async () => {
    if (!dealChannelId) return;
    const res = await updateDealChannelAndMessages(dealChannelId);
    console.log("ConvoScreen - updateDealChannelAndMessages res", res);
    if (res.status !== 'success') {
      return Alert.alert("Error getting messages", res.message, [
        { style: 'cancel', text: 'OK', onPress: () => null },
        { style: 'default', text: 'Try again', onPress: () => getDealChannelAndMessages() },
      ]);
    }
  };
  useEffect(() => {
    getDealChannelAndMessages();
  }, [dealChannelId]);

  useEffect(() => {
    const listener = addHomeSocketOpenListener(() => getDealChannelAndMessages());
    return () => removeHomeSocketOpenListener(listener);
  }, []);

  const dealSummaryJSX = !!submission && <DealStatusSummary submission={submission} deal={deal}/>;

  let body = null;
  if (!dealChannel) { // doesn't exist yet
    body = (
      <KeyboardAvoidingView style={{ flex: 1 }} behavior={'padding'}>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
          keyboardDismissMode={'on-drag'}
        >
          {dealSummaryJSX}
          {/*<Text style={{ ...maybeRounded('500'), color: '#333', fontSize: 18, marginBottom: 8, marginTop: 150 }}>No messages yet</Text>*/}
          {/*<Text style={{ ...maybeRounded('500'), color: '#666', fontSize: 17, marginBottom: 12 }}>Send the first message below️</Text>*/}
        </ScrollView>

        <ConvoTextInput channelId={dealChannelId} dealId={dealId} userId={userId} refresh={getDealChannelAndMessages} submission={submission}/>
      </KeyboardAvoidingView>
    );
  }
  else if (!messages || !otherUser) { // loading
    body = (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {/*<Text style={{ ...maybeRounded('500'), color: '#666', fontSize: 16, marginBottom: 12 }}>Loading your messages ✉️</Text>*/}
        <ActivityIndicator style={{ marginTop: -100 }}/>
      </View>
    );
  }
  else {  // existing convo
    body = (
      <KeyboardAvoidingView style={{ flex: 1 }} behavior={'padding'}>
        <FlatList
          ref={flatlistRef}
          style={{ width: '100%' }}
          contentContainerStyle={{ flexGrow: 'inherit' }}
          data={messages}
          keyExtractor={message => "" + message.id}
          renderItem={({ item, index }) => (
            <Message
              item={item}
              otherUser={otherUser}
              prevMessage={(index < messages.length - 1) ? messages[index+1] : null}
              nextMessage={index > 0 ? messages[index-1] : null}
              isDeal
            />
          )}
          // ItemSeparatorComponent={() => <Touchable noFeedback style={{ height: 16 }}/>}
          ListFooterComponent={dealSummaryJSX}
          ListFooterComponentStyle={{ marginTop: 'auto', marginBottom: 0 }}
          // ListHeaderComponent={dealSummaryJSX}
          // ListHeaderComponentStyle={{ marginBottom: 'auto', marginTop: 0 }}
          keyboardDismissMode={'on-drag'}
          inverted
          scrollsToTop={false}
          // keyboardShouldPersistTaps={'always'}
        />

        <ConvoTextInput dealChannelId={dealChannelId} dealId={dealId} userId={userId} refresh={getDealChannelAndMessages} submission={submission}/>
      </KeyboardAvoidingView>
    )
  }

  return (
    <View style={{flex: 1, borderLeftWidth: 1, borderColor: '#DADDE3', paddingHorizontal: 32, paddingTop: 32, paddingBottom: 24}}>
      <View style={{ flex: 1, backgroundColor: '#fff', borderRadius: 12, overflow: 'hidden', minWidth: 400 }}>
        <View style={{  paddingVertical: 20, alignItems: 'center', justifyContent: 'center', boxShadow: '0px 4px 16px 0px #00000014' }}>
          <View>
            <Text style={{ ...maybeRounded('600'), fontSize: 20, color: '#0F1D40' }}>{(otherUser && otherUser.name) || name}</Text>
          </View>
        </View>
        {body}
      </View>
    </View>
  );
}

function ConvoTextInput({ dealChannelId, dealId, userId, refresh, submission }) {
  // const reportModalRef = useRef(null);

  const textInputRef = useRef();
  useEffect(() => {
    textInputRef.current && textInputRef.current.focus();
  }, [dealChannelId]);

  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const closeModal = () => setModalVisible(false);
  const createChannel = async () => {
    setSending(true);
    let _message = message;
    setMessage('');
    const res = await apiCreateDealConvo(dealId, userId, _message);
    if (res.status !== 'success') {
      setMessage(_message);
      setSending(false);
      return Alert.alert("Error sending message", res.message);
    }
    await updateDealChannelsList();
    await refresh();
    setSending(false);
  };
  const send = async () => {
    if (!dealChannelId) return createChannel();
    setSending(true);
    let _message = message;
    setMessage('');
    const res = await apiSendDealMessage(dealChannelId, _message);
    if (res.status !== 'success') {
      setMessage(_message);
      setSending(false);
      return Alert.alert("Error sending message", res.message);
    }
    await refresh();
    setSending(false);
    await updateDealChannelsList();

    if (_message.includes('@') || _message.toLowerCase().includes('gmail') || _message.toLowerCase().includes('email')) {
      alert(`Please keep all communication on platform. If we find you breaking this rule we'll suspend both your account as well as the creator's. If there's something you want to do that you currently can't with our platform, contact us and we'll add it.`);
    }

    // reportModalRef.current.show();
  };

  const sendReceipt = async( price ) => {
    if (!dealChannelId) return createChannel();
    setSending(true);
    let _message = 'This is an automatically generated receipt from Swipehouse\n' + '--------------\n\n' + 'The following payment to the creator has been sent: $' + price * .8 + '\n\n' + 'You should also receive a push notification confirming this' + '\n\n' + 'This amount will be available in your account as soon as Stripe processes it ✨';
    const res = await apiSendDealMessage(dealChannelId, _message);
    if (res.status !== 'success') {
      setMessage(_message);
      setSending(false);
      return Alert.alert("Error sending message", res.message);
    }
    await refresh();
    setSending(false);
    await updateDealChannelsList();
  }

  return (
    <View style={{ paddingVertical: 16, paddingHorizontal: 24, gap: 8, backgroundColor: '#E9EDF2', flexDirection: 'row', alignItems: 'center' }}>
      <View style={{ overflowY: 'auto', flex: 1, minHeight: 38, maxHeight: 200, backgroundColor: '#fff', borderRadius: 20, borderColor: '#E0E0E0', borderWidth: 1, flexDirection: 'row', alignItems: 'center' }}>
        {/*<TextInput*/}
        {/*  style={{ flex: 1, color: '#000', fontSize: 16, paddingHorizontal: 12, paddingVertical: 6, outline: 'none' }}*/}
        {/*  placeholder={"Write a message"}*/}
        {/*  placeholderTextColor={'#8c8c8c'}*/}
        {/*  value={message}*/}
        {/*  onChangeText={setMessage}*/}
        {/*  maxLength={2000}*/}
        {/*  multiline*/}
        {/*/>*/}

        <TextareaAutosize
          ref={textInputRef}
          rows={1}
          maxRows={8}
          maxLength={10000}
          placeholder={"Write a message"}
          onChange={e => setMessage(e.target.value)}
          value={message}
          style={StyleSheet.flatten({
            ...inputTextInputStyle,

            // fontFamily: 'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif',
            outline: 'none',
            border: 'none',
            resize: 'none',

            flex: 1,
            maxHeight: 140,
            overflow: 'hidden',
            color: '#000',
            fontSize: 14,
            // paddingHorizontal: 12,
            // paddingVertical: 6,
            backgroundColor: 'transparent',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 14,
            paddingBottom: 14,
          })}
          onKeyPress={e => {
            console.log("ConvoScreen - onKeyPress event", e);
            if (e.key === 'Enter' && !e.shiftKey && message.length) {
              send();
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onPaste={e => {
            if (e.clipboardData.files && [...e.clipboardData.files].length > 0) {
              // e.stopPropagation();
              // e.preventDefault();
              //
              // const fileList = e.clipboardData.files;
              // const attachments = await fileListToAttachments(fileList);
              // this.addAttachments(attachments);
            }
          }}
          autoFocus
        />

        {sending && <ActivityIndicator style={{ marginRight: 8 }}/>}
      </View>

      <Touchable disabled={!message} onPress={send} style={{ height: 48, width: 75, backgroundColor: '#0F1D40', borderRadius: 99, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: '#fff', fontSize: 14, ...maybeRounded('600'), paddingHorizontal: 12 }}>Send</Text>
      </Touchable>
      {!!submission && submission.status === 'paid' ?
        <Touchable onPress={() => setModalVisible(true)} style={{ height: 48, width: 100, backgroundColor: '#1dbf73', borderRadius: 99, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: '#fff', fontSize: 14, ...maybeRounded('600'), paddingHorizontal: 12 }} rows={1}>Pay Again</Text>
        </Touchable> : null}

        <RepeatPaymentModal submission={submission} modalVisible={modalVisible} closeModal={closeModal} dealId={dealId} userId={userId} sendReceipt={sendReceipt} />
      {/*<StoreReviewModal ref={reportModalRef}/>*/}
    </View>
  );
}

function RepeatPaymentModal({ submission, modalVisible, closeModal, dealId, userId, sendReceipt }) {

  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState(false);
  const [confirmingSend, setConfirmingSend] = useState(false);

  useEffect(() => {
    if (!modalVisible) setConfirmingSend(false);
  }, [modalVisible]);

  const me = useSelector(state => state.me);

  const payCreator = async () => {
    if (!confirmingSend) {
      return setConfirmingSend(true);
    } else {
      setConfirmingSend(false);
    }

    if (!me.company_has_added_card) return alert(`Add a payment method in settings to complete.`);

    setLoading(true);
    const res = await apiPayCreatorAgainForSubmission(dealId, userId, price);
    if (res.status !== 'success') {
      setLoading(false);
      closeModal();
      return alert(`Error marking complete: ${res.message}`);
    }
    await updateDealsInfo();
    setLoading(false);
    setPaid(true);
    sendReceipt(price);
  };

  return (
    <Modal
      visible={modalVisible}
      close={closeModal}
      style={{ width: '100%', height: '100%', borderWidth: 0, borderColor: 'none', backgroundColor: 'rgba(0,0,0,.3)', alignItems: 'center', justifyContent: 'center' }}
    >
      <View
        style={{
          width: 300,
          // height: 150,
          backgroundColor: 'white',
          borderRadius: 10,
          alignItems: 'center',
          justifyContent: 'center',
          // padding: 5,
          paddingVertical: 20,
          paddingHorizontal: 14,
        }}
      >
        <View style={{ height: 12 }}/>
        <Text style={{ fontWeight: '600', fontSize: 14, color: '#16191C' }}>
          {!!submission ? submission.name + ' | Pay Again' : null}
        </Text>
        <View style={{ height: 10 }}/>
        <Text style={{ fontWeight: '400', fontSize: 14, color: '#16191C', textAlign: 'center' }}>
          As long as the creator is onboard for it, companies use this to film again with a creator{'\n\n'}Note: creators get ~80% after our platform fee. If they expect to be paid $80, enter a value of $100.
          {'\n\n'}Confirm the details of filming with the creator before paying.
        </Text>
        <View style={{ height: 14 }}/>
        <TextInput
          style={styles.input}
          value={price}
          onChangeText={setPrice}
          placeholder={"340"}
        />
        <View style={{ height: 10 }}/>
        <Touchable onPress={payCreator} style={{ height: 35, width: '100%', backgroundColor: !paid ? (confirmingSend ? '#1dbf38' : '#3E8BFF') : '#1dbf73', alignItems: 'center', justifyContent: 'center', borderRadius: 3 }}>
          {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ ...maybeRounded('700'), color: '#fff'}}>{!paid ? (confirmingSend ? 'Press again to confirm' : 'Pay') : 'Paid $' + price * .8}</Text>}
        </Touchable>
        {/*{!paid ? <Text style={{ paddingTop: 10, textAlign: 'center' }}>Pressing button immediately sends payment</Text> : null}*/}
      </View>
    </Modal>

  );
}





const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 50,
    borderRadius: 4,
    backgroundColor: '#fff',
    padding: 10,
    ...maybeRounded('500'),
    fontSize: 15,
    color: '#000000',

    borderWidth: 1,
    borderColor: '#dddfe1',
  },
});