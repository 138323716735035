import React, { useEffect, Fragment, useRef, useState, useMemo } from 'react';
import {
  ActivityIndicator,
  Alert,
  FlatList,
  Image,
  KeyboardAvoidingView,
  Linking,
  Modal,
  Platform,
  ScrollView, SectionList,
  Share,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { apiApproveCampaign, apiGetCampaigns, apiSetCampaignStatus } from "../../api/api";
import Touchable from "../../components/Touchable";
import { maybeRounded } from "../../assets/assets";
import useToggleOptions from "../../util/useToggleOptions";

// import { TAB_BAR_HEIGHT } from "../tabBarHeight";
const TAB_BAR_HEIGHT = 0;

export default function CampaignReview() {

  const [refreshing, setRefreshing] = useState(false);
  const [data, setData] = useState(null);
  const [query, setQuery] = useState('');

  const [statusFilter, toggleStatusFilter] = useToggleOptions('all', ['all', 'open', 'applicants_only', 'closed']);
  const [approvalFilter, toggleApprovalFilter] = useToggleOptions('all', ['all', 'approved', 'pending']);
  const [campaignTypeFilter, toggleCampaignTypeFilter] = useToggleOptions('all', ['all', 'post', 'ugc', 'install']);

  const getCampaignsList = async () => {
    setRefreshing(true);
    const res = await apiGetCampaigns();
    setRefreshing(false);
    if (res.status !== 'success') {
      return alert(`Error getting campaigns - ${res.message}`);
    }
    setData(res.data.deals);
  };

  useEffect(() => {
    getCampaignsList()
  }, []);

  const campaignsList = useMemo(() => {
    if (!data) return [];
    let ret = [...data];
    if (query) ret = ret.filter(item => item.campaign_name.toLowerCase().includes(query.toLowerCase()));
    if (statusFilter !== 'all') ret = ret.filter(item => item.status === statusFilter);
    if (approvalFilter !== 'all') ret = ret.filter(item => approvalFilter === 'approved' ? item.is_approved : !item.is_approved);
    if (campaignTypeFilter !== 'all') ret = ret.filter(item => item.campaign_type === campaignTypeFilter);
    return ret;
  }, [data, query, statusFilter, approvalFilter, campaignTypeFilter]);

  return (
    <View style={{ flex: 1, backgroundColor: '#f0f0f0', marginTop: TAB_BAR_HEIGHT, width: '100%', maxWidth: 600 }}>
      <View style={{ flexDirection: 'row', width: '100%', height: 50, borderBottomWidth: 1, borderBottomColor: '#e0e0e0', backgroundColor: '#f0f0f0', paddingHorizontal: 8, paddingVertical: 6 }}>
        <TextInput
          style={{ flex: 1, paddingHorizontal: 14, fontSize: 16, color: '#000', borderWidth: 1, borderColor: '#e0e0e0', paddingVertical: 6, backgroundColor: '#fff', borderRadius: 20 }}
          placeholder={'Search'}
          placeholderTextColor={'#bbb'}
          value={query}
          onChangeText={setQuery}
          // returnKeyType={'done'} // deprecated in RNW 0.19
          enterKeyHint={'done'}
          autoCapitalize={'none'}
          autoCorrect={false}
          clearButtonMode={'always'}
        />
        <View style={{ width: 6 }}/>
        <Touchable onPress={toggleStatusFilter} style={{ width: 72, height: '100%', backgroundColor: '#3a9', borderRadius: 20, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: '#fff', fontSize: 12, ...maybeRounded('500') }}>Status: {statusFilter}</Text>
        </Touchable>
        <View style={{ width: 6 }}/>
        <Touchable onPress={toggleApprovalFilter} style={{ width: 72, height: '100%', backgroundColor: '#378eaa', borderRadius: 20, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: '#fff', fontSize: 12, ...maybeRounded('500') }}>Approved: {approvalFilter}</Text>
        </Touchable>
        <View style={{ width: 6 }}/>
        <Touchable onPress={toggleCampaignTypeFilter} style={{ width: 72, height: '100%', backgroundColor: '#378eaa', borderRadius: 20, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: '#fff', fontSize: 12, ...maybeRounded('500') }}>Type: {campaignTypeFilter}</Text>
        </Touchable>
      </View>
      <FlatList
        scrollIndicatorInsets={{ right: 1 }}
        keyExtractor={item => "" + item.id}
        data={campaignsList}
        renderItem={({ item }) => <CampaignItem item={item} refresh={getCampaignsList}/>}
        keyboardDismissMode={'on-drag'}
        onRefresh={getCampaignsList}
        refreshing={refreshing}
        // ItemSeparatorComponent={() => <Touchable noFeedback style={{ height: 1, backgroundColor: '#ccc' }}/>}
      />
    </View>
  );
}

function CampaignItem({ item, refresh }) {
  // item: {
  //   "available_product": "meow",
  //   "base_price_usd": 60,
  //   "brand_description": "debeers makes diamonds...",
  //   "campaign_name": "DeBeers UGC Campaign",
  //   "campaign_type": "ugc",
  //   "company_id": 10,
  //   "company_link": "https://debeers.com",
  //   "company_logo": "https://duet-company-logos.s3.amazonaws.com/30/6b9f3114-e5bf-42f3-99c9-deebafd3d250.jpg",
  //   "company_name": "DeBeers",
  //   "company_short_description": "Diamonds are forever",
  //   "created_on": "2020-12-14T13:09:10.703Z",
  //   "creator_negotiation": true,
  //   "creator_pref_notes": "nope",
  //   "expected_deal_budget": "1000",
  //   "id": 7,
  //   "is_approved": true,
  //   "is_flat_rate": null,
  //   "link_for_bio": "",
  //   "link_in_bio": "false",
  //   "link_to_app": "",
  //   "merch_msrp_usd": 1000,
  //   "name": null,
  //   "our_price_on_top_usd": null,
  //   "post_immediately": false,
  //   "post_required": null,
  //   "post_specs": "",
  //   "price_per_install_usd": null,
  //   "raw_amount": null,
  //   "requires_mailing": true,
  //   "status": "open",
  //   "user_product_notes_required": null,
  //   "video_specs": "make a nice video with all the things make a nice vide..."
  // }

  // apiApproveCampaign
  // apiSetCampaignStatus

  const [loading, setLoading] = useState(false);

  const approve = async (notify) => {
    setLoading(true);
    const res = await apiApproveCampaign(item.id, notify);
    setLoading(false);
    if (res.status !== 'success') {
      return alert(`Error approving campaign - ${res.message}`);
    } else {
      await refresh();
    }
  };
  const approvePressed = async () => {
    return await approve(true);
    // Alert.alert(`Notify them of approval?`, null, [
    //   { style: 'default', text: 'Notify', onPress: () => approve(true) },
    //   { style: 'default', text: 'Dont notify', onPress: () => approve(false) },
    //   { style: 'cancel', text: 'Cancel', onPress: () => null },
    // ]);
  };

  const closeForSubmissions = async (notify) => {
    setLoading(true);
    const res = await apiSetCampaignStatus(item.id, 'applicants_only', notify);
    setLoading(false);
    if (res.status !== 'success') {
      return alert(`Error closing campaign - ${res.message}`);
    } else {
      await refresh();
    }
  };
  const closeForSubmissionsPressed = async () => {
    return await closeForSubmissions(true);
    // Alert.alert(`Notify them of close?`, null, [
    //   { style: 'default', text: 'Notify', onPress: () => closeForSubmissions(true) },
    //   { style: 'default', text: 'Dont notify', onPress: () => closeForSubmissions(false) },
    //   { style: 'cancel', text: 'Cancel', onPress: () => null },
    // ]);
  };

  const tit = (title) => <Text style={{ fontSize: 16, ...maybeRounded('500'), marginBottom: 4, marginLeft: -1, color: '#666' }}>{title}</Text>;
  const row = (title, value) => (
    <View style={{ flexDirection: 'row' }}>
      <Text style={{ fontSize: 14, ...maybeRounded('600'), width: 140 }}>{title}: </Text>
      <Text style={{ fontSize: 14, ...maybeRounded('400'), flex: 1 }}>{String(value)}</Text>
    </View>
  );
  const imr = (title, uri) => ( // Image Row
    <View style={{ flexDirection: 'row' }}>
      <Text style={{ fontSize: 14, ...maybeRounded('600'), width: 140 }}>{title}: </Text>
      <Image source={{ uri: uri || undefined }} style={{ backgroundColor: '#f0f0f0', width: 100, height: 100 }} resizeMode={'cover'}/>
    </View>
  );
  const hr = <View style={{ marginHorizontal: 0, marginVertical: 6, height: StyleSheet.hairlineWidth + (1 - 1), backgroundColor: '#777' }}/>;

  const [collapsed, setCollapsed] = useState(true);
  if (collapsed) {
    return (
      <Touchable onPress={() => setCollapsed(false)} style={{ padding: 10, margin: 10, marginVertical: 6, backgroundColor: '#fff', borderRadius: 10 }}>
        <View style={{ flexDirection: 'row' }}>
          <Image source={{ uri: item.company_logo || undefined }} style={{ backgroundColor: '#f0f0f0', width: 50, height: 50, borderRadius: 10, marginRight: 10 }} resizeMode={'cover'}/>
          <View>
            <Text>#{item.id} {item.campaign_name}</Text>
            <Text>
              {item.campaign_type}
              {' • '}
              {item.campaign_type === 'install'
                ? <Text>${item.price_per_install_usd}/install</Text>
                : <Text>${item.base_price_usd} {item.creator_negotiation ? '+ negotiation' : ''}</Text>
              }
            </Text>
            <Text style={{ color: item.status === 'open' ? '#1448aa' : '#f00' }}>{item.status}</Text>
            <Text style={{ color: item.is_approved ? '#1448aa' : '#f00' }}>{item.is_approved ? 'Approved ✅' : 'Pending ⏰'}</Text>
          </View>
        </View>
      </Touchable>
    );
  }

  return (
    <Touchable onPress={() => setCollapsed(true)} style={{ padding: 10, margin: 10, backgroundColor: '#fff', borderRadius: 10 }}>
      {tit("Campaign details")}
      {row("Id", item.id)}
      {row("Created On", item.created_on)}
      {row("Campaign Name", item.campaign_name)}
      {row("Campaign Type", item.campaign_type)}
      {row("Status", item.status)}
      {row("Approved", item.is_approved)}
      {hr}
      {tit("Company details")}
      {row("Company Id", item.company_id)}
      {row("Name", item.company_name)}
      {row("Short Description", item.company_short_description)}
      {row("Link", item.company_link)}
      {imr("Logo", item.company_logo)}
      {hr}
      {tit("Pricing")}
      {row("base_price_usd", item.base_price_usd)}
      {row("price_on_top_usd", item.our_price_on_top_usd)}
      {row("negotiation", item.creator_negotiation)}
      {row("is_flat_rate", item.is_flat_rate)}
      {hr}
      {tit("Notes")}
      {row("deal_budget", item.expected_deal_budget)}
      {row("creator_prefs", item.creator_pref_notes)}
      {row("min_age", item.min_age)}
      {hr}
      {tit("Product")}
      {row("available_product", item.available_product)}
      {row("merch_msrp_usd", item.merch_msrp_usd)}
      {row("requires_mailing", item.requires_mailing)}
      {row("product_notes_req", item.user_product_notes_required)}
      {hr}
      {tit("Post info")}
      {row("post_immediately", item.post_immediately)}
      {row("post_required", item.post_required)}
      {row("link_in_bio", item.link_in_bio)}
      {row("link_for_bio", item.link_for_bio)}
      {hr}
      {tit("Text")}
      {row("brand_description", item.brand_description)}
      {row("video_specs", item.video_specs)}
      {row("post_specs", item.post_specs)}
      {hr}
      {tit("Link info")}
      {row("link_to_app", item.link_to_app)}
      {row("price_per_install_usd", item.price_per_install_usd)}

      <View style={{ flexDirection: 'row', marginTop: 10 }}>
        {!item.is_approved &&
          <Touchable onPress={approvePressed} disabled={loading} style={{ marginRight: 6, justifyContent: 'center', alignItems: 'center', width: 160, height: 44, backgroundColor: '#13c07e', borderRadius: 3 }}>
            {loading ? <ActivityIndicator color={'#000'}/> : <Text style={{ ...maybeRounded('600'), fontSize: 16, color: '#fff' }}>Approve deal</Text>}
          </Touchable>
        }
        {!!item.is_approved && item.status === 'open' &&
          <Touchable onPress={closeForSubmissionsPressed} disabled={loading} style={{ justifyContent: 'center', alignItems: 'center', width: 160, height: 44, backgroundColor: '#e2144f', borderRadius: 3 }}>
            {loading ? <ActivityIndicator color={'#000'}/> : <Text style={{ ...maybeRounded('600'), fontSize: 16, color: '#fff' }}>Close submissions</Text>}
          </Touchable>
        }
      </View>
    </Touchable>
  )
}