import React, { Fragment, useState, useEffect, useRef } from 'react';
import Touchable from "../components/Touchable"
import { maybeRounded } from "../assets/assets";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/reducers";
import { FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { VIEW_ONLY_ADMINS, FULL_ADMINS } from "../util/adminIds";

// const duetLogo = require('../assets/images/logo.png');
const duetLogo = 'https://cdn.prod.website-files.com/65efe7dd7802fd2587b66c78/66631c8ca73ec71414af58e7_a.svg';

export default function Navbar({ logo, signIn, account, logoutBar, pro, dark }) {
  const userId = useSelector(state => state.userId);
  const adminUserId = useSelector(state => state.adminUserId);
  const isAdmin = (FULL_ADMINS.includes(userId) || VIEW_ONLY_ADMINS.includes(userId)) || !!adminUserId;
  const dispatch = useDispatch();
  const logoutPressed = () => dispatch(logout());

  return (
    <View style={{
      width: '100%',
      height: "65px",
      backgroundColor: dark ? '#236EFF' : '#5A95FF',
      paddingHorizontal: '13%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      ...(logoutBar && {
        // backgroundColor: '#476ada',
        backgroundColor: 'white',
        borderBottomWidth: 1,
        borderColor: '#ccc',
        shadowColor: '#ccc',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 5,

        paddingLeft: 42,
        paddingRight: 24,
      }),
    }}>
      <Touchable onPress={() => window.location = "/dashboard"} disabled={!logoutBar} style={{ marginVertical: 10 }}>
        <Image style={{ 
          width: 167, 
          height: 26, 
          backgroundColor: '#fff', 
          borderRadius: 7 
        }} source={duetLogo}/>
      </Touchable>
      <View style={{ flex: 1 }}/>
      {signIn &&
        <Touchable>
          <Text style={styles.signInText}>Sign In</Text>
        </Touchable>
      }
      {account &&
        <Touchable>
          <Text style={styles.signInText}>Account</Text>
        </Touchable>
      }
      {logoutBar && isAdmin &&
        <View>
          <Text style={styles.signInText}>
            <a style={styles.linkStyle} href={"/adminpanel"}>Admin</a>
          </Text>
        </View>
      }
      {logoutBar && isAdmin && <View style={{ width: 50 }}/>}
      {logoutBar &&
        <View>
          <Text style={styles.signInText}>
            <a style={styles.linkStyle} href={"/settings"}>Settings</a>
          </Text>
        </View>
      }
      {logoutBar && <View style={{ width: 50 }}/>}
      {logoutBar &&
        <View>
          <Text style={styles.signInText}>
            <a style={styles.linkStyle} target={"_blank"} href={"mailto:team@swipehouse.co?subject=Swipehouse for Brands Help"}>Contact Us</a>
          </Text>
        </View>
      }
      {logoutBar && <View style={{ width: 50 }}/>}
      {logoutBar &&
        <Touchable onPress={logoutPressed}>
          <Text style={styles.signInText}>Log Out</Text>
        </Touchable>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  signInText: {
    color: '#221054B2',
    fontWeight: "600",
    fontSize: 16,
  },
  linkStyle: {
    color: '#221054B2',
    textDecorationLine: 'none'
  }
});
