import * as Font from 'expo-font';
import { Asset } from 'expo-asset';
import { Platform } from 'react-native';

const getFonts = () => ({
  'Pacifico': require('./fonts/Pacifico-Regular.ttf'),
  ...(Platform.OS === 'ios' ? {
    'Rounded-Regular': require('./fonts/SF-Pro-Rounded-Regular.otf'),
    'Rounded-Medium': require('./fonts/SF-Pro-Rounded-Medium.otf'),
    'Rounded-Semibold': require('./fonts/SF-Pro-Rounded-Semibold.otf'),
    'Rounded-Bold': require('./fonts/SF-Pro-Rounded-Bold.otf'),
    'Rounded-Black': require('./fonts/SF-Pro-Rounded-Black.otf'),
  } : {
    // 'Rounded-Regular': require('./fonts/Nunito-Regular.ttf'),
    // 'Rounded-Medium': require('./fonts/Nunito-SemiBold.ttf'),
    // 'Rounded-Semibold': require('./fonts/Nunito-Bold.ttf'),
    // 'Rounded-Bold': require('./fonts/Nunito-ExtraBold.ttf'),
  }),
});

export const maybeRounded = (weight) => {
  if (Platform.OS === 'ios') {
    if (!weight || weight === '400')           return { fontWeight: weight, fontFamily: 'Rounded-Regular' };
    if (weight === '500')                      return { fontWeight: weight, fontFamily: 'Rounded-Medium' };
    if (weight === '600')                      return { fontWeight: weight, fontFamily: 'Rounded-Semibold' };
    if (weight === 'bold' || weight === '700') return { fontWeight: weight, fontFamily: 'Rounded-Bold' };
    if (weight === '800')                      return { fontWeight: weight, fontFamily: 'Rounded-Black' };
  }
  // else if (Platform.OS === 'android') {
  //   if (!weight || weight === '400')           return { fontFamily: 'Rounded-Regular' };
  //   if (weight === '500')                      return { fontFamily: 'Rounded-Medium' };
  //   if (weight === '600')                      return { fontFamily: 'Rounded-Semibold' };
  //   if (weight === 'bold' || weight === '700') return { fontFamily: 'Rounded-Bold' };
  // }
  return { fontWeight: weight };
};

export const loadAssets = () => (
  Promise.all([
    Font.loadAsync(getFonts()),
    Asset.loadAsync([
      // require('./audio/facetime-ring.mp3'),
      // require('./audio/facetime-invitation-accepted.mp3'),
    ])
  ])
);
