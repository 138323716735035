import React, { Fragment, useState, useEffect, useRef } from 'react';
import Touchable from "../../components/Touchable"
import { maybeRounded } from "../../assets/assets";
import Modal from "../../components/Modal"
import { ActivityIndicator, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { useSelector } from "react-redux";
import MockMessage from "../Messages/MockMessage";
import * as ImagePicker from "expo-image-picker";
import { uploadCompanyLogoS3 } from "../../util/s3";
import { apiCreateNewDeal } from "../../api/api";
import { updateDealsInfo, updateMe } from "../../redux/asyncActions";
import CameraIcon from '../../components/svg-icons/CameraIcon';
import EyeIcon from '../../components/svg-icons/EyeIcon';
import UploadIcon from '../../components/svg-icons/UploadIcon';

const checkMark = require("../../assets/images/icons8-checkmark.png");

function CampaignType({ campaignType, setCampaignType }) {
  return (
    <View style={{ paddingVertical: 8, flexDirection: 'column', gap: 12 }}>
      <Text style={styles.headerTwo}>Type of Campaign</Text>
      <View style={{ flexDirection: 'row', gap: 24 }}>
        <Touchable onPress={() => setCampaignType('UGC')} style={[styles.card, { borderColor: '#E2E4E8', width: 178, padding: 16, borderRadius: 10, flexDirection: 'column', justifyContent: 'space-between' }, campaignType === 'UGC' && styles.selectedCard]}>
          <View>
            <Text style={campaignType === 'UGC' ? styles.cardTitleSelected : styles.cardTitle}>Video-only (UGC)</Text>
            <Text style={ campaignType === 'UGC' ? styles.cardSubtitleSelected :styles.cardSubtitle}>TikTokers film content for you to use in your own ads</Text>
          </View>
          <View>
            <CameraIcon style={{ alignSelf: 'center' }} width={24} height={24} fill={campaignType === 'UGC' ? '#fff' : '#878EA0'}/>
            {/* <img src={require("../../assets/icons/Vectoricon-camera.svg")} style={campaignType === 'UGC' ? {...styles.iconSelected} : {...styles.iconSVG}} alt='svgImage'/> */}
          </View>
        </Touchable>
        <Touchable 
          onPress={() => setCampaignType('Post')} 
          style={[styles.card, { borderColor: '#E2E4E8', width: 178, padding: 16, borderRadius: 10, flexDirection: 'column', justifyContent: 'space-between' }, campaignType === 'Post' && styles.selectedCard]}
        >
          <View>
            <Text style={campaignType === 'Post' ? styles.cardTitleSelected : styles.cardTitle}>Video & Post</Text>
            <Text style={ campaignType === 'Post' ? styles.cardSubtitleSelected :styles.cardSubtitle}>TikTokers film content and also post it to their profiles</Text>
          </View>
          <View>
            <EyeIcon style={{ alignSelf: 'center' }} width={24} height={24} fill={campaignType === 'Post' ? '#fff' : '#878EA0'}/>
            {/* <Text style={{ fontSize: 36 }}>👁</Text> */}
            {/* <img src={require("../../assets/icons/Vectoricon-camera.svg")} style={campaignType === 'UGC' ? {...styles.iconSelected} : {...styles.iconSVG}} alt='svgImage'/> */}
          </View>
        </Touchable>
        {/* <Touchable disabled onPress={() => setCampaignType('Link')} style={[styles.card, campaignType === 'Link' && styles.selectedCard, { opacity: 0.5 }]}>
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 36 }}>🔗</Text>
          </View>
          <Text style={styles.cardTitle}>Pay per Install</Text>
          <Text style={styles.cardSubtitle}>TikTokers promote a link that leads to your product. (Coming soon)</Text>
        </Touchable> */}
      </View>
    </View>
  );
}

function CampaignBudget({ campaignBudget, setCampaignBudget }) {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <Text style={styles.headerTwo}>What is your expected budget for this campaign? (USD)</Text>
      <TextInput
        style={styles.input}
        onChangeText={setCampaignBudget}
        value={campaignBudget}
        placeholder={"5000"}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>This is the ballpark amount you're able to spend for this campaign. Numbers can range from $500 - $100,000. If you clicked 'Pay per click/install' above, this will be your campaign amount. If you clicked a different option, this will just be a guide.</Text>*/}
      {/*<Text style={styles.descriptionText}>This is the ballpark amount you're able to spend for this campaign</Text>*/}
    </View>
  );
}

function CustomCampaignNameAndImage({
  me,
  customCampaignName, setCustomCampaignName,
  customCampaignImageLocal, choosePhoto,
  customCampaignShortDescription, setCustomCampaignShortDescription,
  customCampaignLink, setCustomCampaignLink,
}) {
  const hasAnyImage = !!(customCampaignImageLocal || me.company_logo);
  return (
    <Fragment>
      <View style={styles.inputBox}>
        <Text style={styles.headerTwo}>Brand Name</Text>
        <TextInput
          style={styles.input}
          onChangeText={setCustomCampaignName}
          value={!!customCampaignName ? customCampaignName : me.company_name}
        />
      </View>

      <View style={styles.inputBox}>
        <Text style={styles.headerTwo}>Short brand description</Text>
        <TextInput
          style={styles.input}
          value={customCampaignShortDescription}
          onChangeText={setCustomCampaignShortDescription}
          placeholder={'Delicious food, delivered'}
          placeholderTextColor={'#777E93'}
          maxLength={26}
        />
      </View>

      <View style={styles.inputBox}>
        <Text style={styles.headerTwo}>Brand Logo</Text>
        <View style={{ flexDirection: 'column', gap: 8, alignItems: 'center', padding: 24, backgroundColor: '#F9F9F9', borderRadius: 8, borderWidth: 1, borderColor: '#E9EAED' }}>
          <Touchable onPress={() => choosePhoto(true)} style={{ borderRadius: 4, height: hasAnyImage ? 90 : 24, width: hasAnyImage ? 90 : 24, justifyContent: 'center', alignItems: 'center' }}>
            {hasAnyImage && <Image source={customCampaignImageLocal ? customCampaignImageLocal : { uri: me.company_logo }} resizeMode={'cover'} style={{ width: 90, height: 90, borderRadius: 4 }}/>}
            {!hasAnyImage && <UploadIcon style={{ alignSelf: 'center' }} width={24} height={24} />}
          </Touchable>
          <Text style={{...styles.descriptionText, textAlign: 'center' }}>For best results, upload a square image{'\n'}without any transparency</Text>
        </View>
      </View>

      <View style={styles.inputBox}>
        <Text style={styles.headerTwo}>Brand website or app link</Text>
        <TextInput
          style={styles.input}
          value={customCampaignLink}
          onChangeText={setCustomCampaignLink}
          placeholder={'yourbrand.com'}
          placeholderTextColor={'#777E93'}
        />
      </View>
    </Fragment>
  );
}

function BrandDescription({ brandDescription, setBrandDescription }) {
  return (
    <View style={styles.inputBox}>
      <Text style={styles.headerTwo}>Your brand description</Text>
      <TextInput
        style={styles.inputMultiline}
        onChangeText={setBrandDescription}
        value={brandDescription}
        placeholder={'Finding a watch that’s stylish, bold, and built to last can cost a pretty penny, but we are here to change that. We believe you deserve to look good no matter your budget. In 2014 we started Vincero with a very simple mission: to make products without taking shortcuts, for the people who refuse to take them.'}
        multiline={true}
        placeholderTextColor={'#777E93'}
      />
      <Text style={styles.descriptionText}>Good brand descriptions get creators excited about filming you a vid. Explain why your brand is special or unique and why a creator would want to publicly vouch for your product.</Text>
    </View>
  );
}

function InstallReqs({ installReqs, setInstallReqs }) {
  return (
    <View style={styles.inputBox}>
      <Text style={styles.headerTwo}>What counts as an install?</Text>
      <TextInput
        style={[styles.inputMultiline, { height: 100 }]}
        onChangeText={setInstallReqs}
        value={installReqs}
        placeholder={'Anyone who joins using the link we send you or with your referral code will count as an install.'}
        multiline={true}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>Make it clear to .</Text>*/}
    </View>
  );
}

function VideoSpecifications({ videoSpecs, setVideoSpecs }) {
  return (
    <View style={styles.inputBox}>
      <Text style={styles.headerTwo}>The video specifications you want</Text>
      <TextInput
        style={styles.inputMultiline}
        onChangeText={setVideoSpecs}
        value={videoSpecs}
        placeholder={`We want the content to feel very organic as if it is a TikTok video, not an ad.  Use a high-quality camera that is at least 1080p (newer phones totally fine). Film in a bright setting, preferably during the day. Try to show FitnessAI off in the video if you have a second phone (or show the website w/ screenshots if you want) Also okay to leave this out. We're looking for...`}
        multiline={true}
        placeholderTextColor={'#777E93'}
      />
      <Text style={styles.descriptionText}>
        This sets the direction for your video. Our creators work well with both concrete and vague directions. You can request for edits and remakes from creators as long as they don't meet the specs here. Some things you may want to mention:
        {'\n\n'}• How strongly you want the video to feature your product. Are you looking for videos centered around your product, product sitting on the side of a creator's normal content, do you want a shoutout of the company name / website, any of the above, etc.
        {'\n\n'}• How you want the video exported. Do you want captions, do you want it saved without the watermark, and is there a specific video length you need?
        {'\n\n'}• "Things to note" with any bullet points you want to get across in the video.

        {/*This is where you can set the direction for your video. Our creators work well with both concrete and vague directions. You don't need to have a full script and can keep it open ended. Our creators can uniquely frame your product but some structure is good. You can request for edits and remakes from creators as long as they don't meet the specs here.*/}
        {/*{'\n\n'}If you have any specific export options you want, like exporting with or without the tiktok watermark, a specific video length, or captions, be sure to include that here. If you want your product featured a certain way (just show it in the background, give the website a shoutout, etc) you should include it here as well.*/}
        {/*By default, we will ask creators to use an HD camera, export their video without a tiktok watermark, and submit a video over 4 seconds in length.*/}
      </Text>
    </View>
  );
}

function PostSpecifications({ postSpecs, setPostSpecs }) {
  return (
    <View style={styles.inputBox}>
      <Text style={styles.headerTwo}>Any post specifications you want</Text>
      <TextInput
        style={styles.inputMultiline}
        onChangeText={setPostSpecs}
        value={postSpecs}
        placeholder={'Include the hashtags #savory, #gourmet in the video description. Pin this link in the comments: https://omsom.com/tiktok'}
        multiline={true}
        placeholderTextColor={'#777E93'}
      />
      <Text style={styles.descriptionText}>Here you can ask for a creator to post with certain hashtags or to mention your brand in their description or comments</Text>
    </View>
  );
}

function LinkInBio({ linkInBio, setLinkInBio }) {
  return (
    <View style={styles.inputBox}>
      <Text style={styles.headerTwo}>Are you asking creators to post a link in their bio?</Text>
      <View style={styles.checkboxRow}>
        <Touchable style={[styles.checkbox, linkInBio && { backgroundColor: '#3A3E41' }]} onPress={() => setLinkInBio(!linkInBio)}>
          {linkInBio && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} />}
        </Touchable>
        <Text style={styles.checkboxText}>Yes</Text>
      </View>
      {/*<Text style={styles.descriptionText}>If yes, the duration of the link will be for 4 days after the video is posted. As the link is an additional channel that tiktokers use to monetize, this option will cost more and is adjusted with follower size. The amount will be itemized below.</Text>*/}
      <Text style={styles.descriptionText}>If yes, the duration of the link will be for 4 days after the video is posted. As the link is an additional channel that tiktokers use to monetize, this option costs an additional $1 per 20k followers.</Text>
    </View>
  );
}

function BioLink({ linkValue, setLinkValue }) {
  return (
    <View style={styles.inputBox}>
      <Text style={styles.headerTwo}>What link should be used?</Text>
      <TextInput
        style={styles.input}
        onChangeText={setLinkValue}
        value={linkValue}
        placeholder={'https://omsom.com/tiktok'}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>Provide the link you want creators to post in their bio. If you selected 'pay per click/install' above, we will provide trackable links to the creators which you can monitor.</Text>*/}
      {/*<Text style={styles.descriptionText}>You can also message the link to them later</Text>*/}
    </View>
  );
}

function ReviewVid({ reviewVidFirst, setReviewVidFirst }) {
  return (
    <View style={styles.inputBox}>
      <Text style={styles.headerTwo}>Do you want to review videos before creators post?</Text>
      <View style={styles.checkboxRow}>
        <Touchable style={[styles.checkbox, reviewVidFirst && { backgroundColor: '#3A3E41' }]} onPress={() => setReviewVidFirst(!reviewVidFirst)}>
          {reviewVidFirst && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} />}
        </Touchable>
        <Text style={styles.checkboxText}>Yes</Text>
      </View>
    </View>
  );
}

function MailingProduct({ mailingProduct, setMailingProduct }) {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <Text style={styles.headerTwo}>Are you mailing product?</Text>
      <View style={styles.checkboxRow}>
        <Touchable style={[styles.checkbox, mailingProduct && { backgroundColor: '#3A3E41' }]} onPress={() => setMailingProduct(!mailingProduct)}>
          {mailingProduct && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} />}
        </Touchable>
        <Text style={styles.checkboxText}>Yes</Text>
      </View>
    </View>
  );
}

function MerchDetails({ merchDetails, setMerchDetails }) {
  return (
    <View style={{ flexDirection: 'column', gap: 6}}>
      <Text style={styles.headerTwo}>What products can creators choose to shoot with?</Text>
      <TextInput
        style={[styles.inputMultiline, { height: 90 }]}
        onChangeText={setMerchDetails}
        value={merchDetails}
        // placeholder={'Choose from any of the X-series watches at https://ourwebsite.com/'}
        placeholder={'You can choose whatever watch you want to film with, except those from the italian marble automatic series.'}
        multiline={true}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>Duet is not liable for lost or stolen product. However, if a creator receives your product and doesn't film with it, let us know and we will ban them from all future deals.</Text>*/}
    </View>
  );
}

function NoMerchDetails({ noMerchDetails, setNoMerchDetails }) {
  return (
    <Fragment>
      <Text style={styles.headerTwo}>If any, what products can't creators choose to shoot with?</Text>
      <TextInput
        style={[styles.inputMultiline, { height: 70 }]}
        onChangeText={setNoMerchDetails}
        value={noMerchDetails}
        placeholder={"None of the holiday gift boxes"}
        multiline={true}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>These may be third-party products you sell off your website, for example.</Text>*/}
    </Fragment>
  );
}

function MerchMSRP({ merchMSRP, setMerchMSRP }) {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      {/*<Text style={styles.headerTwo}>Merch MSRP</Text>*/}
      <Text style={styles.headerTwo}>Provided Product/Merch Value (USD) (optional)</Text>
      <TextInput
        style={styles.input}
        onChangeText={setMerchMSRP}
        value={merchMSRP}
        placeholder={'130'}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>Optional. If providing merch or product, enter its value here.</Text>*/}
      {/*<Text style={styles.descriptionText}>We will display this in the job post to creators.</Text>*/}
      {/*<Text style={styles.descriptionText}>We will display this in the job post to creators. Providing a range can be a good method of driving interest.</Text>*/}
    </View>
  );
}

function BasePrice({ basePrice, setBasePrice, campaignType }) {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <Text style={styles.headerTwo}>{campaignType === 'Post' && 'Base '}Price per Video (USD)</Text>
      <TextInput
        style={styles.input}
        onChangeText={setBasePrice}
        value={basePrice}
        placeholder={'200'}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>The higher the price, the more creators are likely to respond to your deal. The minimum is $60 per video.</Text>*/}
      {/*<Text style={styles.descriptionText}>Minimum $60/video. This goes directly to the creator, with a 25% fee on top of this for Duet. {basePrice > 10 && ` (in this case $${(basePrice/4).toFixed(2)}/video)`}</Text>*/}
      {/*<Text style={styles.descriptionText}>Minimum $60/video. This goes directly to the creator, with a 25% fee on top of this for Duet. {basePrice > 10 && ` (in this case $${(basePrice/4).toFixed(2)}/video)`}</Text>*/}
      {/*<Text style={styles.descriptionText}>This goes directly to the creator, with a 25% fee on top for Duet. {basePrice > 10 && ` (in this case $${(basePrice * 0.25).toFixed(2)}/video)`}</Text>*/}
      {/*{campaignType === 'UGC' && <Text style={styles.descriptionText}>This goes directly to the creator, with a 25% fee on top for Duet.</Text>}*/}
    </View>
  );
}

function PricePerInstall({ pricePerInstall, setPricePerInstall }) {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <Text style={styles.headerTwo}>Price per Install (USD)</Text>
      <TextInput
        style={styles.input}
        onChangeText={setPricePerInstall}
        value={pricePerInstall}
        placeholder={'2.00'}
        placeholderTextColor={'#777E93'}
      />
      {/*<Text style={styles.descriptionText}>The higher the price, the more creators are likely to respond to your deal. The minimum is $1 per install.</Text>*/}
    </View>
  );
}

function AppLink({ appLink, setAppLink }) {
  return (
    <View style={{ flexDirection: 'column', gap: 8 }}>
      <Text style={styles.headerTwo}>Link to your app</Text>
      <TextInput
        style={styles.input}
        onChangeText={setAppLink}
        value={appLink}
        placeholder={'https://apps.apple.com/us/app/swipehouse/id1516566208'}
        placeholderTextColor={'#777E93'}
      />
    </View>
  );
}

function CreatorNegotiation({ creatorNeg, setCreatorNeg }) {
  return (
    <View style={{ flexDirection: 'column', gap: 8}}>
      <Text style={styles.headerTwo}>Do you want to allow creators to negotiate?</Text>
      <View style={styles.checkboxRow}>
        <Touchable style={[ styles.checkbox, creatorNeg && { backgroundColor: '#3A3E41' }]} onPress={() => setCreatorNeg(!creatorNeg)}>
          {creatorNeg && <Image style={styles.checkBoxIcon} source={checkMark} resize={'cover'} /> }
        </Touchable>
        <Text style={styles.checkboxText}>Yes</Text>
      </View>
      {/*<Text style={styles.descriptionText}>This will allow bigger creators to respond. We have many 1M+ follower creators on Duet. These creators usually have their own pricing system. Check this box if you want these creators to be able to respond and potentially work out a deal with you. The deal cannot be priced below our standard amount.</Text>*/}
      {/*<Text style={styles.descriptionText}>This will allow more creators to respond. We have many 1M+ follower creators on Duet. These creators usually have their own pricing system. Check this box if you want these creators to be able to respond and potentially work out a deal with you.</Text>*/}
      <Text style={styles.descriptionText}>Some creators, particularly bigger ones with 1M+ followers, may have their own pricing system.</Text>
    </View>
  );
}

export default function CreateCampaignModel({ visible, toggleCampaignModal }) {

  const me = useSelector(state => state.me);

  const [loading, setLoading] = useState(false);

  // main form
  const [campaignType, setCampaignType] = useState('UGC'); // oneOf(['UGC', 'Post', 'Link'])
  const [campaignBudget, setCampaignBudget] = useState('');
  const [creatorNotes, setCreatorNotes] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [videoSpecs, setVideoSpecs] = useState('');
  const [postSpecs, setPostSpecs] = useState('');
  const [linkInBio, setLinkInBio] = useState(false);
  const [linkValue, setLinkValue] = useState('');
  const [reviewVidFirst, setReviewVidFirst] = useState(false);
  const [mailingProduct, setMailingProduct] = useState(false);
  const [merchDetails, setMerchDetails] = useState(null);
  const [noMerchDetails, setNoMerchDetails] = useState(null);
  const [merchMSRP, setMerchMSRP] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [pricePerInstall, setPricePerInstall] = useState('');
  const [appLink, setAppLink] = useState('');
  const [creatorNeg, setCreatorNeg] = useState(false);
  const [installReqs, setInstallReqs] = useState('');
  const [installDelayModal, setInstallDelayModal] = useState(false);

  // etc company info -- not actually used now
  const [companyLogoLocal, setCompanyLogoLocal] = useState(null);
  const [companyShortDescription, setCompanyShortDescription] = useState(me.company_short_description || '');
  const [companyLink, setCompanyLink] = useState(me.company_link || '');

  // instead of company info above, set brand info per-campaign
  const [customCampaignName, setCustomCampaignName] = useState('');
  const [customCampaignImageLocal, setCustomCampaignImageLocal] = useState(null);
  const [customCampaignShortDescription, setCustomCampaignShortDescription] = useState(me.company_short_description || '');
  const [customCampaignLink, setCustomCampaignLink] = useState(me.company_link || '');

  // for preview
  const [followerCount, setFollowerCount] = useState('0');
  const [showPreview, setShowPreview] = useState(false);

  const close = () => toggleCampaignModal(false);

  // upload logo to "company logo" s3 bucket
  // either a company logo or custom campaign logo
  const uploadLogo = async (localImage) => {
    const { thumb } = await uploadCompanyLogoS3(localImage);
    return thumb;
  };

  const choosePhoto = async (isCustomCampaignLogo) => {
    try {
      const pickerRes = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
      });

      console.log("CreateCampaignModel - photo imagepicker result", pickerRes);
      if (pickerRes.canceled || !pickerRes.assets || !pickerRes.assets.length) return;
      const asset = pickerRes.assets[0]; // { uri, width, height, type, duration }

      if (isCustomCampaignLogo) {
        setCustomCampaignImageLocal(asset);
      } else {
        setCompanyLogoLocal(asset);
      }
    } catch (e) {
      console.log("CreateCampaignModel - photo imagepicker error", e);
    }
  };

  const submit = async () => {
    setLoading(true);

    let companyLogo = null;
    let customCampaignImage = null;
    if (companyLogoLocal) companyLogo = await uploadLogo(companyLogoLocal);
    if (customCampaignImageLocal) customCampaignImage = await uploadLogo(customCampaignImageLocal);

    const res = await apiCreateNewDeal({ // be careful: this is a dictionary, key names matter!
      campaignType,
      customCampaignName,
      customCampaignImage,
      customCampaignShortDescription,
      customCampaignLink,
      campaignBudget,
      creatorNotes,
      brandDescription,
      videoSpecs,
      postSpecs,
      linkInBio,
      linkValue,
      reviewVidFirst,
      mailingProduct,
      merchDetails,
      noMerchDetails,
      merchMSRP,
      basePrice,
      pricePerInstall,
      appLink,
      creatorNeg,
      installReqs,
      companyLogo,
      companyShortDescription,
      companyLink,
    });
    if (res.status === 'success') {
      await updateDealsInfo();
      await updateMe();
      setLoading(false);
      close();
      if (campaignType === 'Link') {
        setInstallDelayModal(true);
      }
    } else {
      console.log("CreateCampaignModel - Create deal error", res.message);
      alert(`${res.message}`);
      setLoading(false);
    }
  };

  const renderInstallDelayModal = () => {
    return (
      <Modal visible={installDelayModal} close={() => setInstallDelayModal(false)}>
        <View style={{ height: 200, width: 350, backgroundColor: 'white', paddingHorizontal: 20}}>
          <Touchable onPress={() => setInstallDelayModal(false)} style={{ position: 'absolute', top: 20, right: 20 }}>
            <Text style={styles.descriptionText}>Close</Text>
          </Touchable>
          <View style={{ width: '100%', alignItems: 'center', marginTop: 40}}>
            <Text style={{ ...maybeRounded('500'), fontSize: 16, textAlign: 'center' }}>👋 Install Campaigns are presently {'\n'}in open beta</Text>
          </View>
          <View style={{ height: 20}} />
          <Text style={{ ...maybeRounded('400'), fontSize: 16, textAlign: 'center' }}>They may take a little longer to approve. For faster turnaround, choose a UGC or post campaign. ⏱</Text>
        </View>
      </Modal>
    )
  }

  let value = (Number.parseFloat(merchMSRP)||0) + (Number.parseFloat(basePrice)||0) + (campaignType === 'Post' ? Math.min(1000, ((Number.parseInt(followerCount)/100000)|0)*50) : 0);

  return (
    <Fragment>
      <Modal visible={visible} close={close}>
        <View style={{ flex: 1, flexDirection: "row", width:"600px" }}>
          <View style={{ flex: 1, borderRightWidth: 1, borderRightColor: "#ebebeb", width:"100%" }}>
            <View
              style={{
                alignItems: "center",
                elevation: 5,
                width: "100%",
                paddingVertical: 20,
                boxShadow: '0px 4px 16px 0px #00000014'
              }}
            >
              <Text style={styles.headerOne}>New Campaign</Text>
            </View>
            <ScrollView style={{ flex: 1, width: "100%", padding: 20, maxHeight: 900 }}>
              <View style={{ flexDirection: 'column', gap: 16 }}>
                <CampaignType campaignType={campaignType} setCampaignType={setCampaignType} />
                <View style={{ flexDirection: 'column', gap: 16 }}>
                  <Text style={{ color: '#3B51F5', ...maybeRounded('500'), fontSize: 14 }}>
                    <a style={{ textDecorationLine: "none" }} target={"_blank"} href={"https://www.notion.so/lukeheine/Swipehouse-Example-Videos-f7843d8040cf4154bc17cf09cdf0bb5b"}>
                      Here are some example vids 💫
                    </a>
                  </Text>

                  <View style={{ flexDirection: 'column', gap: 16 }}>
                    <CampaignBudget campaignBudget={campaignBudget} setCampaignBudget={setCampaignBudget} />

                    {campaignType !== "Link" && <BasePrice basePrice={basePrice} setBasePrice={setBasePrice} campaignType={campaignType} />}
                    {campaignType === "Link" && <PricePerInstall pricePerInstall={pricePerInstall} setPricePerInstall={setPricePerInstall} />}
                    {campaignType === "Link" && <AppLink appLink={appLink} setAppLink={setAppLink} />}

                    {campaignType === "Post" && (
                      <View style={{ flexDirection: 'column', gap: 4 }}>
                        <Text style={styles.headerTwo}>Notes on this funding amount</Text>
                        {/*<Text style={styles.descriptionText}>This is the base price of your video. For post campaigns, we add a follower fee of $50 per 100k followers with a cap of $1000. This goes directly to the creator, with a 25% fee on top for Duet.</Text>*/}
                        <Text style={styles.descriptionText}>This is the base price of your video. For post campaigns, we add a follower fee of $2 per 20k followers.</Text>
                      </View>
                    )}

                    {campaignType !== "Link" && <MerchMSRP merchMSRP={merchMSRP} setMerchMSRP={setMerchMSRP} />}
                    {campaignType !== "Link" && (
                      <Fragment>
                        <MailingProduct mailingProduct={mailingProduct} setMailingProduct={setMailingProduct} />
                        {!!mailingProduct && (
                          <View style={{ flexDirection: "row"}}>
                            <View style={{ height: "95%", marginTop: 4, width: 2, backgroundColor: "#c3cacf", marginLeft: 4, marginRight: 16 }} />
                            <View>
                              <MerchDetails merchDetails={merchDetails} setMerchDetails={setMerchDetails} />
                              {/*<NoMerchDetails noMerchDetails={noMerchDetails} setNoMerchDetails={setNoMerchDetails}/>*/}
                            </View>
                          </View>
                        )}
                        {/*{!!mailingProduct && <MerchDetails merchDetails={merchDetails} setMerchDetails={setMerchDetails}/>}*/}
                        {/*{!!mailingProduct && <NoMerchDetails noMerchDetails={noMerchDetails} setNoMerchDetails={setNoMerchDetails}/>}*/}
                        {/*{!!mailingProduct && <MerchMSRP merchMSRP={merchMSRP} setMerchMSRP={setMerchMSRP}/>}*/}
                      </Fragment>
                    )}

                    {campaignType !== "Link" && <CreatorNegotiation creatorNeg={creatorNeg} setCreatorNeg={setCreatorNeg} />}

                    <View style={{ flexDirection: 'column', gap: 16}}>
                      <Text style={styles.jobHeaderText}>Create Your Creator Job Post</Text>

                      <CustomCampaignNameAndImage me={me} customCampaignName={customCampaignName} setCustomCampaignName={setCustomCampaignName} customCampaignImageLocal={customCampaignImageLocal} choosePhoto={choosePhoto} setCustomCampaignShortDescription={setCustomCampaignShortDescription} customCampaignShortDescription={customCampaignShortDescription} customCampaignLink={customCampaignLink} setCustomCampaignLink={setCustomCampaignLink} />

                      <BrandDescription brandDescription={brandDescription} setBrandDescription={setBrandDescription} />

                      {campaignType === "Link" && <InstallReqs installReqs={installReqs} setInstallReqs={setInstallReqs} />}

                      {campaignType !== "Link" && <VideoSpecifications videoSpecs={videoSpecs} setVideoSpecs={setVideoSpecs} />}

                      {campaignType === "Post" && <PostSpecifications postSpecs={postSpecs} setPostSpecs={setPostSpecs} />}
                      {campaignType === "Post" && <LinkInBio linkInBio={linkInBio} setLinkInBio={setLinkInBio} />}
                      {campaignType === "Post" && linkInBio && <BioLink linkValue={linkValue} setLinkValue={setLinkValue} />}
                      {campaignType === "Post" && <ReviewVid reviewVidFirst={reviewVidFirst} setReviewVidFirst={setReviewVidFirst} />}

                      <View style={styles.inputBox}>
                        <Text style={styles.headerTwo}>Any notes to us on specific creators you would want</Text>
                        <TextInput style={[styles.inputMultiline, { height: 70 }]} onChangeText={setCreatorNotes} value={creatorNotes} multiline={true} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
            <View style={{ width: "100%", alignItems: "center", padding: 20 }}>
                <Touchable onPress={submit} style={{ 
                  backgroundColor: "#3B51F5", 
                  borderRadius: 99,
                  paddingHorizontal: 32,
                  paddingVertical: 18, 
                  alignItems: "center", 
                  justifyContent: "center"
                }}>
                  {loading ? <ActivityIndicator color={"#fff"} /> : <Text style={{ color: "#fff", ...maybeRounded("700"), fontSize: 16 }}>Submit for Review</Text>}
                </Touchable>
              </View>
          </View>

          {showPreview && (
            <View style={{ width: 400 }}>
              <Text style={{ marginTop: 20, marginLeft: 20, marginBottom: 20, fontSize: 18, fontWeight: "500" }}>Preview</Text>

              {/*{campaignType === 'Post' &&*/}
              {/*  <View style={{ marginHorizontal: 20, marginBottom: 20 }}>*/}
              {/*    <Text style={styles.headerTwo}>As seen by someone with this many followers:</Text>*/}
              {/*    <TextInput*/}
              {/*      style={styles.input}*/}
              {/*      onChangeText={setFollowerCount}*/}
              {/*      value={followerCount}*/}
              {/*    />*/}
              {/*  </View>*/}
              {/*}*/}

              {/* DealItem */}
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ backgroundColor: "#fff", width: 54, height: 54, borderRadius: 18, marginRight: 16, marginLeft: 20, borderColor: "#000", borderWidth: 1, justifyContent: "center", alignItems: "center" }}>
                  <Text style={{ fontSize: 20, fontWeight: "700" }}>{(me.company_name || "Aa").toUpperCase().substr(0, 2)}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={{ flexDirection: "row", alignItems: "center", paddingRight: 20 }}>
                    <Text rows={1} style={{ flex: 1, ...maybeRounded("600"), fontSize: 19, color: "#262626" }}>
                      {me.company_name}
                    </Text>
                    <View style={{ width: 8 }} />
                    <Text rows={1} style={{ ...maybeRounded("400"), fontSize: 16, color: "#666", opacity: 1, textAlign: "right" }}>
                      {campaignType === "UGC" ? "Video only" : campaignType === "Post" ? "Video + Post" : campaignType === "Link" ? "Link" : "Campaign Type"}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center", paddingRight: 20 }}>
                    <Text rows={1} style={{ flex: 1, ...maybeRounded("400"), fontSize: 16, color: "#666" }}>
                      {companyShortDescription || "Short Description"}
                    </Text>
                    <View style={{ width: 8 }} />
                    <Text rows={1} style={{ ...maybeRounded("400"), fontSize: 16, color: "#666", opacity: 1, textAlign: "right" }}>
                      ${value.toFixed(0)} Value
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ width: "100%", height: 1, backgroundColor: "#f0f0f0", marginTop: 10, marginBottom: 8 }} />
              <ScrollView>
                <MockMessage isMe isFirst isLast text={`Hey, I’d love to learn more!`} />
                <MockMessage isFirst text={`Thank you for your interest in the ${me.company_name} Deal! Here’s more information:`} />
                <MockMessage intro={"Who we are: "} text={brandDescription || "Your brand description"} />
                <MockMessage intro={"Our website: "} text={me.company_link} />
                <MockMessage intro={"What we want: "} text={campaignType === "UGC" ? "Create a tiktok video for our own advertising. No post required." : campaignType === "Post" ? "Create a tiktok video for our advertising + also post it to your profile" : campaignType === "Link" ? "Share a link to our product and get paid per person who installs it" : "Campaign Type"} />
                <MockMessage intro={"Our offer: "} text={`$${(basePrice | 0).toFixed(0)}${merchMSRP ? ` + free ${me.company_name} product up to $${(merchMSRP | 0).toFixed(0)} in value` : ""}`} />
                <MockMessage text={`Some more information you should know...`} />
                <MockMessage intro={`Video specifications: `} text={videoSpecs || "Your video specs"} />
                {campaignType === "Post" && <MockMessage intro={`Post specifications: `} text={postSpecs || "Your post specs"} />}
                {campaignType === "Post" && linkInBio && <MockMessage intro={`Link in bio required. `} text={`You'll have to include a link to our product for at least four days after posting.`} />}
                {!!mailingProduct && !!merchDetails && <MockMessage intro={`Products you *can* choose from our website: `} text={merchDetails} />}
                {/*{!!mailingProduct && !!noMerchDetails && <MockMessage intro={`Products you *can't* choose from our website: `} text={noMerchDetails}/>}*/}
                <MockMessage isLast intro={"Any questions? "} text={"Just write us a message and we'll get back to you as soon as we can."} />
                {/*<MockMessage isFirst isLast text={"If all that sounds good to you, just fill out this application and we’ll get back to you."}/>*/}
              </ScrollView>
            </View>
          )}
        </View>
      </Modal>
      {renderInstallDelayModal()}
    </Fragment>
  );
}


const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#E9EAED',
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 20,
    paddingVertical: 16,
    ...maybeRounded('500'),
    fontSize: 14,
    lineHeight: 20,
    color: '#000000',
  },
  inputMultiline: {
    height: 170,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#E9EAED',
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 20,
    paddingVertical: 16,
    ...maybeRounded('500'),
    fontSize: 14,
    lineHeight: 20,
    color: '#000000',
    textAlign: 'flex-start',
  },
  inputText: {
    ...maybeRounded('500'),
    fontSize: 16,
    color: '#8B9399',
  },
  checkbox: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#999999',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxRow: {
    flexDirection: 'row',
    paddingHorizontal: 4,
    gap: 6
  },
  checkboxText: {
    fontSize: 12,
    color: '#767E93',
    ...maybeRounded('500')
  },
  descriptionText: {
    fontSize: 14,
    color: '#777E93',
    ...maybeRounded('500'),
  },
  headerOne: {
    ...maybeRounded('600'),
    fontSize: 20,
    color: '#0F1D40',
  },
  headerTwo: {
    ...maybeRounded('500'),
    fontSize: 14,
    color: '#0F1D40'
  },
  jobHeaderText: {
    ...maybeRounded('600'),
    fontSize: 16,
    color: '#0F1D40',
  },
  postPreviewContainer: {
    height: 100,
    backgroundColor: '#e7e7e7',
    borderRadius: 10,
  },
  checkBoxIcon:{
    height: 12,
    width: 12,
  },

  card: {
    flex: 1,
    height: 170,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#888',
    paddingTop: 22,
    paddingHorizontal: 8,
    shadowColor: '#333',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    alignItems:"center",
    justifyContent:"center"
  },
  selectedCard: {
    backgroundColor: '#3B51F5',
    borderColor: '#284bff',
    shadowColor: '#284bff',
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  cardTitleSelected: {
    textAlign: 'center',
    color:"white",
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 26,
  },
  cardSubtitleSelected: {
    textAlign: 'center',
    fontWeight: '400',
    fontSize: 14,
    color: "white",
    marginTop:10,
  },
  cardTitle: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 26,
    color: "black"
  },
  cardSubtitle: {
    textAlign: 'center',
    fontWeight: '400',
    fontSize: 14,
    color: '#444',
    marginTop:10,
  },
  headerBox: {
    backgroundColor: '#2C2C2C', // Dark background color for header
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Elevation effect
    padding: '0 20px',
  },
  iconSelected: {
    filter:   'invert(10%) brightness(1000%)',height:24, width:24
  },
  iconSVG: {
    filter:   'invert(20%) brightness(100%) grayscale(100%)',height:24, width:24
  },
  inputBox: { 
    flexDirection: 'column', 
    gap: 8
  }
});
