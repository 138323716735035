import React, { Fragment, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Navbar from "../../components/Navbar";
import { ActivityIndicator, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { loginAsUser, logout } from "../../redux/reducers";
import { VIEW_ONLY_ADMINS, FULL_ADMINS } from "../../util/adminIds";
import CampaignReview from "./CampaignReview";

function NotAdmin() {
  return (
    <View>
      <Text>Not admin</Text>
    </View>
  );
}

export default function AdminScreen() {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.userId);
  const adminUserId = useSelector(state => state.adminUserId);
  let isAdmin = FULL_ADMINS.includes(userId) || VIEW_ONLY_ADMINS.includes(userId);

  useEffect(() => {
    if (!isAdmin && adminUserId) {
      dispatch(logout());
    }
  }, []);

  return (
    <View style={{ backgroundColor: '#F8F9FA', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
      <Navbar logoutBar/>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ alignItems: 'center', justifyContent: 'center', paddingTop: 60, paddingBottom: 40, paddingHorizontal: 30 }}
      >
        {!isAdmin && <NotAdmin/>}
        {isAdmin && <CampaignReview/>}
      </ScrollView>
    </View>
  );
}

