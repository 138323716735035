const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthAbbr = date => (months[date.getMonth()]);

const zeroPadNum = (n, num) => (
  `${new Array(n - `${num}`.length + 1).join('0')}${num}`
);

const pastMinute = (timestamp) => Math.abs(new Date() - new Date(timestamp)) <= 1000 * 60;
const pastHour = (timestamp) => Math.abs(new Date() - new Date(timestamp)) <= 1000 * 60 * 60;
const pastHalfDay = (timestamp) => Math.abs(new Date() - new Date(timestamp)) <= 1000 * 60 * 60 * 12;
const pastDay = (timestamp) => Math.abs(new Date() - new Date(timestamp)) <= 1000 * 60 * 60 * 24;
const pastWeek = (timestamp) => Math.abs(new Date() - new Date(timestamp)) <= 1000 * 60 * 60 * 24 * 7;
const pastMonth = (timestamp) => Math.abs(new Date() - new Date(timestamp)) <= 1000 * 60 * 60 * 24 * 30;
const pastYear = (timestamp) => Math.abs(new Date() - new Date(timestamp)) <= 1000 * 60 * 60 * 24 * 365;

// https://stackoverflow.com/a/30282090/3273806
const addDays = (date, days) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + days,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
};
const subDays = (date, days) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - days,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
};

// convert "2018-09-07T03:08:23.950608" to "3:08am"
const timestampToTime = (timestamp) => {
  const time = new Date(timestamp);
  let hours = time.getHours();
  const minutes = time.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = (hours + 11) % 12 + 1;
  return `${hours}:${zeroPadNum(2, minutes)}${ampm}`;
};

// convert "2018-09-07T03:08:23.950608" to
// "3:08am" if past 12 hours,
// "Fri" if past week,
// "Sep 7" if past year,
// "2018 Sep 7" if older
const timestampToDateOrTime = (timestamp) => {
  const time = new Date(timestamp);

  if (pastHalfDay(timestamp)) {
    return timestampToTime(timestamp);
  }

  if (pastWeek(timestamp)) {
    return days[time.getDay()];
  }

  const date = time.getDate();
  const month = monthAbbr(time);

  if (pastYear(timestamp)) {
    return `${month} ${date}`;
  }

  return `${time.getFullYear()} ${month} ${date}`;
};

// convert "2018-09-07T03:08:23.950608" to
// "3:08am" if past 12 hours,
// "Fri 3:08am" if past week,
// "Sep 7 3:08am" if past year,
// "2018 Sep 7 3:08am" if older
const timestampToTimeAndMaybeDate = (timestamp) => {
  const time = new Date(timestamp);

  if (pastHalfDay(timestamp)) {
    return timestampToTime(timestamp);
  }

  if (pastWeek(timestamp)) {
    return days[time.getDay()] + ' ' + timestampToTime(timestamp);
  }

  const date = time.getDate();
  const month = monthAbbr(time);

  if (pastYear(timestamp)) {
    return `${month} ${date} ${timestampToTime(timestamp)}`;
  }

  return `${time.getFullYear()} ${month} ${date} ${timestampToTime(timestamp)}`;
};

const MS_IN_MIN = 1000*60;
const MS_IN_HOUR = MS_IN_MIN*60;
const MS_IN_DAY = MS_IN_HOUR*24;
const MS_IN_WEEK = MS_IN_DAY*7;
const MS_IN_MONTH = MS_IN_DAY*30;
const MS_IN_YEAR = MS_IN_DAY*365;

const msBetweenDates = (d1, d2) => Math.abs(d1.getTime() - d2.getTime());
const minsBetweenTimes = (d1, d2) => Math.abs(d1 - d2) / MS_IN_MIN;
const hoursBetweenDates = (d1, d2) => msBetweenDates(d1, d2) / MS_IN_HOUR;
const hoursBetweenTimestamps = (t1, t2) => hoursBetweenDates(new Date(t1), new Date(t2));
const daysBetweenDates = (d1, d2) => msBetweenDates(d1, d2) / MS_IN_DAY;
const weeksBetweenDates = (d1, d2) => msBetweenDates(d1, d2) / MS_IN_WEEK;
const monthsBetweenDates = (d1, d2) => msBetweenDates(d1, d2) / MS_IN_MONTH;
const yearsBetweenDates = (d1, d2) => msBetweenDates(d1, d2) / MS_IN_YEAR;

const friendlyTimestamp = (timestamp, ago=false) => {
  if (!timestamp) return null;

  if (pastMinute(timestamp)) return 'now';
  if (pastHour(timestamp)) return `${minsBetweenTimes(new Date(timestamp), new Date()) | 0}m${ago ? ' ago' : ''}`;
  if (pastDay(timestamp)) return `${hoursBetweenDates(new Date(timestamp), new Date()) | 0}h${ago ? ' ago' : ''}`;
  if (pastWeek(timestamp)) return `${daysBetweenDates(new Date(timestamp), new Date()) | 0}d${ago ? ' ago' : ''}`;
  if (pastMonth(timestamp)) return `${weeksBetweenDates(new Date(timestamp), new Date()) | 0}w${ago ? ' ago' : ''}`;
  if (pastYear(timestamp)) return `${weeksBetweenDates(new Date(timestamp), new Date()) | 0}w${ago ? ' ago' : ''}`;
  return `${yearsBetweenDates(new Date(timestamp), new Date()) | 0}y`;
};

// takes in MM/DD/YYYY, MM-DD-YYYY, MM/DD/YY, and MM-DD-YY
// for years 2000-2050
const parseDate = (str) => {
  // convert str to array [month: int, day: int, year: int]
  str = str.trim();
  if (str.includes('/')) str = str.split('/');
  else if (str.includes('-')) str = str.split('-');
  let split = str.map(x => parseInt(x, 10));
  if (split.length !== 3) return null;

  let [m, d, y] = split;

  // restrict to years 2000-2050
  if (y < 2000 || y > 2050) {
    // also allow people to enter 01-49 as years
    if (y > 0 && y < 50) y += 2000;
    else return null;
  }

  // create Date object from the parsed date
  // then make sure its month/day/year equal the parsed ones
  // e.g. to keep people from putting "March 32, 2018"
  const date = new Date(y, m - 1, d);
  const [m2, d2, y2] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
  if (m2 === m && d2 === d && y2 === y) {
    // valid!
    return date;
  }

  return null;
};

// converts Date object to "MM/DD/YYYY" format
const dateToMMDDYYYYString = (date) => {
  let date_mm = date.getMonth() + 1;
  let date_dd = date.getDate();
  let date_yy = date.getFullYear();
  return `${date_mm}/${date_dd}/${date_yy}`;
};

export {
  monthAbbr,
  zeroPadNum,
  pastMinute,
  pastHour,
  pastHalfDay,
  pastDay,
  pastWeek,
  pastMonth,
  pastYear,
  addDays,
  subDays,
  timestampToTime,
  timestampToTimeAndMaybeDate,
  timestampToDateOrTime,
  hoursBetweenDates,
  hoursBetweenTimestamps,
  minsBetweenTimes,
  daysBetweenDates,
  weeksBetweenDates,
  monthsBetweenDates,
  yearsBetweenDates,
  friendlyTimestamp,
  parseDate,
  dateToMMDDYYYYString,
};