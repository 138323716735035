import * as React from "react";
import Svg, { Path } from "react-native-svg";

function UploadIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M18.753 20.73h-5.15v-5.203h1.702c.432 0 .687-.49.432-.843l-3.31-4.58a.528.528 0 00-.859 0l-3.31 4.58a.531.531 0 00.432.844h1.702v5.203H4.624C2.05 20.588 0 18.18 0 15.57c0-1.8.976-3.368 2.423-4.217a3.275 3.275 0 01-.202-1.142 3.321 3.321 0 014.463-3.124 6.658 6.658 0 016.027-3.82 6.669 6.669 0 016.62 6.017C21.985 9.74 24 12.198 24 14.978c0 2.972-2.315 5.547-5.247 5.753z"
        fill="#868D9F"
      />
    </Svg>
  )
};

export default UploadIcon;
