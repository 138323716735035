import { legacy_createStore, applyMiddleware } from "redux";
// import ExpoFileSystemStorage from "redux-persist-expo-filesystem"
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { thunk } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'

// notes on RTK + redux-persist:
// - rtk docs - "migrating to modern redux - store setup with configurestore - Detailed Example: Custom Store Setup with Persistence and Middleware" - https://redux-toolkit.js.org/usage/migrating-to-modern-redux#store-setup-with-configurestore
//   - good example hidden below a fold - do ctrl-f "persist"
// - rtk docs - "usage guide - use with redux-persist" - https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
//   - solid example. ctrl-f "persist" also helpful here

import rootReducer from "./reducers";

const persistConfig = {
  key: 'root',
  // storage: ExpoFileSystemStorage,
  storage,
  // notes on stateReconciler:
  // - autoMergeLevel1 is the default
  // - need either `autoMergeLevel1` or `autoMergeLevel2` for RTK Query (which we don't currently use)
  //   - rtk docs - "persistence and rehydration" - https://redux-toolkit.js.org/rtk-query/usage/persistence-and-rehydration
  //   - also need to add some kind of isHydrateAction check to the reducer? ie `action.type === REHYDRATE`, return something based on `action.payload`
  // - redux-persist docs - https://github.com/rt2zz/redux-persist#state-reconciler
  // // stateReconciler: autoMergeLevel1,
  whitelist: [
    'token',
    'userId',
    'adminToken',
    'adminUserId',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = legacy_createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store);
export { store, persistor }