import * as React from "react";
import Svg, { Path } from "react-native-svg";

function PlusIcon(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9.167 9.167V5.834h1.666v3.333h3.334v1.667h-3.334v3.333H9.167v-3.333H5.833V9.167h3.334zM10 18.334a8.333 8.333 0 110-16.667 8.333 8.333 0 010 16.667zm0-1.667a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
        fill="#fff"
      />
    </Svg>
  )
};

export default PlusIcon;
