import React, { Component, ReactNode } from 'react';
import { TouchableHighlight, TouchableOpacity, TouchableWithoutFeedback, View, ViewStyle, Insets, StyleProp } from 'react-native';
// import * as Haptics from "expo-haptics";

interface TouchableProps {
  onPress?: () => void,
  onLongPress?: () => void,
  // track?: () => void,
  noFeedback?: boolean,
  highlight?: boolean,
  // haptic?: boolean, // mobile-only
  hs?: number, // shorthand for square hitslop
  activeOpacity?: number,
  style?: ViewStyle,
  // pointerEvents?: ViewStyle['pointerEvents'],
  pointerEvents?: View['props']['pointerEvents'],
  hitSlop?: Insets,
  children?: ReactNode,
}

export default class Touchable extends Component<TouchableProps> {
  static defaultProps = {
    onPress: null,
    onLongPress: null,
    noFeedback: false,
  };

  onAnyPress = (pressFunction?: () => void, long?: boolean) => {
    // if (this.props.haptic) Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    if (!pressFunction) return;
    pressFunction();
  };

  onPress = () => this.onAnyPress(this.props.onPress);
  onLongPress = () => this.onAnyPress(this.props.onLongPress, true);

  render() {
    const { noFeedback, highlight, children, style, pointerEvents, activeOpacity, hs, hitSlop, ...props } = this.props;
    const feedback = !noFeedback;
    const Button = feedback ? (highlight ? TouchableHighlight : TouchableOpacity) : TouchableWithoutFeedback;
    const buttonStyle = feedback ? style : {};
    const ao = activeOpacity === undefined ? 0.8 : activeOpacity;
    const computedHitSlop = hitSlop ? hitSlop : (hs ? { top: hs, left: hs, bottom: hs, right: hs } : undefined);
    // const styleWithPointerEvents: StyleProp<ViewStyle> = pointerEvents ? [style, pointerEvents] : style;
    // If touchable w/o feedback, must have only one child, wrap children in view
    // and give style to view
    return (
      <Button
        {...props}
        hitSlop={computedHitSlop}
        style={buttonStyle}
        onPress={this.onPress}
        onLongPress={this.onLongPress}
        // @ts-ignore // only TouchableOpacity supports activeOpacity
        activeOpacity={ao}
      >
        {/*{feedback && !highlight ? children : <View style={styleWithPointerEvents}>{children}</View>}*/}
        {feedback && !highlight ? children : <View pointerEvents={pointerEvents}>{children}</View>}
      </Button>
    );
  }
}
