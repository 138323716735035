// import Sentry from "../modules/Sentry/Sentry";
import { Image } from "react-native";
import * as ImageManipulator from "expo-image-manipulator";

// import * as VideoThumbnails from "expo-video-thumbnails";
// import * as FileSystem from "expo-file-system";

async function getBlobSize(uri) {
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      resolve(xhr.response);
    };
    xhr.onerror = function(e) {
      console.log(e);
      reject(new TypeError('Network request failed'));
      // Sentry.captureException(e);
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });
  let size = blob.size;
  // blob.close(); // todo: blob.close is not a function
  return size;
}

function uploadToS3(fileUrl, signedUrl, onProgress) {
  // todo: next time we update expo sdk, use background upload
  // FileSystem.uploadAsync(signedUrl, fileUrl, { httpMethod: 'PUT' });
  // or https://github.com/aroth/react-native-uploader which has progress
  return new Promise(async (resolve, reject) => {

    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        resolve(xhr.response);
      };
      xhr.onerror = function(e) {
        console.log(e);
        reject(new TypeError('Network request failed'));
        // Sentry.captureException(e);
      };
      xhr.responseType = 'blob';
      xhr.open('GET', fileUrl, true);
      xhr.send(null);
    });

    console.log("s3 - uploadToS3 - Uploading!", JSON.stringify({ fileUrl, signedUrl, blob }));

    // const file = new File([blob], `filename.mov`);

    // upload without tracking progress
    // await fetch(signedUrl, { method: 'PUT', body: blob });

    const xhr2 = new XMLHttpRequest();
    xhr2.onload = () => {
      console.log(`s3 - uploadToS3 - The upload is completed: ${xhr2.status} ${xhr2.response}`);
      resolve();
      // blob.close();
    };
    xhr2.onerror = (e) => {
      console.log(`s3 - uploadToS3 - Upload failed`, e);
      reject();
      // blob.close();
    };
    xhr2.onabort = (e) => {
      console.log(`s3 - uploadToS3 - Upload cancelled`, e);
      reject();
      // blob.close();
    };
    xhr2.onprogress = (e) => { // note: doesn't seem to get called
      console.log(`s3 - uploadToS3 - Uploaded ${e.loaded} of ${e.total} bytes`, e);
    };
    xhr2.upload.onload = () => { // note: doesn't seem to get called
      console.log(`s3 - uploadToS3 - The upload is completed: ${xhr2.status} ${xhr2.response}`);
    };
    xhr2.upload.onerror = (e) => {
      console.log(`s3 - uploadToS3 - Upload failed`, e);
    };
    xhr2.upload.onabort = (e) => {
      console.log(`s3 - uploadToS3 - Upload cancelled`, e);
    };
    xhr2.upload.onprogress = (e) => {
      if (onProgress) onProgress(e.loaded);
      console.log(`s3 - uploadToS3 - Uploaded ${e.loaded} of ${e.total} bytes`, e);
    };
    xhr2.open('PUT', signedUrl);
    xhr2.send(blob);
  });
}

const getImageDims = (uri) => {
  return new Promise((resolve, reject) => {
    Image.getSize(uri, (width, height) => {
      resolve([width, height]);
    }, () => reject());
  });
};

const resizePic = async ({ uri, width, height }, size) => {
  if (!width || !height) {
    [width, height] = await getImageDims(uri);
  }

  let resize = { width: size };
  if (height > width) resize = { height: size };

  const res = await ImageManipulator.manipulateAsync(uri, [{ resize }], {
    compress: 0.5,
    format: ImageManipulator.SaveFormat.JPEG,
  });

  return res.uri;
};

export const uploadCompanyLogoS3 = async (image, onProgress) => {
  // get upload URLs
  const { apiGetLogoUploadUrls } = require("../api/api");
  const res = await apiGetLogoUploadUrls();
  if (res.status !== 'success') {
    throw new Error(res.message);
  }
  const {
    presignedUrl, targetUrl,
  } = res.data;

  const thumbUri = await resizePic(image, 800);

  // upload progress indicator
  const thumbUriSize = await getBlobSize(thumbUri);
  const onThumb100Progress = loaded => onProgress && onProgress(loaded / thumbUriSize);
  // const onThumb100Progress = loaded => null;

  await uploadToS3(thumbUri, presignedUrl, onThumb100Progress);

  return {
    thumb: targetUrl,
  };
};
