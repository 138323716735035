import { ActivityIndicator, Image, StyleSheet, Text, TextInput, View } from "react-native";
import { formatFollowers } from "../../util/formatFollowers";
import Touchable from "../../components/Touchable";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { maybeRounded } from "../../assets/assets";
import { Video, ResizeMode } from "expo-av";
import Linkify from "react-linkify";
import { calculatePrice } from "../../util/calculateSubmissionPrice";
import Modal from "../../components/Modal";
import { sleep } from "../../util/sleep";
import { apiAcceptPriceProposal, apiProposePrice } from "../../api/api";
import { updateDealsInfo } from "../../redux/asyncActions";

const tiktokIcon = require('../../assets/images/icons8-tiktok.png');
const checkIcon = require("../../assets/images/icons8-checkmark.png");

const statusToStr = {
  applied: "Applied - need to approve or reject",
  rejected: "Rejected",
  accepted: "In progress",
  // accepted: "Accepted - in progress, may require attention",
  // accepted: "Accepted - in progress",
  // accepted: "Accepted - waiting for their video. Send over anything they need if applicable",
  reviewing_video_or_post: "Video submitted, needs your review",
  waiting_for_post: "Waiting for them to post",
  submission_done_invoice_not_sent: "Complete. Waiting for your payment.",
  submission_done_invoice_sent: "Complete. Waiting for your payment.",
  paid: "Completed & Paid",
};

const AutoLinkText = ({ children, ...props }) => (
  <Text {...props}>
    <Linkify
      children={children}
      componentDecorator={(href, text, key) =>
        <a href={href} key={key} target={"_blank"} style={{ color: '#2364C6' }}>
          {text}
        </a>
      }
    />
  </Text>
);

function UserPriceProposalModal({ submission, deal }) {
  const creatorProposedPrice = submission.user_proposed_base_price ? (submission.user_proposed_base_price + submission.user_proposed_price_our_add_usd) : null;

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const accept = async () => {
    setLoading(true);
    const res = await apiAcceptPriceProposal(deal.id, submission.id);
    if (res.status !== 'success') {
      setLoading(false);
      alert(`Error: ${res.message}`);
      return;
    }
    await updateDealsInfo();
    setLoading(false);
    closeModal();
  };

  let canChangePrice = submission.status !== 'paid';

  if (!submission.user_proposed_base_price || !canChangePrice) return null;

  return (
    <View style={{ marginTop: 8 }}>
      <Text style={styles.text}>
        <Text style={{ fontWeight: '600' }}>Creator proposed a price:</Text> ${creatorProposedPrice.toFixed(2)} ({(submission.user_proposed_base_price / creatorProposedPrice * 100).toFixed(0)}% goes to creator).
        {' '}
        <Text onPress={showModal} style={{ color: '#1c41a3', textDecorationLine: 'underline' }}>Approve</Text>
      </Text>
      <Modal
        visible={modalVisible}
        close={closeModal}
        style={{ width: '100%', height: '100%', borderWidth: 0, borderColor: 'none', backgroundColor: 'rgba(0,0,0,.3)', alignItems: 'center', justifyContent: 'center' }}
      >
        <View
          style={{
            width: 300,
            // height: 150,
            backgroundColor: 'white',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            // padding: 5,
            paddingVertical: 20,
            paddingHorizontal: 14,
          }}
        >
          <View style={{ height: 12 }}/>
          <Text style={{ fontWeight: '600', fontSize: 14, color: '#16191C' }}>
            {submission.name} | Price Update
          </Text>
          <View style={{ height: 20 }}/>
          <Text style={{ fontWeight: '400', fontSize: 14, color: '#16191C', textAlign: 'center' }}>
            Creator proposed a price update: ${creatorProposedPrice.toFixed(2)}. Would you like to accept it?
          </Text>
          <View style={{ height: 20 }}/>
          <Touchable disabled={loading} onPress={accept} style={{ height: 35, width: '100%', backgroundColor: '#3E8BFF', alignItems: 'center', justifyContent: 'center', borderRadius: 3 }}>
            {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ ...maybeRounded('700'), color: '#fff'}}>Accept</Text>}
          </Touchable>
          <View style={{ height: 20 }}/>
          <Text onPress={closeModal} style={{ color: '#444' }}>Cancel</Text>
        </View>
      </Modal>
    </View>
  );
}

function CompanyPriceProposalModal({ submission, deal }) {
  const companyProposedPrice = submission.company_proposed_base_price ? (submission.company_proposed_base_price + submission.company_proposed_price_our_add_usd) : null;

  const [price, setPrice] = useState('' + (companyProposedPrice || ''));
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const submit = async () => {
    setLoading(true);
    const res = await apiProposePrice(deal.id, submission.id, price);
    if (res.status !== 'success') {
      setLoading(false);
      alert(`Error: ${res.message}`);
      return;
    }
    await updateDealsInfo();
    setLoading(false);
    closeModal();
  };

  const isDiff = price !== ('' + (companyProposedPrice || ''));
  let canChangePrice = submission.status !== 'paid';

  if (!canChangePrice) return null;

  return (
    <View style={{ marginTop: 8, marginBottom: 8 }}>
      <Text style={styles.text}>
        {!!companyProposedPrice &&
          <Text>
            <Text style={{ fontWeight: '600' }}>You proposed a price: </Text> ${companyProposedPrice.toFixed(2)} ({(submission.company_proposed_base_price / companyProposedPrice * 100).toFixed(0)}% goes to creator).
            {' '}
          </Text>
        }
        <Text onPress={showModal} style={{ color: '#444', textDecorationLine: 'underline' }}>Propose a new price</Text>
      </Text>
      <Modal
        visible={modalVisible}
        close={closeModal}
        style={{ width: '100%', height: '100%', borderWidth: 0, borderColor: 'none', backgroundColor: 'rgba(0,0,0,.3)', alignItems: 'center', justifyContent: 'center' }}
      >
        <View
          style={{
            width: 300,
            // height: 150,
            backgroundColor: 'white',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            // padding: 5,
            paddingVertical: 20,
            paddingHorizontal: 14,
          }}
        >
          <View style={{ height: 12 }}/>
          <Text style={{ fontWeight: '600', fontSize: 14, color: '#16191C' }}>
            {submission.name} | Price Update
          </Text>
          <View style={{ height: 10 }}/>
          <Text style={{ fontWeight: '400', fontSize: 14, color: '#16191C', textAlign: 'center' }}>
            What price would you like to propose?{'\n\n'}Note: creators get ~80% after our platform fee. If they expect to be paid $80, enter a value of $100.
          </Text>
          <View style={{ height: 14 }}/>
          <TextInput
            style={styles.input}
            value={price}
            onChangeText={setPrice}
            placeholder={"340"}
          />
          <View style={{ height: 10 }}/>
          <Touchable disabled={!isDiff || loading} onPress={submit} style={{ height: 35, width: '100%', backgroundColor: !isDiff ? '#aaa' : '#3E8BFF', alignItems: 'center', justifyContent: 'center', borderRadius: 3 }}>
            {loading ? <ActivityIndicator color={'#fff'}/> : <Text style={{ ...maybeRounded('700'), color: '#fff'}}>Update</Text>}
          </Touchable>
        </View>
      </Modal>
    </View>
  );
}

export default function DealStatusSummary({ submission, deal }) {

  const wasApproved = submission.status !== 'applied' && submission.status !== 'rejected';

  const { adjustedPrice, creatorTake } = calculatePrice(deal, submission);
  const creatorProposedPrice = submission.user_proposed_base_price ? (submission.user_proposed_base_price + submission.user_proposed_price_our_add_usd) : null;
  const companyProposedPrice = submission.company_proposed_base_price ? (submission.company_proposed_base_price + submission.company_proposed_price_our_add_usd) : null;

  const { base_price_per_install_usd, our_price_on_top_per_install_usd } = deal;
  const adjustedLinkPrice = (base_price_per_install_usd + our_price_on_top_per_install_usd).toFixed(2);

  return (
    <View style={{ 
      alignSelf: 'stretch', 
      flexDirection: 'row',
      padding: 20,
      borderBottomWidth: 1,
      borderColor: '#E9EAED'
    }}>
      {/*<Image source={{ uri: submission.propic_800_url }} style={{ marginRight: 15, width: 40, height: 40, borderRadius: 40/2, borderWidth: 2 }}/>*/}
      <View style={{ flex: 1, gap: 8 }}>
        <View style={styles.row}>
          <Text style={styles.label}>Current status:</Text>
          <Text style={styles.text}>{statusToStr[submission.status]}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Applied on:</Text>
          <Text style={styles.text}>{(new Date(submission.created_on)).toLocaleDateString()}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Name:</Text>
          <Text style={styles.text}>{submission.mailing_address_full_name || submission.name}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>TikTok:</Text>
          <Text style={styles.text}>
            <a style={{ color: '#3B51F5' }} target={"_blank"} href={`https://tiktok.com/@${submission.tiktok_handle.toLowerCase()}`}>@{submission.tiktok_handle}</a>
            {' • '}{formatFollowers(submission.follower_count)} followers
          </Text>
        </View>
        {!!deal.requires_mailing &&
          <Fragment>
            <View style={styles.row}>
              <Text style={styles.label}>Product you want:</Text>
              <AutoLinkText style={styles.text}>
                {submission.user_product_notes || 'N/A'}
              </AutoLinkText>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Address:</Text>
              {!wasApproved &&
                <Text style={styles.text}>
                  {[submission.mailing_address_state, submission.mailing_address_country].filter(x => !!x).map(x => x.trim()).join(', ')}
                  {' '}(approve to see full address)
                </Text>
              }
              {!!wasApproved &&
                <Text style={styles.text}>
                  {submission.mailing_address_street_address}{'\n'}
                  {submission.mailing_address_suite}{submission.mailing_address_suite && '\n'}
                  {[submission.mailing_address_city, submission.mailing_address_state, submission.mailing_address_country, submission.mailing_address_zip].filter(x => !!x).map(x => x.trim()).join(', ')}
                </Text>
              }
            </View>
          </Fragment>
        }

        {/* email*/}
        {/* paypal */}

        {!!(deal.post_required || deal.campaign_type === 'post') &&
          <View style={styles.row}>
            <Text style={styles.label}>Post:</Text>
            <AutoLinkText style={styles.text}>{submission.tiktok_post_link || 'Not yet posted'}</AutoLinkText>
          </View>
        }
        {(deal.campaign_type === 'post' || deal.campaign_type === 'ugc') &&
          <View style={styles.row}>
            <Text style={styles.label}>Video:</Text>
            {!submission.video_url && <Text style={styles.text}>Not yet submitted</Text>}
            {!!submission.video_url &&
              <View style={{ flex: 1 }}>
                <Video
                  // usePoster
                  posterSource={{ uri: submission.video_thumb_800_url }}
                  source={{ uri: submission.video_url }}
                  resizeMode={ResizeMode.CONTAIN}
                  // note: version 13.0.0/13.0.1 added videoStyle and PosterComponent props we may want to use
                  style={{ marginBottom: 10, width: 280, height: 280*16/9, aspectRatio: 16/9, maxWidth: '100%', backgroundColor: '#000', borderRadius: 6 }}
                  useNativeControls
                />
                <Text style={[styles.text, { flex: null }]}>
                  {/* todo: better filenames on downloads */}
                  <a style={{ color: '#2364C6' }} target={"_blank"} href={submission.video_url}>Download Video</a>
                </Text>
              </View>
            }
          </View>
        }
        {deal.campaign_type !== 'install' &&
          <View style={styles.row}>
            <Text style={styles.label}>Price:</Text>
            <View style={{ alignItems: 'flex-end' }}>
              <Text style={styles.text}>${adjustedPrice}{submission.requested_price_usd && ' **offer set by creator'} ({(creatorTake / adjustedPrice * 100).toFixed(0)}% goes to creator)</Text>
              {/*{!wasApproved && <Text style={[styles.text, { fontSize: 14, color: '#444', marginTop: 4 }]}>You can still negotiate the price below, and enter an updated price here. The creator will see 80% of whatever price you enter.</Text>}*/}
              <UserPriceProposalModal deal={deal} submission={submission}/>
              <CompanyPriceProposalModal deal={deal} submission={submission}/>
            </View>
          </View>
        }
        {deal.campaign_type === 'install' &&
          <View style={styles.row}>
            <Text style={styles.label}>Price:</Text>
            <View style={{ flex: 1 }}>
              {/*<Text style={styles.text}>${adjustedLinkPrice} per install (${base_price_per_install_usd.toFixed(2)} to creator)</Text>*/}
              <Text style={styles.text}>${adjustedLinkPrice} per install ({(base_price_per_install_usd / adjustedLinkPrice * 100).toFixed(0)}% goes to creator)</Text>
            </View>
          </View>
        }
        {deal.campaign_type !== 'install' &&
          <View style={styles.row}>
            <Text style={styles.label}>Payment:</Text>
            <Text style={styles.text}>{submission.final_payment_succeeded ? (submission.final_payment_sent_to_connect ? 'Paid' : 'Paid (*creator still setting up payout)') : 'Not paid yet'}</Text>
          </View>
        }
        {deal.campaign_type === 'install' &&
          <View style={styles.row}>
            <Text style={styles.label}>Installs:</Text>
            <Text style={styles.text}>{submission.installs_count || 0}</Text>
          </View>
        }
        {deal.campaign_type === 'install' &&
          <View style={styles.row}>
            <Text style={styles.label}>Paid out so far:</Text>
            <Text style={styles.text}>${((submission.installs_total_paid_by_company_cents_usd || 0)/100).toFixed(2)}</Text>
          </View>
        }

        {/*<View style={{ height: 5 }}/>*/}
        {/*<View style={styles.checkBoxRow}>*/}
        {/*  <View style={[styles.checkBoxInput, styles.checkboxInputSelected, { width: 24, height: 24, backgroundColor: '#b0b0b0' }]}>*/}
        {/*    <Image source={checkIcon} tintColor={'#fff'} style={{ width: 20, height: 20 }}/>*/}
        {/*  </View>*/}
        {/*  <Text style={[styles.checkBoxDescription, { ...maybeRounded('400') }]}>If accepted, I can film this video within a week</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.checkBoxRow}>*/}
        {/*  <View style={[styles.checkBoxInput, styles.checkboxInputSelected, { width: 24, height: 24, backgroundColor: '#b0b0b0' }]}>*/}
        {/*    <Image source={checkIcon} tintColor={'#fff'} style={{ width: 20, height: 20 }}/>*/}
        {/*  </View>*/}
        {/*  <Text style={[styles.checkBoxDescription, { ...maybeRounded('400') }]}>I know that I will have to shoot{deal.post_required ? ' and post' : ''} the video if accepted</Text>*/}
        {/*</View>*/}
        {/*{deal.requires_mailing &&*/}
        {/*  <View style={styles.checkBoxRow}>*/}
        {/*    <View style={[styles.checkBoxInput, styles.checkboxInputSelected, { width: 24, height: 24, backgroundColor: '#b0b0b0' }]}>*/}
        {/*      <Image source={checkIcon} tintColor={'#fff'} style={{ width: 20, height: 20 }}/>*/}
        {/*    </View>*/}
        {/*    <Text style={[styles.checkBoxDescription, { ...maybeRounded('400') }]}>I know that if I don't shoot the video I will have to refund the item</Text>*/}
        {/*  </View>*/}
        {/*}*/}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    fontSize: 14,
    fontWeight: '500',
    color: '#0F1D40'
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    color: '#0F1D40',
    wordBreak: 'break-word',
  },
  row: {
    flexDirection: 'row',
    paddingHorizontal: 4,
    gap: 16,
    justifyContent: 'space-between'
  },


  inputHeaderText: {
    marginBottom: 5,
    fontSize: 16,
    color: '#000',
    ...maybeRounded('600'),
  },
  checkBoxRow: {
    flexDirection: 'row',
  },
  checkBoxInput: {
    height: 40,
    width: 40,
    borderRadius: 3,
    borderColor: '#616161',
    borderWidth: 1,
    marginRight: 10,
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxInputSelected: {
    backgroundColor: '#0463ff',
    borderWidth: 0,
  },
  checkBoxDescription: {
    color: '#000',
    fontSize: 16,
    ...maybeRounded('600'),
    flex: 1,
  },
  submissionText: {
    marginTop: -3,
    marginBottom: 15,
    fontSize: 15,
    color: '#333',
    ...maybeRounded('400'),
  },

  input: {
    width: '100%',
    height: 50,
    borderRadius: 4,
    backgroundColor: '#fff',
    padding: 10,
    ...maybeRounded('500'),
    fontSize: 15,
    color: '#000000',

    borderWidth: 1,
    borderColor: '#dddfe1',
  },
});