import { apiView } from "../api/api";
import { Dimensions } from "react-native";
import { store } from "../redux/store";
import getBrowserInfo from "./browserInfo";

// import { Dimensions } from "react-native-web";

// possible todo: also add google analytics

export const uploadView = async () => {
  let isInTempLogin = !!store.getState().adminUserId;
  if (isInTempLogin) return;

  const browserInfo = {
    ...getBrowserInfo(), // { name, version, fullVersion, os }
    window: Dimensions.get('window'), // { fontScale, height, scale, width }
    screen: Dimensions.get('screen'), // { fontScale, height, scale, width }
    timezoneOffset: (new Date()).getTimezoneOffset(), // e.g. 240 for EST
    language: navigator.language, // "en-US"
    languages: navigator.languages, // ["en-US", "en", "ru"]
    userAgent: navigator.userAgent,
  };

  const urlInfo = {
    hash: window.location.hash,
    host: window.location.host,
    hostname: window.location.hostname,
    href: window.location.href,
    origin: window.location.origin,
    // host: "https://duet.social", //window.location.host,
    // hostname: "duet",//window.location.hostname,
    // href: "https://duet.social"+window.location.pathname,
    // origin: "https://duet.social",
    pathname: window.location.pathname,
    port: window.location.port,
    protocol: window.location.protocol,
    // protocol: "https",
    search: window.location.search,
  };

  const referrer = document.referrer;

  return await apiView(browserInfo, urlInfo, referrer);
};
