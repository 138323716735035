export const calculatePrice = (deal, submission) => {
  const {
    campaign_type,
    is_flat_rate,
    base_price_usd,
    merch_msrp_usd,
    creator_negotiation,
    our_price_on_top_usd,
  } = deal;

  const {
    follower_base_price_usd,
    follower_price_on_top_usd,
    requested_price_usd,
    requested_price_our_add_usd,
    follower_count,
  } = submission;

  let creatorTake = base_price_usd;
  let adjustedPrice = base_price_usd + our_price_on_top_usd;
  if (campaign_type === 'post' && !is_flat_rate) {
    creatorTake = base_price_usd + follower_base_price_usd;
    adjustedPrice = base_price_usd + our_price_on_top_usd + follower_base_price_usd + follower_price_on_top_usd;
  }
  if (requested_price_usd) {
    creatorTake = requested_price_usd;
    adjustedPrice = requested_price_usd + requested_price_our_add_usd;
  }

  return { creatorTake, adjustedPrice };
};
