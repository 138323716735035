import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Platform } from "react-native";
import { sleep } from "../util/sleep";
import { WS_HOST } from "./util";
import uuidv4 from "uuid/v4";
import { updateDealChannelsList, updateDealsInfo } from "../redux/asyncActions";
import { updateDealChannelAndMessages } from "../redux/asyncActions";

const messageHandlers = {
  // message: async ({ channelId }) => {
  //   updateChannelsList();
  //   updateChannelAndMessages(channelId);
  // },
  deal_message: async ({ dealChannelId }) => {
    updateDealChannelsList();
    updateDealChannelAndMessages(dealChannelId);
  },
  // swipe: async () => {
  //   updateChannelsList();
  // },
  // bonus: async () => {
  //   updateBonusMatches();
  // },
  update_deals: async () => {
    updateDealsInfo();
  },
};

// on socket reopen, can use this to reload channelsList / messages
const openListeners = {};
export const addHomeSocketOpenListener = (f) => {
  const id = uuidv4();
  openListeners[id] = f;
  return id;
};
export const removeHomeSocketOpenListener = (id) => {
  delete openListeners[id];
};

export default function useHomeSocket() {
  const token = useSelector(state => state.token);
  const socket = useRef();
  useEffect(() => {
    let unmounted = false;
    if (token) {
      let setupSocket;
      const onSocketOpen = event => {
        console.log("Home socket opened");
        Object.values(openListeners).forEach(f => f());
      };
      const onSocketMessage = async event => {
        if (event.data !== `{"type":"ping"}` && event.data !== `{"type":"pong"}`) console.log("Home socket message", event.data);
        try {
          let data = JSON.parse(event.data);
          const { type, payload } = data;
          if (messageHandlers[type]) messageHandlers[type](payload);
        } catch (e) {
          console.log("Home socket message error", e.toString());
        }
      };
      const onSocketError = event => console.log("Home socket error", event.toString());

      let socketResetTimeout = 0;
      const onSocketClose = async event => {
        console.log("Home socket close", event.code, event.reason);

        // try to reconnect with ~1 second, 2 second, 3 second, up to 11 second intervals
        if (!unmounted) {
          socketResetTimeout = socketResetTimeout + 1000 + (Math.random()*500 - 250);
          if (socketResetTimeout > 10000) socketResetTimeout = 10000 + Math.random()*1000;
          await sleep(socketResetTimeout);
          setupSocket();
        }
      };

      const setupHeartbeat = (ws) => {
        const sendPing = () => ws.readyState === 1 && ws.send(JSON.stringify({ type: 'ping' }));
        const sendPong = () => ws.readyState === 1 && ws.send(JSON.stringify({ type: 'pong' }));

        // every 25 seconds, send a ping message to the server
        const sendInterval = setInterval(() => sendPing(), 25000);

        // in 60 seconds, if no message accepted from server, close the connection
        ws.isAlive = true;
        const rcvInterval = setInterval(() => {
          if (ws.isAlive === false) console.log("Home socket - terminating");
          if (ws.isAlive === false) return ws.close();
          ws.isAlive = false;
        }, 60000);

        ws.addEventListener('message', event => {
          let data = JSON.parse(event.data);
          const { type } = data;
          if (type === 'ping') sendPong();
          if (type === 'pong') ws.isAlive = true;
        });
        ws.addEventListener('close', event => {
          clearInterval(sendInterval);
          clearInterval(rcvInterval);
        });
      };

      setupSocket = () => {
        // NOTE: this array thing is some hacky crap around the error "Failed to construct 'WebSocket': The subprotocol is invalid"
        // socket.current = new WebSocket(`${WS_HOST}/ws/channel/${channelId}`, token ? ['Bearer', token] : undefined);
        socket.current = new WebSocket(`${WS_HOST}/ws/home`, Platform.OS === 'web' ? ['Bearer', token] : [`Bearer ${token}`]);
        // socket.current = new WebSocket(`${WS_HOST}/ws/home`);
        socket.current.addEventListener('open', onSocketOpen);
        socket.current.addEventListener('message', onSocketMessage);
        socket.current.addEventListener('error', onSocketError);
        socket.current.addEventListener('close', onSocketClose);

        setupHeartbeat(socket.current);
      };

      setupSocket();

      return () => {
        unmounted = true;
        socket.current.close();
      };
    }
  }, [token]);
}
