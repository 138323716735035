import { store } from "./store";
import {
  apiGetDealChannelAndMessages,
  apiGetDealChannels,
  apiGetDealsInfo,
  apiGetMe,
  apiGetPaymentMethod
} from "../api/api";
import {
  addDealChannelAndMessages,
  addDealChannelsList,
  addDealsInfo,
  addMe,
  addPaymentMethod,
  logout
} from "./reducers";


export const updateMe = async () => {
  let res = await apiGetMe();
  if (res.status === 'success') {
    store.dispatch(addMe(res.data));
  }
  else if (res.data && res.data.type === 'not_found') { // deleted user
    store.dispatch(logout());
  }
  return res;
};

export const updateDealsInfo = async () => {
  let res = await apiGetDealsInfo();
  if (res.status === 'success') {
    store.dispatch(addDealsInfo(res.data));
  }
  return res;
};

export const updateDealChannelsList = async () => {
  const res = await apiGetDealChannels();
  if (res.status === 'success') store.dispatch(addDealChannelsList(res.data));
  return res;
};

export const updateDealChannelAndMessages = async (dealChannelId: number) => {
  const res = await apiGetDealChannelAndMessages(dealChannelId);
  if (res.status === 'success') store.dispatch(addDealChannelAndMessages(dealChannelId, res.data));
  return res;
};

export const updatePaymentMethod = async () => {
  const res = await apiGetPaymentMethod();
  if (res.status === 'success') store.dispatch(addPaymentMethod(res.data));
  return res;
};
